import React, { useEffect, useState } from "react";
import {
  TextInput,
  Row,
  Column,
  FormGroup,
  RadioButtonGroup,
  RadioButton,
  TextArea,
  Checkbox,
} from "carbon-components-react";
import useGet from "../../hooks/useGet";
import ProvinceCombo from "../Combos/ProvinceCombo";
import DistrictCombo from "../Combos/DistrictCombo";
import { NumberInput } from "carbon-components-react";

const BusinessProfileForm = ({
  disabled,
  register,
  control,
  errors,
  application,
}) => {
  const { data: legalForms } = useGet("/utils/legal-forms");
  const [districts, setDistricts] = useState([]);

  return (
    <FormGroup
      legendText="SECÇÃO C. PERFIL DE NEGÓCIO - EMPRESAS EXISTENTES"
      className="section-header"
    >
      <h6 className="section-subtitle">
        A ser preenchida somente por candidatos/as que têm um negócio
        actualmente
      </h6>
      <fieldset className="bx--fieldset required">
        <legend className="bx--label">C1. Tipo de negócio</legend>
        <Row>
          <Column sm={12} md={12} lg={12}>
            <p>Existem diferentes tipos de negócios nomeadamente: </p>
            <ol>
              <li>
                <strong>Fabricante/Produtor:</strong> são aqueles que usam
                matérias-primas para fazer novos produtos. Por exemplo usam a
                madeira para o fabrico de camas e cadeiras, ou a pele de animais
                (couro) para o fabrico de sapatos, etc.{" "}
              </li>
              <li>
                <strong>Prestador de Serviços:</strong> são aqueles que fazem
                algum trabalho para alguém e em troca são pagos uma remuneração.
                Por exemplo os pedreiros, cabeleiros, etc.{" "}
              </li>
              <li>
                <strong>Retalhistas/Vendedores a Retalho:</strong> são os que
                compram em grandes quantidades e revendem em pequenas
                quantidades para fazer lucro. Por exemplo os comerciantes que
                compram sabão, óleo em caixa e revendem em pequenas quantidades.{" "}
              </li>
              <li>
                <strong>Grossistas/Vendedores a Grosso:</strong> são aqueles
                comerciantes que vendem por grosso, i.e., em grandes
                quantidades, por exemplo para os retalhistas/vendedores a
                retalho.
              </li>
            </ol>
          </Column>
        </Row>
        <Row>
          <Column sm={12} md={3} lg={3}>
            <Checkbox
              labelText="Fabricante/Produtor "
              id="isBusinessManufacturerOrProducer"
              disabled={disabled}
              {...register("isBusinessManufacturerOrProducer")}
            />
          </Column>
          <Column sm={12} md={3} lg={3}>
            <Checkbox
              labelText="Prestador de serviço "
              id="isBusinessServiceProvider"
              disabled={disabled}
              {...register("isBusinessServiceProvider")}
            />
          </Column>
          <Column sm={12} md={3} lg={3}>
            <Checkbox
              labelText="Retalhista "
              id="isBusinessRetailer"
              disabled={disabled}
              {...register("isBusinessRetailer")}
            />
          </Column>
          <Column sm={12} md={3} lg={3}>
            <Checkbox
              labelText="Grossista"
              id="isBusinessWholesaler"
              disabled={disabled}
              {...register("isBusinessWholesaler")}
            />
          </Column>
          <p className="field-error">{errors.businessType?.message}</p>
        </Row>
      </fieldset>
      <fieldset className="bx--fieldset legal-form">
        <legend className="bx--label">C2. Forma legal do negócio</legend>
        <Row>
          <Column sm={12} md={12} lg={12}>
            <p>
              Há varias formas de negócios, mas é importante que de acordo com
              as suas condições e capacidades escolha a mais adequada para si.
              Para Agora Emprega CPN são 4 formas legais de negócios elegíveis
              nomeadamente:
            </p>
            <ol>
              <li>
                <strong>Sociedade Unipessoal</strong> quando o negócio pertence
                a um único proprietário.
              </li>
              <li>
                <strong>Sociedade por quotas</strong>, quando duas ou mais
                pessoas decidem fazer um negócio e a responsabilidade de cada
                sócio é restrita ao valor da sua quota. Uma sociedade por quotas
                também pode ser unipessoal, quando é constituída por apenas um
                sócio, que detém a totalidade do capital social.
              </li>
              <li>
                <strong>Sociedade Anónima</strong>, é uma forma jurídica de
                empresa na qual o capital financeiro da empresa é dividido em
                acções e a participação e responsabilidade dos sócios
                (accionistas, que devem ser pelo menos 3, salvo número inferior
                se um deles é o Estado) é definida pela quantidade de acções que
                possuem. Uma das características é que o património pessoal do
                acionista fica separado do patrimônio da empresa.
              </li>
              <li>
                <strong>Cooperativa</strong>, um negócio formado por conjunto de
                pessoas que decidem trabalhar juntas para fins comuns por
                exemplo melhorar acesso aos mercado de insumos, acesso ao
                mercado de venda de produtos, etc.
              </li>
            </ol>
            <RadioButtonGroup
              legend="Group Legend"
              name="legalFormOfBusinessCode"
              orientation="horizontal"
              disabled={disabled}
              valueSelected={application?.legalFormOfBusinessCode}
            >
              {legalForms.map((lf) => (
                <RadioButton
                  id={"lfb-" + lf.code}
                  key={"lfb-" + lf.code}
                  labelText={lf.description}
                  value={lf.code}
                  {...register("legalFormOfBusinessCode")}
                />
              ))}
            </RadioButtonGroup>
            <p className="field-error">
              {errors.legalFormOfBusinessCode?.message}
            </p>
          </Column>
        </Row>
      </fieldset>
      <fieldset className="bx--fieldset">
        <Row>
          <Column xsm={12} sm={12} md={12} lg={12} className="required">
            <TextArea
              id="businessProductOrService"
              labelText="C3. Tipo de produto/serviço que vende/presta"
              rows={8}
              enableCounter
              maxCount={1250}
              disabled={disabled}
              helperText="[Descreva de forma sumária, o produto/serviço que actualmente presta, enunciando as suas principais características e o grau de desenvolvimento.]"
              {...register("businessProductOrService")}
            />
            <p className="field-error">{errors.competitorData?.message}</p>
          </Column>
        </Row>
      </fieldset>
      <fieldset className="bx--fieldset">
        <Row>
          <Column sm={12} md={6} lg={6} className="required">
            <TextInput
              id="numberOfExistingWorkers"
              labelText="C4. Indique o número de trabalhadores que existem actualmente"
              disabled={disabled}
              {...register("numberOfExistingWorkers")}
            />
            <p className="field-error">
              {errors.numberOfExistingWorkers?.message}
            </p>
          </Column>
        </Row>
        <Row>
          <Column sm={12} md={3} lg={3} className="required">
            <TextInput
              id="numberOfExistingMaleWorkers"
              labelText="Homens"
              disabled={disabled}
              {...register("numberOfExistingMaleWorkers")}
            />
            <p className="field-error">
              {errors.numberOfExistingMaleWorkers?.message}
            </p>
          </Column>
          <Column sm={12} md={3} lg={3} className="required">
            <TextInput
              id="numberOfExistingFemaleWorkers"
              labelText="Mulheres"
              disabled={disabled}
              {...register("numberOfExistingFemaleWorkers")}
            />
            <p className="field-error">
              {errors.numberOfExistingFemaleWorkers?.message}
            </p>
          </Column>
          <Column sm={12} md={3} lg={3} className="required">
            <TextInput
              id="numberOfExistingMaleWorkersAged18To35"
              labelText="Homens (18-35 anos)"
              disabled={disabled}
              {...register("numberOfExistingMaleWorkersAged18To35")}
            />
            <p className="field-error">
              {errors.numberOfExistingMaleWorkersAged18To35?.message}
            </p>
          </Column>
          <Column sm={12} md={3} lg={3} className="required">
            <TextInput
              id="numberOfExistingFemaleWorkersAged18To35"
              labelText="Mulheres  (18-35 anos)"
              disabled={disabled}
              {...register("numberOfExistingFemaleWorkersAged18To35")}
            />
            <p className="field-error">
              {errors.numberOfExistingFemaleWorkersAged18To35?.message}
            </p>
          </Column>
        </Row>
      </fieldset>
      <fieldset className="bx--fieldset last-fieldset">
        <legend className="bx--label">
          C5.Instalações de trabalho à disposição do negócio
        </legend>
        <Row>
          <Column sm={12} md={4} lg={4} className="required">
            <ProvinceCombo
              id="workFacilitiesProvinceId"
              name="workFacilitiesProvinceId"
              label="Província"
              disabled={disabled}
              control={control}
              setDistricts={setDistricts}
            />
            <p className="field-error">
              {errors.workFacilitiesProvinceId?.message}
            </p>
          </Column>
          <Column sm={12} md={4} lg={4} className="required">
            <DistrictCombo
              id="workFacilitiesDistrictId"
              name="workFacilitiesDistrictId"
              label="Cidade/Distrito"
              disabled={disabled}
              items={districts}
              control={control}
            />
            <p className="field-error">
              {errors.workFacilitiesDistrictId?.message}
            </p>
          </Column>
          <Column sm={12} md={4} lg={4} className="required">
            <TextInput
              id="workFacilitiesLocality"
              labelText="Localidade"
              maxLength={128}
              disabled={disabled}
              {...register("workFacilitiesLocality")}
            />
            <p className="field-error">
              {errors.workFacilitiesLocality?.message}
            </p>
          </Column>
        </Row>
        <Row>
          <Column sm={12} md={4} lg={4} className="required">
            <TextInput
              id="workFacilitiesNeighborhood"
              labelText="Bairro"
              maxLength={128}
              disabled={disabled}
              {...register("workFacilitiesNeighborhood")}
            />
            <p className="field-error">
              {errors.workFacilitiesNeighborhood?.message}
            </p>
          </Column>
          <Column sm={2} md={4} lg={4} className="required">
            <TextInput
              id="workFacilitiesStreet"
              labelText="Rua"
              maxLength={128}
              disabled={disabled}
              {...register("workFacilitiesStreet")}
            />
            <p className="field-error">
              {errors.workFacilitiesStreet?.message}
            </p>
          </Column>
          <Column sm={2} md={4} lg={4}>
            <FormGroup legendText="">
              <RadioButtonGroup
                legendText="Instalações"
                name="ownWorkFacilities"
                className="required"
                disabled={disabled}
                valueSelected={String(application?.ownWorkFacilities)}
              >
                <RadioButton
                  id="facility-own"
                  labelText="Próprias"
                  value="true"
                  {...register("ownWorkFacilities")}
                />
                <RadioButton
                  id="facility-rent"
                  labelText="Arrendadas"
                  value="false"
                  {...register("ownWorkFacilities")}
                />
              </RadioButtonGroup>
              <p className="field-error">{errors.ownWorkFacilities?.message}</p>
            </FormGroup>
          </Column>
        </Row>
      </fieldset>
    </FormGroup>
  );
};

BusinessProfileForm.defaultProps = {
  disabled: false,
};

export default BusinessProfileForm;
