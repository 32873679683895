import React, { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../../contexts/AuthContext'
import {
  PieChart,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Pie,
  Legend,
} from 'recharts'
import http from "../../http-common";
const LandingPage = () => {

  const { user, isCompetitor } = useContext(AuthContext)

  const [countprojectbyemployee, SetCountprojectbyemployee] = useState([]);
  const [employeeDepartmentResume, SetEmployeeDepartmentResume] = useState([]);
  const [employeeByserviceStatus, SetEmployeeByserviceStatus] = useState([]);


  const loadCountprojectbyemployee = async () => {
    const response = await http.get("/countprojectbyemployee");
    const response_dto = response.data.map((x) => ({
      "name": x.description,
      "value": parseInt(x.value),
    }))
    SetCountprojectbyemployee(response_dto);
  }


  const loadEmployeeDepartmentreRume = async () => {
    const response = await http.get("/employeedepartmentresume");
    const response_dto = response.data.map((x) => ({
      "name": x.description,
      "value": parseInt(x.value),
    }))
    SetEmployeeDepartmentResume(response_dto);
  }

  const loadservicestatusbyemployee = async () => {

    const response = await http.get("/servicestatusbyemployee");


    const response_dto = response.data.map((x) => ({
      "name": x.employee,
      "Abertas": x.open,
      "Em progresso": x.active,
      "Fechadas": x.closed
    }))

    console.log("response_dto", response_dto);

    SetEmployeeByserviceStatus(response_dto);

    console.log("employeeByserviceStatus", employeeByserviceStatus);
  }


  useEffect(() => {
    loadEmployeeDepartmentreRume();
  }, [])

  useEffect(() => {
    loadCountprojectbyemployee();
  }, [])

  useEffect(() => {
    loadservicestatusbyemployee();
  }, [])



  const dashbordStyle = {
    welcome: {
      'margin-bottom': '60px',
      'padding-bottom': '60px',
    },

    firstCahart: { 'margin-top': '20px' },

    column: {
      'background-color': '#aaa',
    },

    cardMargin: {
      margin: 'layout.$spacing-10',
      'text-align': 'center',
    },

    dashBordTitle: {
      'margin-top': '10px',
    },
  }



  // bar chart
  const totalTarefasFeitaseNaofeirasPorFuncionarios = [
    {
      name: 'Funcionario 1',
      feitas: 23,
      falta: 32,
      projecto: 95,
    },
    {
      name: 'Funcionario 2',
      feitas: 34,
      falta: 234,
      projecto: 200,
    },
    {
      name: 'Funcionario 3',
      feitas: 78,
      falta: 423,
      projecto: 47,
    },
    {
      name: 'Funcionario 4',
      feitas: 10,
      falta: 67,
      projecto: 95,
    },
  ]

  return (
    <div>

      <div className="bx--row">
        <div className="bx--col card" style={dashbordStyle.cardMargin}>
          <h2>Início  </h2>
        </div>
      </div>

      <div style={dashbordStyle.firstCahart}>
        <div className="bx--row">
          <div className="bx--col-md-4 card" style={dashbordStyle.cardMargin}>
            <h2 style={dashbordStyle.dashBordTitle}>
              Total de Funcionários por projecto{' '}
            </h2>
            <PieChart width={400} height={400}>
              <Pie
                dataKey="value"
                isAnimationActive={true}
                data={countprojectbyemployee}
                cx="50%"
                cy="50%"
                outerRadius={150}
                fill="#2596be"
                label
              />
              <Tooltip />
            </PieChart>
          </div>
          <div className="bx--col-md-4 card" style={dashbordStyle.cardMargin}>
            <h2 style={dashbordStyle.dashBordTitle}>
              Total Funcionários por Departamento{' '}
            </h2>
            <PieChart width={400} height={400}>
              <Pie
                dataKey="value"
                isAnimationActive={true}
                data={employeeDepartmentResume}
                cx="50%"
                cy="50%"
                outerRadius={150}
                fill="#2596be"
                label
              />
              <Tooltip />
            </PieChart>
          </div>
        </div>
      </div>

      <div style={dashbordStyle.firstCahart}>
        <div className="bx--row">
          <div className="bx--col card" style={dashbordStyle.cardMargin}>
            <h2 style={dashbordStyle.dashBordTitle}>
              {' '}
              Total Tarefas Por Funcionário
            </h2>

            {/*Todo/para fazer:  Essa parte deve usar % e nao valores numeros, */}
            <BarChart
              width={1250}
              height={400}
              data={employeeByserviceStatus}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Legend />
              <Bar dataKey="Abertas" fill="#8884d8" />
              <Bar dataKey="Em progresso" fill={'#2596be'} />
              <Bar dataKey="Fechadas" fill={'#2574be'} />
              <Tooltip />
            </BarChart>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LandingPage
