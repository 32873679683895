import { Search32 } from "@carbon/icons-react";
import { Add16, Edit24, Edit32, TrashCan24 } from "@carbon/icons-react";
import {
  Button,
  Column,
  DataTable,
  Form,
  Row,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
  TableToolbar,
  TableToolbarContent,
  TableToolbarSearch,
  TextInput,
} from "carbon-components-react";
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import Card from "../../components/Card/Card";
import TablePagination from "../../components/Pagination/Pagination";
import useGet from "../../hooks/useGet";
import http from "../../http-common";
import { Document16 } from "@carbon/icons-react";
import jsPDF from "jspdf";
import "jspdf-autotable";

const tableHeaders = [
  {
    header: "Nome",
    key: "firstname",
  },
  {
    header: "Apelido",
    key: "lastname",
  },
  {
    header: "Usuário",
    key: "username",
  },
  {
    header: "Operações",
    key: "actions",
  },
];

const LinkList = ({ url }) => (
  <ul style={{ display: "flex" }}>
    <li>
      <Link to={url} title="Editar">
        <Edit24 />
      </Link>
    </li>
  </ul>
);

const UsersPage = () => {
  const [users, setUsers] = useState([]);
  const [count, setCount] = useState(0);
  const [size, setSize] = useState(20);

  const { data: roles } = useGet("/user-roles");

  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const featchData = async () => {
    try {
      const response = await http.get("/users/search?size=" + size);

      getUserData(response);
    } catch (error) {}
  };

  const countUsers = async () => {
    const response = await http.get("/users/count");
    setCount(response.data);
    return response.data;
  };

  const onPageChange = async ({ page, pageSize }) => {
    try {
      var pag = page - 1;
      const response = await http.get(
        "/users/search?page=" + pag + "&size=" + pageSize
      );
      getUserData(response);
    } catch (error) {}
  };

  const onSearch = async (data) => {
    try {
      const response = await http.get(
        `/users/search?username=${data.username}&firstname=${data.firstname}`
      );
      getUserData(response);
    } catch (error) {}
  };

  const getUserData = (response) => {
    const data = response.data.content.map((user) => ({
      id: user.id,
      firstname: user.firstname,
      lastname: user.lastname,
      username: user.username,
      actions: <LinkList url={"../actualizar-utilizador/" + user.id} />,
    }));
    setUsers(data);
  };

  const exportPDF = () => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 200;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = "Credencias do Fornecedor";
    const headers = [["Fornecedor", "Username", "Password"]];
    const timestamp = Date.now();
    const data = users.map((user) => [
      user.firstname,
      user.username,
      user.textPassword,
    ]);

    let content = {
      startY: 50,
      head: headers,
      body: data,
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save(`users_${timestamp}.pdf`);
  };

  // const exportUsers = (

  //     <div className="mb-2 form-buttons">
  //       <p></p>
  //         <Button
  //             onClick={exportPDF}
  //             renderIcon={Document16}
  //             kind="ghost"

  //         >
  //             Exportar Usuários
  //         </Button>
  //     </div>
  // );
  useEffect(() => {
    featchData();
    countUsers();
  }, []);

  return (
    <Card title="Utilizadores">
      <div className="mb-2 form-buttons">
        <p></p>
        <Button
          kind="ghost"
          onClick={() => navigate("../registar-utilizador")}
          renderIcon={Add16}
        >
          Registar Utilizador
        </Button>
      </div>
      {/* {exportUsers} */}
      <DataTable rows={users} headers={tableHeaders} isSortable useZebraStyles>
        {({ rows, headers, getHeaderProps, getTableProps }) => (
          <TableContainer>
            <TableToolbar aria-label="data table toolbar">
              <TableToolbarContent>
                <Form
                  className="data-table-search-form"
                  onSubmit={handleSubmit(onSearch)}
                >
                  <Row>
                    <Column sm={6} md={6} lg={5}>
                      <TextInput
                        id="Nome"
                        labelText=""
                        placeholder="Nome"
                        {...register("firstname")}
                      />
                    </Column>

                    <Column sm={6} md={6} lg={5}>
                      <TextInput
                        id="username"
                        labelText=""
                        placeholder="Username"
                        {...register("username")}
                      />
                    </Column>
                  </Row>
                  <Button type="submit" renderIcon={Search32}>
                    Pesquisar
                  </Button>
                </Form>
              </TableToolbarContent>
            </TableToolbar>
            <Table {...getTableProps()}>
              <TableHead>
                <TableRow>
                  {headers.map((header) => (
                    <TableHeader {...getHeaderProps({ header })}>
                      {header.header}
                    </TableHeader>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow key={row.id}>
                    {row.cells.map((cell) => (
                      <TableCell key={cell.id}>{cell.value}</TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </DataTable>
      <TablePagination
        currentPageSize={size}
        onPageChange={onPageChange}
        totalItems={count}
      />
    </Card>
  );
};

export default UsersPage;
