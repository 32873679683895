import {
    Button,
    Form,
    TextInput,
    Row,
    Column,
    ButtonSet,
    Dropdown,
    DatePicker,
  DatePickerInput,
} from "carbon-components-react";
import { useEffect, useState, useContext } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import http from "../../http-common";
import { RuleCancelled24, SaveModel24 } from "@carbon/icons-react";
import { AuthContext } from "../../contexts/AuthContext";
import { yupResolver } from "@hookform/resolvers/yup";

const FairForm = ({ mode, url, sucessMessage, onSucessMessage }) => {
    const [formMode] = useState(mode);
    const [showSuccess, setShowSuccess] = useState(false);
    const [fair, setFair] = useState({
        description: '',
        provinceId: '',
        districtId: '',
        locality: '',
        active: '',
        balance: '',
        date: '',
        beneficiaryGoal:'',
    });
    const [provinces, setProvinces] = useState([]);
    const [districts, setDistricts] = useState([]);
    const [districtData, setDistrictData] = useState([]);
    const [fairID, setFairID] = useState({
        id: null,
      });
    const [provinceLabel,setProvinceLabel]=useState('');
    const [districtLabel,setDistrictLabel]=useState('');
    const getSchema = () => {
        switch (mode) {
            case "CREATE":
                return {};
            case "UPDATE":
                return {};
            default:
                return {};
        }
    };

    const fetchProvinces = async () => {
        try {
            const response = await http.get('/provinces');
            let items = response.data.map(e => {
                return { id: e.id, text: e.description, children: e.children };
            })
            return { success: true, data: items };
        } catch (error) {
            return { success: false };
        }
    };

    useEffect(() => {
        (async () => {
            let response = await fetchProvinces();
            if (response.success) {

                setProvinces(response.data);

            }
        })()
    }, []);

    useEffect(() => {

        if (formMode === "UPDATE") {
            try {

            } catch (error) {
                toast.error(error);
            }
            // fetch product data
        }
        setShowSuccess(false);
    }, [formMode]);
    function onSubmit(data) {
        try{
            if(formMode ==="UPDATE"){
                http.post('/fairs/' + params.fairId, data);

            }else{
                http.post('/fairs', data);
            }
        }catch(error){
            toast.error(error.response?.data.message);
        }
        navigate("../inicio");
    };
    const retrieveFairs = async id => {
        const response = await http.get('/fairs/' + id);
        setFair(response.data);
        setFairID({ ...fairID, id: id });
        setProvinceLabel(response.data.province.description);
        setDistrictLabel(response.data.district.description);
        const fields =[
        "description",
        "provinceId",
        "districtId",
        "locality",
        "active",
        "balance",
        "date",
        "province",
        "district",
        "beneficiaryGoal",
        ];
        fields.forEach((field) => setValue(field, response.data[field]));
        return response.data;
      };
    const { register, handleSubmit, reset, setValue, getValues, watch, formState: { errors }, } = useForm({  mode: "onSubmit" });
    const navigate = useNavigate();
    const params = useParams();

    function onProvinceChange(e) {
        fair.provinceId = e.selectedItem.id;
        setDistrictData(e.selectedItem.children);
        let response = e.selectedItem.children.map(c => {
            return { id: c.id, text: c.description };
        });
        setValue("provinceId", e.selectedItem.id);

        setDistricts(response);
    };
    function onDistrictChange(e) {
        fair.districtId = e.selectedItem.id;
        setValue("districtId", e.selectedItem.id);
    };
    const getProvinceLabel = (id) => {
        return provinces.find(p =>p.id==id).text; 
       };
     
       const getDistrictLabel = (provinceID, districtID) => {
     
         return getDistricts().find(p =>p.id == districtID).text;
        };
     
        const getDistricts = () =>{
          return provinces
          .find(p => p.id==fair.provinceId)
          .children.map(e => { return { id: e.id, text: e.description, }});
        };  
        useEffect(() => {
            if(formMode === "UPDATE"){
                const getFairs = async () => {
                    const fairs = await retrieveFairs(params.fairId);
                  };
                  getFairs();
            }
          
          }, []);
          const setDate =e=>{ 
            setValue("date",e.target.value);
            fair.date=e.target.value;
          };
    return (
        <>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Row>
                    <Column>
                        <TextInput
                            id="description"
                            labelText="Nome da Feira"
                            {...register("description")}
                        />
                        <p className="field-error">{errors.description?.message}</p>

                    </Column>
                    <Column>
                        {
                            formMode === "UPDATE" ? <Dropdown
                                id="provinceId"
                                titleText="Provincia"
                                label={provinceLabel}
                                items={provinces}
                                onChange={onProvinceChange}
                                name="provinceId"
                                itemToString={item => (item ? item.text : '')}
                            /> : <Dropdown
                                id="provinceId"
                                titleText="Provincia"
                                label="Selecione"
                                items={provinces}
                                onChange={onProvinceChange}
                                name="provinceId"
                                itemToString={item => (item ? item.text : '')}
                            />
                        }

                        <p className="field-error">{errors.provinceId?.message}</p>
                    </Column>
                </Row>
                <Row>
                    <Column>
                        {
                            formMode === "UPDATE" ? <Dropdown
                                id="districtId"
                                titleText="Distrito"
                                label={districtLabel}
                                items={districts}
                                onChange={onDistrictChange}
                                name="districtId"
                                itemToString={item => (item ? item.text : '')}
                            /> : <Dropdown
                                id="districtId"
                                titleText="Distrito"
                                label="Selecione"
                                items={districts}
                                onChange={onDistrictChange}
                                name="districtId"
                                itemToString={item => (item ? item.text : '')}
                            />
                        }

                        <p className="field-error">{errors.districtId?.message}</p>
                    </Column>
                    <Column>
                        <TextInput
                            id="locality"
                            labelText="Localidade"
                            {...register("locality")}
                        />
                        <p className="field-error">{errors.locality?.message}</p>

                    </Column>
                </Row>
                <Row>
                    <Column>
                    {
                        formMode === "UPDATE" ? <DatePicker locale="no" dateFormat="d/m/Y" datePickerType="single">
                        <DatePickerInput
                            style={{ width: '330px' }}
                            labelText="Data"
                            id="date"
                            name="date"
                            value={fair.date}
                            {...register("date")}
                            onSelect={setDate}

                        />
                    </DatePicker> : <DatePicker locale="no" dateFormat="d/m/Y" datePickerType="single">
                        <DatePickerInput
                            style={{ width: '330px' }}
                            labelText="Data"
                            id="date"
                            name="date"
                            {...register("date")}
                            onSelect={setDate}

                        />
                    </DatePicker>
                    }
                       
                    </Column>
                    <Column>
                        <TextInput
                            id="balance"
                            labelText="Valor da Feira"
                            {...register("balance")}
                        />
                        <p className="field-error">{errors.balance?.message}</p>

                    </Column>
                </Row>
                <Row>
                    <Column>
                        <TextInput
                            id="beneficiaryGoal"
                            labelText="Meta de Beneficiarios"
                            {...register("beneficiaryGoal")}
                        />

                    </Column>
                    <Column>
                   
                    </Column>
                </Row>
                <Row>
                    <Column>
                        <ButtonSet>
                            <Button
                                type="submit"
                                renderIcon={SaveModel24}
                                iconDescription="Save"
                            >
                                Salvar
                            </Button>
                        </ButtonSet>
                    </Column>
                </Row>
            </Form>
        </>
    );
};

export default FairForm;