import React, { useEffect, useState } from 'react';
import http from '../../http-common';

import {
  Button,
  Pagination,
  DataTableSkeleton,
  OverflowMenuItem,
  OverflowMenu,
  Tab, Tabs, 
} from 'carbon-components-react';
import CustomTable from "../../components/custom-table/custom-table";
import { Link, useNavigate } from "react-router-dom";
import { useContext } from 'react';
import { AuthContext } from '../../contexts/AuthContext';
import { toast } from "react-toastify";
import PresenceFairPage from './PresenceFair';



const FairsPage = () => {
  const [currentPageSize, setCurrentPageSize] = useState(50);
  const [fairRows, setFairRows] = useState([]);
  const [fairs, setFairs] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState([]);
  const [locations, setLocations] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [approval, setApproval] = useState({});
  const [futureFairRows, setFutureFairRows] = useState([]);



  const authContext = useContext(AuthContext);
  const [role] = useState(authContext.user.roleName === 'ROLE_SELLER');


  const getHeaders = () => {

    switch (authContext.user.role) {
      case "ROLE_APPLICANT":
        return [
          { key: 'description', header: 'Descrição' },
          { key: 'date', header: 'Data' },
          { key: 'balance', header: 'Valor' },
          { key: 'province', header: 'Provincia' },
          { key: 'district', header: 'Distrito' },
          { key: 'status', header: 'Estado' },
          { key: 'actions', header: 'Acções' },
        ];
      case "ROLE_ADMIN":
        return [
          { key: 'description', header: 'Descrição' },
          { key: 'date', header: 'Data' },
          { key: 'balance', header: 'Valor' },
          { key: 'province', header: 'Provincia' },
          { key: 'district', header: 'Distrito' },
          { key: 'status', header: 'Estado' },
          { key: 'actions', header: 'Acções' },
        ];
      case "ROLE_SELLER":
        return [
          { key: 'description', header: 'Descrição' },
          { key: 'date', header: 'Data' },
          { key: 'province', header: 'Provincia' },
          { key: 'district', header: 'Distrito' },
          { key: 'status', header: 'Estado' },
          { key: 'fairPrecense', header: 'Participacao na Feira' },
          { key: 'actions', header: 'Acções' },
        ];
    }
  }

  const getAllLocations = async () => {
    const response = await http.get('/locations');
    setLocations(response.data);
    setIsLoading(false);
  };

  const getLocationDescriptionById = (id) => {
    const location = locations.find(l => l.id == id);
    return location != undefined ? location.description : id;
  };


  const retrieveCount = async () => {
    const response = await http.get('/fairs/count');
    setCount(response.data)
    return response.data;
  };

  const paging = async ({ page, pageSize }) => {
    var pag = currentPage * (page - 1);
    const response = await http.get('/fairs/' + pag + '/' + pageSize);
    const data = response.data.map(sensor => ({
      id: sensor.id,
      description: sensor.description,
      date: sensor.date,
      balance: sensor.balance,
      province: sensor.province.description,
      district: sensor.district.description,
      status: sensor.blocked ? 'Bloqueada' : 'Operacional',
      fairPrecense: modalFormPresence(sensor),
      actions: tableActions(sensor)
    }));
    setFairRows(data);
  };

  useEffect(() => {

    getAllLocations();

    const getAllFairs = async () => {
      const all = await retrieveFairs();
      await retrieveCount();
    };
    getAllFairs();
  }, []);
  const tableActions = (fair) => {
    switch (authContext.user.role) {
      case "ROLE_APPLICANT":
        return (<OverflowMenu
          flipped
        >

          <OverflowMenuItem
            key="optionOne"
            itemText="Editar"
            onClick={() => navigate("../edit-fair/" + fair.id)}
          />


          <OverflowMenuItem
            itemText={fair.blocked ? 'Desbloquear' : 'Bloquear'}
            onClick={() => blockOrUnblockFair(fair)}
          />

          <OverflowMenuItem
            key="optionThree"
            itemText="Vendas"
            onClick={() => navigate("../fairSales/" + fair.id, { state: { fairName: fair.description } })}
          />
        </OverflowMenu>

        );
      case "ROLE_ADMIN":
        return (<OverflowMenu
          flipped
        >

          <OverflowMenuItem
            key="optionOne"
            itemText="Editar"
            onClick={() => navigate("../edit-fair/" + fair.id)}
          />


          <OverflowMenuItem
            itemText={fair.blocked ? 'Desbloquear' : 'Bloquear'}
            onClick={() => blockOrUnblockFair(fair)}
          />

          <OverflowMenuItem
            key="optionThree"
            itemText="Vendas"
            onClick={() => navigate("../fairSales/" + fair.id, { state: { fairName: fair.description } })}
          />
        </OverflowMenu>

        );
      case "ROLE_SELLER":
        return (<OverflowMenu
          flipped
        >

          <OverflowMenuItem
            key="optionThree"
            itemText="Vendas"
            onClick={() => navigate("../fairSales/" + fair.id, { state: { fairName: fair.description } })}
          />
          <OverflowMenuItem
            key="optionFour"
            itemText="Solicitar participação"
            onClick={async () => {
              try {

                await http.post('seller/fair/approval/' + authContext.sellerID + '/' + fair.id, approval);
                toast.success("Solicitação enviada com sucesso");

              } catch (error) {
                toast.error(error.response?.data.message);
              }

            }
            }
          />
          <OverflowMenuItem
            key="optionFive"
            itemText="Estado da Aprovação"
            onClick={async () => {
              try {
                const response = await http.get('/seller/fair/approval/' + authContext.sellerID + '/' + fair.id);
                switch (response.data) {
                  case 0:
                    response.data = "Aprovado"

                    break;
                  case 1:
                    response.data = "Recusado"

                    break;
                  case 2:
                    response.data = "Pendente"

                    break;

                  case 3:
                    response.data = "Por Submeter"

                    break;
                }
                toast.success(response.data);
              } catch (error) {

              }
            }}
          />

        </OverflowMenu>
        )
    };
  };
  const modalFormPresence = (fair) => {
    return (
      <PresenceFairPage
      fair={fair}
      sellerId={authContext.sellerID}
      />
      
    )
  };
  const blockOrUnblockFair = async (fair) => {
    fair.blocked = !fair.blocked;
    await http.post('/fairs/update', fair);
    retrieveFairs();
  };

  const retrieveFairs = async () => {
    const response = await http.get('/fairs/' + 0 + '/' + currentPageSize);
    var futureFairs = [];
    const data = response.data.map(sensor => ({
      id: '' + sensor.id,
      description: authContext.user.role === "ROLE_SELLER" ? sensor.description : (<Link to={'../fairs/' + sensor.id}>{sensor.description}</Link>),
      date: sensor.date,
      balance: sensor.balance,
      province: sensor.province.description,
      district: sensor.district.description,
      status: sensor.blocked ? 'Bloqueada' : 'Operacional',
      fairPrecense: modalFormPresence(sensor),
      actions: tableActions(sensor)
    }));
    response.data.map(sensor => {
      var fairDate = sensor.date.toString().split("/");
      var fairDateConvert = new Date(fairDate[2], fairDate[1], fairDate[0]);
      var currentDate = new Date();
      if (fairDateConvert > currentDate) {
        futureFairs.push({
          id: sensor.id,
          description: authContext.user.role === "ROLE_SELLER" ? sensor.description : (<Link to={'../fairs/' + sensor.id}>{sensor.description}</Link>),
          date: sensor.date,
          balance: sensor.balance,
          province: sensor.province.description,
          district: sensor.district.description,
          status: sensor.blocked ? 'Bloqueada' : 'Operacional',
          fairPrecense: modalFormPresence(sensor),
          actions: tableActions(sensor)
        });
      }
    });
    setFairRows(data);
    setFairs(response.data);
    setFutureFairRows(futureFairs);
    return response.data;
  };
  const navigate = useNavigate();

  const addFair = () => {
    navigate("../create-fair");
  };
  const addNewFairButton = (
    <>
      {!role &&
        <div style={{ textAlign: 'end' }}>
          <Button onClick={addFair}>Adicionar Feira</Button>
        </div>
      }
    </>
  );
  const skeleton = <DataTableSkeleton headers={getHeaders()} />

  return (

    <div>
      {isLoading ? skeleton :
        <div>
          <Tabs>
            <Tab id="provider_fairs_tab_old" label="Antigas"> <CustomTable tableTitle="FEIRAS" tableHeaders={getHeaders()} tableRows={fairRows} isLoading={isLoading} addButton={addNewFairButton} />
              <Pagination
                totalItems={count}
                backwardText="Previous page"
                forwardText="Next page"
                pageSize={currentPageSize}
                pageSizes={[50]}
                itemsPerPageText="Items per page"
                onChange={paging}
                page={currentPage}
              /> </Tab>
            <Tab id="provider_fairs_tab_new" label="Futuras">
              <CustomTable tableTitle="FEIRAS Futuras" tableHeaders={getHeaders()} tableRows={futureFairRows} isLoading={isLoading} addButton={addNewFairButton} />
            </Tab>

          </Tabs>

        </div>}
    </div>
  );
};

export default FairsPage;