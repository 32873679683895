import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate, useParams } from "react-router-dom";
import Card from "../../components/Card/Card";
import {
    Button,
    Column,
    Dropdown,
    Row,
    Form,
    TextInput,
    TextArea,
    Select,
    SelectItem,
  } from "carbon-components-react";
import CompetitorDataForm from "../../components/Forms/CompetitorDataForm";
import useAuth from "../../hooks/useAuth";
import {
  submitApplicationSchema,
  submitBusinessProfileSchema,
} from "../../services/SchemaService";
import ExecutiveSummaryForm from "../../components/Forms/ExecutiveSummaryForm";
import BusinessProfileForm from "../../components/Forms/BusinessProfileForm";
import BusinessIdeaForm from "../../components/Forms/BusinessIdeaForm";
import useApplication from "../../hooks/useApplication";
import SubmitButton from "../../components/Buttons/SubmitButton";
import CancelButton from "../../components/Buttons/CancelButton";
import { ButtonSet } from "carbon-components-react";
import { Edit24 } from "@carbon/icons-react";
import { SendAlt24 } from "@carbon/icons-react";
import http from "../../http-common";
import { toast } from "react-toastify";
import useGet from "../../hooks/useGet";

const ViewGrievancePage = () => {
  const params = useParams();
  const [occurrenceNumber] = useState(params.occurrenceNumber);
  const [grivance, setGrivance] = useState({});
  const { data: provinces } = useGet("/utils/provinces");
  const { data: genders } = useGet("/utils/genders");
  const { data: types } = useGet("/utils/grievance-types");
  const [districts, setDistricts] = useState([]);
 
  const navigate = useNavigate();

  const handleProvinceChange = async (e) => {
    if (e.target.value) {
      try {
        const response = await http.get(
          "/utils/provinces/" + e.target.value + "/districts"
        );
        setDistricts(response.data);
      } catch (error) {
        toast.error(error?.response?.data.message);
      }
    } else {
      setDistricts([]);
    }
  };

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    const fetchData = async () => {
        try {
          const result = await http.get(`/grievances/search/occurrence/${occurrenceNumber}`);
        //   setGrivance(result.data);
          reset(result.data);
        } catch (error) {
          toast.error(error.response?.data.message);
        }
      };

      fetchData();

     
  }, [occurrenceNumber]);

  return (
    <>
      <Card title="Reclamação">
      <Form>
                    
                      <Row>
                        <Column sm={12} md={6} lg={6}>
                          <TextInput
                            id="name"
                            labelText="Nome"
                            disabled={true}
                            {...register("name")}
                          />
                        </Column>
                        <Column sm={12} md={3} lg={3}>
                          <TextInput
                            id="age"
                            labelText="Idade"
                            disabled={true}
                            {...register("age")}
                          />
                        </Column>
                        <Column sm={12} md={3} lg={3}>
                          <Select
                            id="gender"
                            labelText="Genero"
                            disabled={true}
                            {...register("gender")}
                          >
                            <SelectItem text="" value="" />
                            {genders.map((p) => (
                              <SelectItem
                                text={p.description}
                                value={p.code}
                                key={p.code}
                              />
                            ))}
                          </Select>
                        </Column>
                      </Row>
                      <Row>
                        <Column sm={12} md={3} lg={3}>
                          <TextInput
                            id="email"
                            labelText="Email"
                            disabled={true}
                            {...register("email")}
                          />
                        </Column>
                        <Column sm={12} md={3} lg={3}>
                          <TextInput
                            id="phone"
                            labelText="Telefone"
                            disabled={true}
                            {...register("phone")}
                          />
                        </Column>
                        <Column xml={12} sm={12} md={3} lg={3}>
                          <Select
                            id="provinceId"
                            labelText="Província"
                            disabled={true}
                            {...register("provinceId")}
                            onChange={handleProvinceChange}
                          >
                            <SelectItem text="" value="" />
                            {provinces.map((p) => (
                              <SelectItem
                                text={p.description}
                                value={p.id}
                                key={p.id}
                              />
                            ))}
                          </Select>
                        </Column>

                        <Column xml={12} sm={12} md={3} lg={3}>
                          <Select
                            id="districtId"
                            name="districtId"
                            labelText="Cidade/Distrito"
                            disabled={true}
                            // value={application?.districtId || ""}
                            {...register("districtId")}
                          >
                            <SelectItem text="" value="" />
                            {districts.map((p) => (
                              <SelectItem
                                text={p.description}
                                value={p.id}
                                key={p.id}
                              />
                            ))}
                          </Select>
                        </Column>
                      </Row>

                      <Row>
                        <Column sm={2} md={6} lg={6} className="required">
                          <Select
                            id="typeCode"
                            labelText="Tipo"
                            disabled={true}
                            {...register("typeCode")}
                          >
                            <SelectItem text="" value="" />
                            {types.map((p) => (
                              <SelectItem
                                text={p.description}
                                value={p.code}
                                key={p.code}
                              />
                            ))}
                          </Select>
                          <p className="field-error">{errors.type?.message}</p>
                        </Column>
                        <Column sm={2} md={6} lg={6} className="required">
                          <TextInput
                            id="subjuct"
                            labelText="Assunto"
                            disabled={true}
                            {...register("subject")}
                          />
                          <p className="field-error">
                            {errors.subject?.message}
                          </p>
                        </Column>
                      </Row>

                      <Row>
                        <Column sm={12} md={12} lg={12} className="required">
                          <TextArea
                            id="description"
                            labelText="Descrição"
                            enableCounter
                            rows={10}
                            disabled={true}
                            {...register("description")}
                          />
                          <p className="field-error">
                            {errors.description?.message}
                          </p>
                        </Column>
                      </Row>
                      <div className="form-buttons mt-1">
                      <CancelButton
                label="Voltar"
              />
          </div>
                  </Form>
      </Card>
    </>
  );
};

export default ViewGrievancePage;
