import {
  Button,
  DataTable,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
  OverflowMenuItem,
  OverflowMenu,
} from "carbon-components-react";
import { useEffect, useState, useContext } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, Link } from "react-router-dom";
import http from "../../http-common";
import { Add16, Edit24, Search32 } from "@carbon/icons-react";
import Card from "../../components/Card/Card";
import TablePagination from "../../components/Pagination/Pagination";

const tableHeaders = [
  {
    header: "Tipo de serviço",
    key: "type",
  },
  {
    header: "Descrição",
    key: "description",
  },
  // {
  //   header: "Data de previsao de início",
  //   key: "prevStartDate",
  // },
  {
    header: "Data de início",
    key: "startDate",
  },
  // {
  //   header: "Data de previsao de término",
  //   key: "prevendDate",
  // },
  {
    header: "Data de término",
    key: "endDate",
  },
  {
    header: "Status",
    key: "status",
  },
  {
    header: "Operações",
    key: "actions",
  },
];

const LinkList = ({ url, url2 }) => (
  <ul style={{ display: "flex" }}>
    <li>
      <Link to={url} title="Editar">
        <Edit24 />
      </Link>
    </li>
    &nbsp;&nbsp;&nbsp;
  </ul>
);

const ProjectServicePage = ({ projectId }) => {
  const [service, setservice] = useState({
    description: "",
  });

  const [services, setServices] = useState([]);
  const [count, setCount] = useState(0);
  const [size, setSize] = useState(20);
  const [isLoading, setIsLoading] = useState(true);
  const [type, setType] = useState([]);

  const {
    formState: { errors },
  } = useForm();

  const navigate = useNavigate();

  //Service type
  const getAllServices = async () => {
    const response = await http.get("/servicetypes");
    setType(response.data);
    setIsLoading(false);
  };

  const featchData = async () => {
    try {
      const response = await http.get(`/services/projects/${projectId}`);
      getserviceDate(response);
    } catch (error) {}
  };

  const getserviceDate = (response) => {
    try {
      const data = response?.data?.map((service) => ({
        id: service.id,
        type: service.type,
        description: service.description,
        endDate: service.endDate,
        status: service.status,
        prevStartDate: service.startDate,
        startDate: service.startDate,
        prevendDate: service.prevendDate,

        actions: <LinkList url={"../update-service/" + service.id} />,
      }));
      setServices(data);
      setCount(response.data.totalElements);
      console.log("Data oout", data);
    } catch (error) {
      console.log("Errdfjdhkfb.kdsf");
    }
  };

  const onPageChange = async ({ page, pageSize }) => {
    try {
      var pag = page - 1;
      const response = await http.get(`/services/projects/${projectId}`);
      getserviceDate(response);
    } catch (error) {}
  };

  useEffect(() => {
    getAllServices();
    featchData();
  }, []);

  const tableActions = (client) => {
    return (
      <OverflowMenu flipped>
        <OverflowMenuItem
          key="optionOne"
          itemText="Edit"
          onClick={() => navigate("../update-service/" + client.id)}
        />
      </OverflowMenu>
    );
  };

  // change format for Project Value
  function formatMz(num) {
    var p = num.toFixed(2).split(".");
    return (
      p[0]
        .split("")
        .reverse()
        .reduce(function (acc, num, i, orig) {
          return num + (num != "-" && i && !(i % 3) ? "." : "") + acc;
        }, "") +
      "," +
      p[1]
    );
  }

  return (
    <Card title="Consultar Actividades">
      <div className="mb-2 form-buttons">
        <p></p>
        <Button
          kind="ghost"
          type="button"
          onClick={() => navigate("../registar-servicos/" + projectId)}
          renderIcon={Add16}
        >
          Registar Actividade
        </Button>
      </div>

      <DataTable
        rows={services}
        headers={tableHeaders}
        isSortable
        useZebraStyles
      >
        {({ rows, headers, getHeaderProps, getTableProps }) => (
          <TableContainer>
            <Table {...getTableProps()}>
              <TableHead>
                <TableRow>
                  {headers.map((header) => (
                    <TableHeader {...getHeaderProps({ header })}>
                      {header.header}
                    </TableHeader>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow key={row.id}>
                    {row.cells.map((cell) => (
                      <TableCell key={cell.id}>
                        {cell.value?.content ?? cell.value}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </DataTable>

      <TablePagination
        currentPageSize={size}
        onPageChange={onPageChange}
        totalItems={count}
      />
    </Card>
  );
};

export default ProjectServicePage;
