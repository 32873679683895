import {
  Button,
  Form,
  TextInput,
  Row,
  Column,
  ButtonSet,
  Dropdown,
} from "carbon-components-react";
import { useEffect, useState, useContext } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import http from "../../http-common";
import { SaveModel24 } from "@carbon/icons-react";
import { clientContactSchema } from "../../services/SchemaService";
import { yupResolver } from "@hookform/resolvers/yup";

const ContactForm = ({ mode, url, contactId, clientid }) => {
  const [formMode] = useState(mode);
  const [showSuccess, setShowSuccess] = useState(false);
  const [isBtnDisabled, setisBtnDisabled] = useState(false);
  const [contact, setContact] = useState({
    name: "",
    surName: "",
    position: "",
    contactOloga: "",
    email: "",
    opcionalEmail: "",
    phoneNumber: "",
    faxNumber: "",

    client: { id: clientid },
  });

  const [clients, setClient] = useState([]);
  const [positionContact, setPositionContact] = useState([]);

  const getSchema = () => {
    switch (mode) {
      case "UPDATE":
        return clientContactSchema;
      case "CREATE":
        return clientContactSchema;
      default:
        return clientContactSchema;
    }
  };
  useEffect(() => {
    (async () => {
      let response = await getclients();
      if (response.success) {
        setClient(response.data);
      }
    })();
  }, []);

  const getclients = async () => {
    try {
      const response = await http.get("/clients");
      let items = response.data.map((e) => {
        return { id: e.id, text: e.name, children: e.children };
      });
      return { success: true, data: items };
    } catch (error) {
      return { success: false };
    }
  };

  //fetch POSITION_CONTACT

  useEffect(() => {
    (async () => {
      let response = await fetchPositionContact();
      if (response.success) {
        setPositionContact(response.data);
      }
    })();
  }, []);

  const fetchPositionContact = async () => {
    try {
      const response = await http.get("/positioncontact");
      let items = response.data.map((e) => {
        return { id: e.id, text: e.description, children: e.children };
      });
      return { success: true, data: items };
    } catch (error) {
      return { success: false };
    }
  };

  const delay = (ms) => new Promise((res) => setTimeout(res, ms));

  async function onSubmit(data) {
    try {
      setisBtnDisabled(true);
      var _data = null;

      if (formMode === "UPDATE") {
        _data = await http.post(url, data);
      } else {
        _data = await http.post(url, data);
      }

      toast.success("Operação realizada com sucesso!");
      await delay(4000); // breaking
      navigate("../actualizar-contacto/" + _data.data.id);
    } catch (error) {
      setisBtnDisabled(false);
      toast.error(error.response?.data.message);
    }
  }

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = useForm({ resolver: yupResolver(getSchema()), mode: "onSubmit" });
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    if (formMode === "UPDATE") {
      const fetchData = async () => {
        try {
          const result = await http.get(`/contacts/${contactId}`);
          const fields = [
            "id",
            "name",
            "surName",
            "position",
            "contactOloga",
            "email",
            "opcionalEmail",
            "phoneNumber",
            "faxNumber",
          ];
          fields.forEach((field) => setValue(field, contact[field]));
          setContact(result.data);
        } catch (error) {
          toast.error(error.response?.data.message);
        }
      };

      fetchData();
    }
    setShowSuccess(false);
  }, [formMode]);

  useEffect(() => {
    reset(contact);
  }, [contact]);

  function onPositionContact(e) {
    contact.position = e.selectedItem.text;
    setValue("position", e.selectedItem.text);
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Row>
        <Column sm={6} md={6} lg={6} className="required">
          <TextInput id="name" labelText="Nome" {...register("name")} />
          <p className="field-error">{errors.name?.message}</p>
        </Column>
        <Column sm={6} md={6} lg={6} className="required">
          <TextInput
            id="surName"
            labelText="Apelido"
            {...register("surName")}
          />
          <p className="field-error">{errors.surName?.message}</p>
        </Column>
      </Row>
      <Row>
        <Column sm={6} md={6} lg={6} className="required">
          <Dropdown
            id="position"
            titleText="Função"
            label={formMode == "UPDATE" ? contact.position : "Selecione"}
            items={positionContact}
            onChange={onPositionContact}
            name="position"
            itemToString={(item) => (item ? item.text : "")}
          />
          <p className="field-error">{errors.position?.message}</p>
        </Column>
        <Column sm={6} md={6} lg={6} className="required">
          <TextInput
            id="contactOloga"
            labelText="Contacto na ologa"
            {...register("contactOloga")}
          />
          <p className="field-error">{errors.contactOloga?.message}</p>
        </Column>
      </Row>
      <Row>
        <Column sm={6} md={6} lg={6} className="required">
          <TextInput id="email" labelText="Email" {...register("email")} />
          <p className="field-error">{errors.email?.message}</p>
        </Column>
        <Column sm={6} md={6} lg={6}>
          <TextInput
            id="opcionalEmail"
            labelText="Email Opcional"
            {...register("opcionalEmail")}
          />
        </Column>
      </Row>
      <Row>
        <Column sm={6} md={6} lg={6}>
          <TextInput
            id="phone_number"
            labelText="Número de telefone"
            {...register("phoneNumber")}
            maxLength={12}
          />
        </Column>
        <Column sm={6} md={6} lg={6}>
          <TextInput
            id="fax_number"
            labelText="Telefone opcional"
            {...register("faxNumber")}
            maxLength={12}
          />
        </Column>
      </Row>
      <Row className="mt-2">
        <Column>
          <ButtonSet>
            <Button
              type="submit"
              renderIcon={SaveModel24}
              iconDescription="Save"
            >
              Salvar
            </Button>
            <Button onClick={() => navigate("../update_client/" + clientid)}>
              Voltar para cliente
            </Button>
          </ButtonSet>
        </Column>
      </Row>
    </Form>
  );
};

export default ContactForm;
