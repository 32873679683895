import {
  Button,
  Form,
  TextInput,
  Row,
  Column,
  DataTable,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
  TableToolbar,
  TableToolbarContent,
  OverflowMenuItem,
  OverflowMenu,
} from "carbon-components-react";
import { useEffect, useState, useContext } from "react";
import { useForm } from "react-hook-form";
import http from "../../http-common";
import { Link, useNavigate } from "react-router-dom";
import { Add16, Search32, Edit24, Document16 } from "@carbon/icons-react";
import Card from "../../components/Card/Card";
import TablePagination from "../../components/Pagination/Pagination";
import jsPDF from "jspdf";

const tableHeaders = [
  {
    header: "Nome do cliente",
    key: "clientAbreviation",
  },
  // {
  //   header: "Empresa associada",
  //   key: "clientCompany",
  // },
  {
    header: "Nome de do projecto",
    key: "projectName",
  },
  {
    header: "Tipo de serviço",
    key: "type",
  },
  {
    header: "Descrição",
    key: "description",
  },
  {
    header: "Inicio",
    key: "startDate",
  },
  {
    header: "Fim",
    key: "endDate",
  },
  {
    header: "Valor da Actividade",
    key: "value",
  },
  {
    header: "Valor em outra moeda",
    key: "correspondedValue",
  },
  {
    header: "Status",
    key: "status",
  },
  {
    header: "Operações",
    key: "actions",
  },
];

const LinkList = ({ url, url2 }) => (
  <ul style={{ display: "flex" }}>
    <li>
      <Link to={url} title="Editar">
        <Edit24 />
      </Link>
    </li>
    &nbsp;&nbsp;&nbsp;
  </ul>
);
const ListServicePage = () => {
  const [services, setServices] = useState([]);
  const [parameters, setParameters] = useState();
  const [count, setCount] = useState(0);
  const [size, setSize] = useState(20);
  const [isLoading, setIsLoading] = useState(true);
  const [type, setType] = useState([]);
  const [status, setStatus] = useState([]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const navigate = useNavigate();

  //Service type
  const getAllServices = async () => {
    const response = await http.get("/servicetypes");
    setType(response.data);
    setIsLoading(false);
  };

  //Status
  const getStatus = async () => {
    const response = await http.get("/status");
    setStatus(response.data);
    setIsLoading(false);
  };

  const featchData = async () => {
    try {
      const response = await http.get(
        "/service/client/search/filter?size=" + size
      );
      getserviceDate(response);
    } catch (error) {}
  };

  const getserviceDate = (response) => {
    try {
      const data = response.data.content.map((service) => ({
        id: service.id,
        type: service.type,
        description: service.description,
        startDate: service.startDate,
        endDate: service.endDate,
        status: service.status,
        clientCompany: service.clientCompany,
        projectName: service.projectName,
        clientAbreviation: service.clientAbreviation,
        value:
          formatMz(service.value) +
          " " +
          (service.valueCoinType ? service.valueCoinType : ""),
        correspondedValue:
          formatMz(service.correspondedValue) +
          " " +
          (service.correspondedValueCoinType
            ? service.correspondedValueCoinType
            : ""),
        actions: <LinkList url={"../update-service/" + service.id} />,
      }));
      setServices(data);
      setCount(response.data.totalElements);
      console.log("data out", data)
    } catch (e) {
      console.log("error ao lista", e);
    }
  };

  const onPageChange = async ({ page, pageSize }) => {
    try {
      var pag = page - 1;
      const response = await http.get(
        `/service/client/search/filter/${parameters.description}?page=${pag}&size=${pageSize}`
      );
      getserviceDate(response);
    } catch (error) {}
  };

  const onSearch = async (data) => {
    try {
      const response = await http.get(
        `/service/client/search/filter?clientAbreviation=${data.clientAbreviation}&projectName=${data.projectName}&description=${data.description}`
      );
      getserviceDate(response);
      setParameters(data);
    } catch (error) {}
  };
  useEffect(() => {
    getStatus();
    featchData();
  }, []);

  //Export documents
  const exportPDF = () => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 200;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = "Ordem de serviço";
    const headers = [
      [
        "Nome do Cliemte",
        "Nome do project",
        "Tipo de servico",
        "Descrição",
        "Inicio",
        "Fim",
        "Status",
      ],
    ];
    const date = new Date().toLocaleDateString();
    const data = services.map((st) => [
      st.clientAbreviation,
      st.projectName,
      st.type,
      st.description,
      st.startDate,
      st.endDate,
      st.status,
    ]);

    let content = {
      startY: 50,
      head: headers,
      body: data,
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save(`Ordem d servico_${date}.pdf`);
  };


     // change format for Service Value
     function formatMz(num) {
      if (num != null) {
        var p = num.toFixed(2).split(".");
        return (
          p[0]
            .split("")
            .reverse()
            .reduce(function (acc, num, i, orig) {
              return num + (num != "-" && i && !(i % 3) ? "." : "") + acc;
            }, "") +
          "," +
          p[1]
        );
      } else {
        return "";
      }
    }

  const tableActions = (client) => {
    return (
      <OverflowMenu flipped>
        <OverflowMenuItem
          key="optionOne"
          itemText="Edit"
          onClick={() => navigate("../update-service/" + client.id)}
        />
      </OverflowMenu>
    );
  };

 

  return (
    <Card title="Consultar Actividades">
      <DataTable
        rows={services}
        headers={tableHeaders}
        isSortable
        useZebraStyles
      >
        {({ rows, headers, getHeaderProps, getTableProps }) => (
          <TableContainer>
            <TableToolbar aria-label="data table toolbar">
              <TableToolbarContent>
                <Form
                  className="data-table-search-form"
                  onSubmit={handleSubmit(onSearch)}
                >
                  <Row>
                    <Column sm={4} md={4} lg={4}>
                      <TextInput
                        id="clientAbreviation"
                        labelText=""
                        placeholder="Nome do cliente"
                        {...register("clientAbreviation")}
                      />
                    </Column>
                    <Column sm={4} md={4} lg={4}>
                      <TextInput
                        id="projectName"
                        labelText=""
                        placeholder="Nome do projecto"
                        {...register("projectName")}
                      />
                    </Column>
                    <Column sm={4} md={4} lg={4}>
                      <TextInput
                        id="description"
                        labelText=""
                        placeholder="Descrição"
                        {...register("description")}
                      />
                    </Column>
                  </Row>

                  <Button type="submit" renderIcon={Search32}>
                    Pesquisar
                  </Button>
                  <Button
                    style={{ marginLeft: "0.1rem" }}
                    onClick={exportPDF}
                    renderIcon={Document16}
                  >
                    Exportar
                  </Button>
                </Form>
              </TableToolbarContent>
            </TableToolbar>
            <Table {...getTableProps()}>
              <TableHead>
                <TableRow>
                  {headers.map((header) => (
                    <TableHeader {...getHeaderProps({ header })}>
                      {header.header}
                    </TableHeader>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow key={row.id}>
                    {row.cells.map((cell) => (
                      <TableCell key={cell.id}>
                        {cell.value?.content ?? cell.value}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </DataTable>

      <TablePagination
        currentPageSize={size}
        onPageChange={onPageChange}
        totalItems={count}
      />
    </Card>
  );
};

export default ListServicePage;
