import React, { useState,useEffect } from "react";
import { Search32 } from "@carbon/icons-react";

import Card from "../../components/Card/Card";
import http from "../../http-common";
import { Row } from "carbon-components-react";
import {
  Button,
  DataTable,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
  TableToolbar,
  TableToolbarContent,
  TableToolbarSearch,
} from "carbon-components-react";
const tableHeaders =[
    {
        header:"Nome",
        key:"name"
    },
    {
        header:"Descricao",
        key:"description"
    },
    {
        header:"Operacoes",
        key:"actions"
    },
];
const InsuranceCompanyPage = ()=>{
    const [iCompanies,setICompanies]=useState([]);
    const featchData = async()=>{
        try {
            const response = await http.get("/icompanies");
            getICompaniesData(response);
        } catch (error) {
            
        }
    };
    const getICompaniesData=(response)=>{
        const data =    response.data.map((iCompany)=>({
            id:iCompany.id,
            name:iCompany.name,
            description:iCompany.description,
            actions:(<a>link</a>),
        }));
        setICompanies(data);
    };
    useEffect(()=>{
        featchData();
    },[]);
    return (<Card title="Seguradoras">
        <DataTable rows={iCompanies} headers={tableHeaders} isSortable useZebraStyles>
            {({rows,headers,getHeaderProps,getTableProps})=>(
                <TableContainer>
                    <TableToolbar aria-label="data table toolbar"> 
                    <TableToolbarContent> 
                    <Button type="submit" renderIcon={Search32}>
                    Pesquisar
                  </Button>
                    </TableToolbarContent>
                    </TableToolbar>
                    <Table> 
                        <TableHead> 
                            <TableRow> 
                                { 
                                headers.map((header)=>( 
                                    <TableHeader {...getHeaderProps({header})}>

                                        {header.header}
                                    </TableHeader>
                                ))
                                }
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            { 
                            rows.map((row)=>(
                                <TableRow key={row.id}>
                                    {
                                        row.cells.map((cell)=>( 
                                            <TableCell key={cell.id}>{cell.value}</TableCell>
                                        ))
                                    }
                                </TableRow>
                            ))
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </DataTable>
    </Card>);
};

export default InsuranceCompanyPage;