import {
  Button,
  Form,
  TextInput,
  Row,
  Column,
  ButtonSet,
  Dropdown,
  Loading,
  TextArea,
} from "carbon-components-react";
import React from "react";
import { useEffect, useState, useContext } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import http from "../../http-common";
import { SaveModel24 } from "@carbon/icons-react";
import ProvinceCombo from "../../components/Combos/ProvinceCombo";
import { providerCRMSchema } from "../../services/SchemaService";
import { yupResolver } from "@hookform/resolvers/yup";

const ProviderForm = ({ disabled, mode, url, provinceId, providerId }) => {
  const [formMode] = useState(mode);
  const [showSuccess, setShowSuccess] = useState(false);
  const [provider, setProvider] = useState({
    address: "",
    name: "",
    nuit: "",
    provinceId: "",
    phoneNumber: "",
    faxNumber: "",
    email: "",
    website: "",
    comment: "",
  });
  const [type, setType] = useState([]);
  const [location, setLocation] = useState([]);

  const [showLoading, setShowLoading] = useState(false);
  const [isBtnDisabled, setisBtnDisabled] = useState(false);

  const getSchema = () => {
    switch (mode) {
      case "UPDATE":
        return providerCRMSchema;
      case "CREATE":
        return providerCRMSchema;
      default:
        return providerCRMSchema;
    }
  };

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    watch,
    control,
    formState: { errors },
  } = useForm({ resolver: yupResolver(getSchema()), mode: "onSubmit" });

  const delay = (ms) => new Promise((res) => setTimeout(res, ms));

  async function onSubmit(data) {
    try {
      setisBtnDisabled(true);
      var result = null;

      if (formMode === "UPDATE") {
        result = await http.post(url, data);
        toast.success("Operação realizada com sucesso!");
        await delay(2000); // breaking
        navigate("../fornecedor");
      } else {
        result = await http.post(url, data);

        console.log("create url", url);
        console.log("create data", result);

        if (result?.data.msg !== undefined) {
          toast.error("Operação não realizada!: \n " + result?.data.msg);
          setisBtnDisabled(false);
        } else {
          toast.success("Operação realizada com sucesso!");
          await delay(2000); // breaking

          navigate("../fornecedor");
        }
      }
    } catch (error) {
      setisBtnDisabled(false);
      toast.error(error.response?.data.message);
    }
  }

  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    if (formMode === "UPDATE") {
      const fetchData = async () => {
        try {
          const result = await http.get(`/provider/${providerId}`);
          const fields = [
            "id",
            "address",
            "name",
            "nuit",
            "provinceId",
            "phoneNumber",
            "faxNumber",
            "email",
            "website",
            "comment",
          ];
          fields.forEach((field) => setValue(field, provider[field]));
          setProvider(result.data);
        } catch (error) {
          toast.error(error.response?.data.message);
        }
      };

      fetchData();
    }
    setShowSuccess(false);
  }, [formMode]);

  useEffect(() => {
    reset(provider);
  }, [provider]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Row>
        <Column sm={6} md={6} lg={6} className="required">
          <TextInput
            id="name"
            labelText="Nome"
            maxLength={64}
            {...register("name")}
          />
          <p className="field-error">{errors.name?.message}</p>
        </Column>
        <Column sm={6} md={6} lg={6}>
          <TextInput
            id="address"
            labelText="Endereço"
            {...register("address")}
            maxLength={50}
          />
        </Column>
        <Column sm={6} md={6} lg={6}>
          <TextInput
            id="nuit"
            labelText="Nuit"
            {...register("nuit")}
            maxLength={50}
          />
        </Column>
        <Column sm={6} md={6} lg={6}>
          <TextInput
            id="phoneNumber"
            labelText="Número de Telefone"
            {...register("phoneNumber")}
            maxLength={50}
          />
        </Column>
      </Row>
      <Row>
        <Column sm={6} md={6} lg={6}>
          <TextInput
            id="faxNumber"
            labelText="Telefone alternativo ou fixo"
            {...register("faxNumber")}
            maxLength={50}
          />
        </Column>
        <Column sm={6} md={6} lg={6} className="required">
          <TextInput
            id="email"
            labelText="Email"
            {...register("email")}
            maxLength={50}
          />
          <p className="field-error">{errors.email?.message}</p>
        </Column>
      </Row>
      <Row>
        <Column sm={6} md={6} lg={6}>
          <TextInput
            id="website"
            labelText="Website"
            {...register("website")}
            maxLength={50}
          />
        </Column>
        <Column sm={6} md={6} lg={6}>
          <TextArea
            id="comment"
            labelText="Comentários"
            helperText="Adicione  dados adicionais"
            {...register("comment")}
          />
        </Column>
      </Row>
      <Row className="mt-2">
        <Column sm={12} md={6} lg={6}>
          <ButtonSet>
            <Button
              disabled={isBtnDisabled}
              type="submit"
              renderIcon={SaveModel24}
              iconDescription="Save"
            >
              Salvar
              {showLoading == true ? (
                <Loading
                  description="Active loading indicator"
                  withOverlay={false}
                  small
                />
              ) : (
                ""
              )}
            </Button>
            <Button onClick={() => navigate("../fornecedor")}>Voltar</Button>
          </ButtonSet>
        </Column>
      </Row>
    </Form>
  );
};

export default ProviderForm;
