import React from "react";
import Card from "../../components/Card/Card";
import ConfigurationForm from "./ConfigurationForm";

const CreateConfigurationPage = (configurationId) => {
  return (
    <>
      <ConfigurationForm
        mode={"CREATE"}
        url={"/addconfigurations"}
        successMessage={"Registo salvo com sucesso"}
        // onSuccessNavigateTo={"../clientes"}
      />
    </>
  );
};

export default CreateConfigurationPage;
