import React, { useEffect, useState,useContext } from 'react';
import http from '../../http-common';

import {
   ModalWrapper, Form, Row,
  Column,
  TextInput, 
} from 'carbon-components-react';

import { toast } from "react-toastify";
import { AuthContext } from '../../contexts/AuthContext';

const PresenceFairPage =(fair,sellerId)=>{
    const [numberOfStall, setNumberOfStall] = useState({});

    const authContext = useContext(AuthContext);

    const handleNumberOfStall = (e) => {
        setNumberOfStall({
        value: e.nativeEvent.data
        });
        if (e.nativeEvent
          .data > 2) {
          toast.error("Numero Invalido de Bancas deve ser de 1 a 2");
        }
      };

      const submitApproval = async () => {
        try {
         await http.post('seller/fair/approval/' + fair.sellerId + '/' + fair.fair.id + "/" + numberOfStall.value);
          toast.success("Solicitação enviada com sucesso");
    
        } catch (error) {
          toast.error(error.response?.data.message);
        }
      };
    return (
        <> 
           <ModalWrapper
        buttonTriggerText="Solicitar Participacao"
        modalHeading="Participacao na Feira"
        modalLabel=""
        primaryButtonText="Submeter"
        secondaryButtonText={null}
        handleSubmit={() => submitApproval()}
      >
        <p>Indique o numero de bancas que prentede para esta Feira de {fair.fair.description}</p>
        {" "}

        <Row>
          <Column sm={8} md={8} lg={8}>

            <TextInput
              id="numberOfStalls"
              maxLength={1}
              labelText="Numero de Bancas"
              placeholder="O Numero de Bancas deve ser de 1 a 2"
              onChange={handleNumberOfStall}
            />
            <p>Numero de Bancas deve ser de 1 a 2</p>

          </Column>

        </Row>



      </ModalWrapper>
        </>
    );
};

export default PresenceFairPage;