import React, { useState, useEffect } from "react";
import Card from "../../components/Card/Card";
import { Form } from "carbon-components-react";
import {
  Row,
  Column,
  TextInput,
  Button,
  DataTable,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
  TableToolbar,
  TableToolbarContent,
  OverflowMenuItem,
  OverflowMenu,
} from "carbon-components-react";
import { Link, useNavigate } from "react-router-dom";

import http from "../../http-common";
import { Add16, Search32, Edit24 } from "@carbon/icons-react";
import TablePagination from "../../components/Pagination/Pagination";
import { useForm } from "react-hook-form";

const tableHeaders = [
  {
    header: "Nome",
    key: "firstName",
  },
  {
    header: "Apelido",
    key: "surName",
  },

  {
    header: "Data de Nascimento",
    key: "dateOfBirth",
  },

  {
    header: "Género",
    key: "gender",
  },
  {
    header: "Código de BI",
    key: "biCode",
  },
  {
    header: "Email",
    key: "email",
  },
  {
    header: "Telefone",
    key: "phoneNumber",
  },
  {
    header: "Telefone Opcional",
    key: "faxNumber",
  },  
  {
    header: "Grau académico",
    key: "academicDegree",
  },
  {
    header: "Operações",
    key: "actions",
  },
];

const LinkList = ({ url, url2 }) => (
  <ul style={{ display: "flex" }}>
    <li>
      <Link to={url} title="Editar">
        <Edit24 />
      </Link>
    </li>
    &nbsp;&nbsp;&nbsp;
  </ul>
);

const EmployeeListPage = () => {
  const navigate = useNavigate();

  const [employee, setEmployee] = useState([]);
  const [department, setDepartment] = useState([]);
  const [count, setCount] = useState(0);
  const [size, setSize] = useState(20);
  const [isLoading, setIsLoading] = useState(true);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const featchData = async () => {
    try {
      const response = await http.get("/employees/search/filter?size=" + size);
      getEmployeeData(response);
    } catch (error) {}
  };
 
  const getEmployeeData = (response) => {
    const data = response.data.content.map((employee) => ({
      id: employee.id,
      firstName: employee.firstName,
      surName: employee.surName,
      academicDegree: employee.academicDegree,
      contact: employee.contact,
      email: employee.email,
      gender: employee.gender,
      biCode: employee.biCode,
      dateOfBirth: employee.dateOfBirth,
      phoneNumber: employee.phoneNumber,
      faxNumber: employee.faxNumber,

      actions: <LinkList url={"../actualizar-funcionario/" + employee.id} />,
    }));
    setEmployee(data);
    setCount(response.data.totalElements);
  };

  const onPageChange = async ({ page, pageSize }) => {
    try {
      var pag = page - 1;
      const response = await http.get(
        "/employees/search/filter?page=" + pag + "&size=" + pageSize
      );
      getEmployeeData(response);
    } catch (error) {}
  };

  const onSearch = async (data) => {
    try {
      const response = await http.get(
        `/employees/search/filter?firstName=${data.firstName}`
      );
      getEmployeeData(response);
    } catch (error) {}
  };

  //Client

  const getDepartment = async () => {
    const response = await http.get("/departments");
    setDepartment(response.data);
    setIsLoading(false);
  };

  useEffect(() => {
    getDepartment();
    featchData();
  }, []);

  const tableActions = (employee) => {
    return (
      <OverflowMenu flipped>
        <OverflowMenuItem
          key="optionOne"
          itemText="Edit"
          onClick={() => navigate("../actualizar-funcionario/" + employee.id)}
        />
      </OverflowMenu>
    );
  };

  return (
    <Card title="Consultar Funcionários">
      <div className="mb-2 form-buttons">
        <p></p>
        <Button
          kind="ghost"
          type="button"
          onClick={() => navigate("../registar-funcionario")}
          renderIcon={Add16}
        >
          Registar Funcionário
        </Button>
      </div>

      <DataTable
        rows={employee}
        headers={tableHeaders}
        isSortable
        useZebraStyles
      >
        {({ rows, headers, getHeaderProps, getTableProps }) => (
          <TableContainer>
            <TableToolbar aria-label="data table toolbar">
              <TableToolbarContent>
                <Form
                  className="data-table-search-form"
                  onSubmit={handleSubmit(onSearch)}
                >
                  <Row>
                    <Column sm={6} md={6} lg={5}>
                      <TextInput
                        id="firstName"
                        labelText=""
                        placeholder="Name"
                        {...register("firstName")}
                      />
                    </Column>
                  </Row>
                  <Button type="submit" renderIcon={Search32}>
                    Search
                  </Button>
                </Form>
              </TableToolbarContent>
            </TableToolbar>
            <Table {...getTableProps()}>
              <TableHead>
                <TableRow>
                  {headers.map((header) => (
                    <TableHeader {...getHeaderProps({ header })}>
                      {header.header}
                    </TableHeader>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow key={row.id}>
                    {row.cells.map((cell) => (
                      <TableCell key={cell.id}>
                        {cell.value?.content ?? cell.value}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </DataTable>
      <TablePagination
        currentPageSize={size}
        onPageChange={onPageChange}
        totalItems={count}
      />
    </Card>
  );
};
export default EmployeeListPage;
