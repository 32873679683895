import React from 'react'
import { useParams } from 'react-router-dom'
import Card from '../../components/Card/Card'
import ContactForm from './ContactForm'

const CreateContactPage = () => {
  const params = useParams()

  return (
    <div>
      <Card title="Pessoa de Contacto">
        <ContactForm
          mode={'CREATE'}
          url={'/contacts/'}
          successMessage={'Cliente registado com sucesso'}
          onSuccessNavigateTo={'../update_client/' + params.id}
          clientid={params.id}
        />
      </Card>
    </div>
  )
}

export default CreateContactPage
