import React from "react";
import {
  Row,
  Column,
  FormGroup,
  TextArea,
  Checkbox,
  TextInput,
  NumberInput,
} from "carbon-components-react";

const BusinessIdeaForm = ({ disabled, register, errors }) => {
  return (
    <FormGroup
      legendText="SECÇÃO D. IDEIA DA CANDIDATURA"
      className="section-header"
    >
      <h6 className="section-subtitle">
        A ser preenchida por todos/as candidatos/as independentemente se tem ou
        não um negócio actualmente
      </h6>

      <fieldset className="bx--fieldset">
        <Row>
          <Column xsm={12} sm={12} md={12} lg={12} className="required">
            <TextArea
              id="businessIdea"
              labelText="D1. Descreva sumariamente a sua Ideia para a sua candidatura e as características inovadoras desta ideia"
              helperText="[Descreva de forma sumária a sua ideia, destacando as principais características inovadoras/diferenciadoras (científicas, tecnológicas, de mercado, do produto/serviço, processo ou modelo de negócio). Entende-se por inovação/diferenciação qualquer aspecto considerado como novo pelo eventual utilizador/mercado, capaz de garantir diferenciação face aos concorrentes. Descreva o que faría se recebesse financiamento da CPN.]"
              rows={10}
              enableCounter
              maxCount={4000}
              disabled={disabled}
              {...register("businessIdea")}
            />
            <p className="field-error">{errors.businessIdea?.message}</p>
          </Column>
        </Row>
      </fieldset>
      <fieldset className="bx--fieldset required">
        <legend className="bx--label">D2. Tipo de negócio para a ideia</legend>
        <Row>
          <Column sm={12} md={12} lg={12}>
            <p>Existem diferentes tipos de negócios nomeadamente: </p>
            <ol>
              <li>
                <strong>Fabricante/Produtor:</strong> são aqueles que usam
                matérias-primas para fazer novos produtos. Por exemplo usam a
                madeira para o fabrico de camas e cadeiras, ou a pele de animais
                (couro) para o fabrico de sapatos, etc.{" "}
              </li>
              <li>
                <strong>Prestador de Serviços:</strong> são aqueles que fazem
                algum trabalho para alguém e em troca são pagos uma remuneração.
                Por exemplo os pedreiros, cabeleiros, etc.{" "}
              </li>
              <li>
                <strong>Retalhistas/Vendedores a Retalho:</strong> são os que
                compram em grandes quantidades e revendem em pequenas
                quantidades para fazer lucro. Por exemplo os comerciantes que
                compram sabão, óleo em caixa e revendem em pequenas quantidades.{" "}
              </li>
              <li>
                <strong>Grossistas/Vendedores a Grosso:</strong> são aqueles
                comerciantes que vendem por grosso, i.e., em grandes
                quantidades, por exemplo para os retalhistas/vendedores a
                retalho.
              </li>
            </ol>
          </Column>
        </Row>
        <Row>
          <Column sm={12} md={3} lg={3}>
            <Checkbox
              labelText="Fabricante/Produtor "
              id="isManufacturerOrProducer"
              disabled={disabled}
              {...register("isManufacturerOrProducer")}
            />
          </Column>
          <Column sm={12} md={3} lg={3}>
            <Checkbox
              labelText="Prestador de serviço "
              id="isServiceProvider"
              disabled={disabled}
              {...register("isServiceProvider")}
            />
          </Column>
          <Column sm={12} md={3} lg={3}>
            <Checkbox
              labelText="Retalhista "
              id="isRetailer"
              disabled={disabled}
              {...register("isRetailer")}
            />
          </Column>
          <Column sm={12} md={3} lg={3}>
            <Checkbox
              labelText="Grossista"
              id="isWholesaler"
              disabled={disabled}
              {...register("isWholesaler")}
            />
          </Column>
          <p className="field-error">{errors.businessType?.message}</p>
        </Row>
      </fieldset>
      <fieldset className="bx--fieldset">
        <Row>
          <Column sm={12} md={12} lg={12} className="required">
            <TextArea
              id="ideaProductOrService"
              labelText="D3. Produtos ou serviços para a ideia"
              helperText="[Descreva de forma sumária o produto/serviço que pretende produzir/prestar, enunciando as suas principais características e o grau de desenvolvimento.]"
              rows={8}
              enableCounter
              maxCount={1250}
              disabled={disabled}
              {...register("ideaProductOrService")}
            />
            <p className="field-error">
              {errors.ideaProductOrService?.message}
            </p>
          </Column>
        </Row>
      </fieldset>
      <fieldset className="bx--fieldset">
        <Row>
          <Column sm={12} md={12} lg={12} className="required">
            <TextArea
              id="targetCustomerProfile"
              labelText="D4. Perfil de Clientes-alvo"
              helperText="[Clientes-alvo são aqueles que a empresa pretende servir e que poderão proporcionar os maiores ganhos. Caracterize-os, justificando com base em critérios comportamentais, demográficos, localização geográfica, benefícios procurados no produto e de necessidade a satisfazer ou padrões de utilização.]"
              rows={8}
              enableCounter
              maxCount={1250}
              disabled={disabled}
              {...register("targetCustomerProfile")}
            />
            <p className="field-error">
              {errors.targetCustomerProfile?.message}
            </p>
          </Column>
        </Row>
      </fieldset>
      <fieldset className="bx--fieldset">
        <Row>
          <Column sm={12} md={12} lg={12} className="required">
            <TextArea
              id="workers"
              labelText="D5. Trabalhadores"
              helperText="[Número de colaboradores que pretende contratar, sua distribuição por sexo, qualificações, competências necessárias, e regime de contratação.]"
              rows={8}
              enableCounter
              maxCount={1250}
              disabled={disabled}
              {...register("workers")}
            />
            <p className="field-error">{errors.workers?.message}</p>
          </Column>
        </Row>
        <Row className="mt-1">
          <Column sm={12} md={3} lg={3} className="required">
            <TextInput
              id="numberOfExpectedWorkers"
              labelText="Número de trabalhadores esperados em 2024"
              disabled={disabled}
              {...register("numberOfExpectedWorkers")}
            />
            <p className="field-error">
              {errors.numberOfExpectedWorkers?.message}
            </p>
          </Column>
          <Column sm={12} md={3} lg={3} className="required">
            <TextInput
              id="numberOfExpectedFemaleWorkers"
              labelText="Número de trabalhadores do sexo feminino esperados em 2024"
              disabled={disabled}
              {...register("numberOfExpectedFemaleWorkers")}
            />
            <p className="field-error">
              {errors.numberOfExpectedFemaleWorkers?.message}
            </p>
          </Column>
          <Column sm={12} md={3} lg={3} className="required">
            <TextInput
              id="numberOfExpectedMaleWorkers"
              labelText="Número de trabalhadores do sexo masculino esperados em 2024"
              disabled={disabled}
              {...register("numberOfExpectedMaleWorkers")}
            />
            <p className="field-error">
              {errors.numberOfExpectedMaleWorkers?.message}
            </p>
          </Column>
          <Column sm={12} md={3} lg={3} className="required">
            <TextInput
              id="numberOfExpectedWorkersAged18To35"
              labelText="Número de trabalhadores dos 18 aos 35 anos esperados em 2024"
              disabled={disabled}
              {...register("numberOfExpectedWorkersAged18To35")}
            />
            <p className="field-error">
              {errors.numberOfExpectedWorkersAged18To35?.message}
            </p>
          </Column>
        </Row>
      </fieldset>
      <Row>
        <Column sm={12} md={12} lg={12} className="required">
          <TextArea
            id="howWillEnsureEqualEmploymentOpportunity"
            labelText="D6. Como vai garantir a igualdade de oportunidade de emprego para homens e mulheres dos 18 aos 35 anos?"
            rows={8}
            enableCounter
            maxCount={1250}
            disabled={disabled}
            {...register("howWillEnsureEqualEmploymentOpportunity")}
          />
          <p className="field-error">
            {errors.howWillEnsureEqualEmploymentOpportunity?.message}
          </p>
        </Column>
      </Row>
    </FormGroup>
  );
};

BusinessIdeaForm.defaultProps = {
  disabled: false,
};

export default BusinessIdeaForm;
