import {
  Button,
  Form,
  TextInput,
  Row,
  Column,
  ButtonSet,
  Dropdown,
  Loading,
} from "carbon-components-react";
import React from "react";
import { useEffect, useState, useContext } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import http from "../../http-common";
import { Add16, Edit24, SaveModel24 } from "@carbon/icons-react";
import ProvinceCombo from "../../components/Combos/ProvinceCombo";
import { clientSchema } from "../../services/SchemaService";
import { yupResolver } from "@hookform/resolvers/yup";
import Card from "../../components/Card/Card";

const ClientForm = ({ disabled, mode, url, clientId }) => {
  const [formMode] = useState(mode);
  const [showSuccess, setShowSuccess] = useState(false);
  const [client, setClient] = useState({
    id: "",
    abreviation: "",
    address: "",
    name: "",
    nuit: "",
    typeOfActivity: "",
    typeOfClient: "",
    //  company: "",
    provinceId: "",
    phoneNumber: "",
    faxNumber: "",
    email: "",
    website: "",
  });
  const [type, setType] = useState([]);
  const [clientype, setClientType] = useState([]);
  const [company, setCompany] = useState([]);
  const [location, setLocation] = useState([]);

  const [showLoading, setShowLoading] = useState(false);
  const [isBtnDisabled, setisBtnDisabled] = useState(false);

  const getSchema = () => {
    switch (mode) {
      case "UPDATE":
        return clientSchema;
      case "CREATE":
        return clientSchema;
      default:
        return clientSchema;
    }
  };

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    watch,
    control,
    formState: { errors },
  } = useForm({ resolver: yupResolver(getSchema()), mode: "onSubmit" });

  const navigate = useNavigate();
  const params = useParams();

  const delay = (ms) => new Promise((res) => setTimeout(res, ms));

  async function onSubmit(data) {
    try {
      setisBtnDisabled(true);
      var result = null;

      if (formMode === "UPDATE") {
        result = await http.post(url, data);
        toast.success("Operação realizada com sucesso!");
        await delay(2000); // breaking

        navigate("../clientes");
      } else {
        result = await http.post(url, data);

        console.log("create url", url);
        console.log("create data", result);

        if (result?.data.msg !== undefined) {
          toast.error("Operação não realizada!: \n " + result?.data.msg);
          setisBtnDisabled(false);
        } else {
          toast.success("Operação realizada com sucesso!");
          await delay(2000); // breaking

          navigate("../clientes");
        }
      }
    } catch (error) {
      setisBtnDisabled(false);
      toast.error(error.response?.data.message);
    }
  }
  //fetch activity
  useEffect(() => {
    (async () => {
      let response = await fetchActivities();
      if (response.success) {
        setType(response.data);
      }
    })();
  }, []);

  const fetchActivities = async () => {
    try {
      const response = await http.get("/activitytypes");
      let items = response.data.map((e) => {
        return { id: e.id, text: e.description, children: e.children };
      });
      return { success: true, data: items };
    } catch (error) {
      return { success: false };
    }
  };

  //fetch Clients

  useEffect(() => {
    (async () => {
      let response = await fetchClients();
      if (response.success) {
        setClientType(response.data);
      }
    })();
  }, []);

  const fetchClients = async () => {
    try {
      const response = await http.get("/clientstypes");
      let items = response.data.map((e) => {
        return { id: e.id, text: e.description, children: e.children };
      });
      return { success: true, data: items };
    } catch (error) {
      return { success: false };
    }
  };

  // //fetch Company
  // useEffect(() => {
  //   (async () => {
  //     let response = await fetchCompany();
  //     if (response.success) {
  //       setCompany(response.data);
  //     }
  //   })();
  // }, []);

  // const fetchCompany = async () => {
  //   try {
  //     const response = await http.get("/company");
  //     let items = response.data.map((e) => {
  //       return { id: e.id, text: e.description, children: e.children };
  //     });
  //     return { success: true, data: items };
  //   } catch (error) {
  //     return { success: false };
  //   }
  // };

  useEffect(() => {
    if (formMode === "UPDATE") {
      const fetchData = async () => {
        try {
          const result = await http.get(`/clients/${clientId}`);
          const fields = [
            "id",
            "abreviation",
            "address",
            "name",
            "nuit",
            "typeOfActivity",
            "typeOfClient",
            "provinceId",
            "phoneNumber",
            "faxNumber",
            "email",
            "website",
            // "company",
          ];
          fields.forEach((field) => setValue(field, client[field]));
          setClient(result.data);
        } catch (error) {
          toast.error(error.response?.data.message);
        }
      };

      fetchData();
    }
    setShowSuccess(false);
  }, [formMode]);

  useEffect(() => {
    reset(client);
  }, [client]);

  function onTypeChange(e) {
    client.typeOfActivity = e.selectedItem.text;
    setValue("typeOfActivity", e.selectedItem.text);
  }

  function onClientTypeChange(e) {
    client.typeOfClient = e.selectedItem.text;
    setValue("typeOfClient", e.selectedItem.text);
  }
  // function onCompanyChange(e) {
  //   client.company = e.selectedItem.text;
  //   setValue("company", e.selectedItem.text);
  // }

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Column sm={6} md={6} lg={6} className="required">
            <TextInput
              id="name"
              labelText="Nome"
              maxLength={64}
              {...register("name")}
            />
            <p className="field-error">{errors.name?.message}</p>
          </Column>
          <Column sm={6} md={6} lg={6} className="required">
            <TextInput
              id="abreviation"
              labelText="Abreviatura"
              maxLength={50}
              {...register("abreviation")}
            />
            <p className="field-error">{errors.abreviation?.message}</p>
          </Column>
        </Row>

        <Row>
          {/* <Column sm={6} md={6} lg={6} className="required">
            {/* <Dropdown
              id="company"
              titleText="Empresa"
              label={formMode == "UPDATE" ? client.company : "Selecione"}
              items={company}
              onChange={onCompanyChange}
              name="company"
              itemToString={(item) => (item ? item.text : "")}
            />
            <p className="field-error">{errors.company?.message}</p>
          </Column> */}
          <Column sm={6} md={6} lg={6} className="required">
            <Dropdown
              id="typeOfClient"
              titleText="Tipo de Organização"
              label={formMode == "UPDATE" ? client.typeOfClient : "Selecione"}
              items={clientype}
              onChange={onClientTypeChange}
              name="typeOfClient"
              itemToString={(item) => (item ? item.text : "")}
            />
            <p className="field-error">{errors.typeOfClient?.message}</p>
          </Column>
          <Column sm={6} md={6} lg={6} className="required">
            <Dropdown
              id="type"
              titleText="Tipo de Actividade"
              label={formMode == "UPDATE" ? client.typeOfActivity : "Selecione"}
              items={type}
              onChange={onTypeChange}
              name="type"
              itemToString={(item) => (item ? item.text : "")}
            />
            <p className="field-error">{errors.typeOfActivity?.message}</p>
          </Column>
        </Row>
        <Row>
          <Column sm={6} md={6} lg={6}>
            <ProvinceCombo
              id="provinceId"
              name="provinceId"
              label="Província"
              disabled={disabled}
              control={control}
            />
          </Column>
          <Column sm={6} md={6} lg={6}>
            <TextInput
              id="address"
              labelText="Endereço"
              {...register("address")}
              maxLength={200}
            />
          </Column>
        </Row>
        <Row>
          <Column sm={6} md={6} lg={6}>
            <TextInput
              id="nuit"
              labelText="NUIT"
              {...register("nuit")}
              maxLength={9}
            />
          </Column>
          <Column sm={6} md={6} lg={6}>
            <TextInput
              id="phoneNumber"
              labelText="Número de Telefone"
              {...register("phoneNumber")}
              maxLength={50}
            />
          </Column>
        </Row>
        <Row>
          <Column sm={6} md={6} lg={6}>
            <TextInput
              id="faxNumber"
              labelText="Telefone alternativo ou fixo"
              {...register("faxNumber")}
              maxLength={50}
            />
          </Column>
          <Column sm={6} md={6} lg={6}>
            <TextInput
              id="website"
              labelText="Website"
              {...register("website")}
              maxLength={50}
            />
          </Column>
        </Row>
        <Row>
          <Column sm={6} md={6} lg={6}>
            <TextInput
              id="email"
              labelText="Email"
              {...register("email")}
              maxLength={50}
            />
          </Column>
        </Row>
        <Row className="mt-2">
          <Column sm={12} md={6} lg={6}>
            <ButtonSet>
              <Button
                disabled={isBtnDisabled}
                type="submit"
                renderIcon={SaveModel24}
                iconDescription="Save"
              >
                Salvar
                {showLoading == true ? (
                  <Loading
                    description="Active loading indicator"
                    withOverlay={false}
                    small
                  />
                ) : (
                  ""
                )}
              </Button>
              <Button onClick={() => navigate("../clientes")}>Voltar</Button>
            </ButtonSet>
          </Column>
        </Row>
      </Form>
    </>
  );
};

export default ClientForm;
