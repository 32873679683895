import React from 'react'
import Card from '../../components/Card/Card'
import ProviderForm from './ProviderForm'

const CreateProviderPage = () => {
  return (
    <Card title="Registar Fornecedor">
      <ProviderForm
        mode={'CREATE'}
        url={'/provider'}
        successMessage={'Fornecedor registado com sucesso'}
        onSuccessNavigateTo={'../fornecedor'}
      />
    </Card>
  )
}

export default CreateProviderPage
