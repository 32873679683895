import {
  Button,
  DataTable,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
  OverflowMenuItem,
  OverflowMenu,
} from "carbon-components-react";
import { useEffect, useState, useContext } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, Link } from "react-router-dom";
import http from "../../http-common";
import { Add16, Edit24, Search32 } from "@carbon/icons-react";
import Card from "../../components/Card/Card";
import TablePagination from "../../components/Pagination/Pagination";

const tableHeaders = [
  {
    header: "Tipo de servico",
    key: "type",
  },
  {
    header: "Descrição",
    key: "description",
  },
  {
    header: "Inicio",
    key: "startDate",
  },
  {
    header: "Fim",
    key: "endDate",
  },
];

const ClientActivityPage = ({ clientId }) => {
  const [service, setservice] = useState({
    description: "",
  });

  const [services, setServices] = useState([]);
  const [count, setCount] = useState(0);
  const [size, setSize] = useState(20);
  const [isLoading, setIsLoading] = useState(true);
  const [type, setType] = useState([]);
  const [status, setStatus] = useState([]);

  const {
    formState: { errors },
  } = useForm();

  const navigate = useNavigate();

  const LinkList = ({ url, url2 }) => (
    <ul style={{ display: "flex" }}>
      <li>
        <Link to={url} title="Editar">
          <Edit24 />
        </Link>
      </li>
      &nbsp;&nbsp;&nbsp;
    </ul>
  );

  //Service type
  const getAllServices = async () => {
    const response = await http.get("/servicetypes");
    setType(response.data);
    setIsLoading(false);
  };

  //Status
  const getStatus = async () => {
    const response = await http.get("/status");
    setStatus(response.data);
    setIsLoading(false);
  };
  const featchData = async () => {
    try {
      const response = await http.get(`/services/client/${clientId}`);
 

      if (response.data != null) {
        getserviceDate(response);
      }
    } catch (error) {}
  };

  const getserviceDate = (response) => {
    try {
      const data = response?.data?.map((service) => ({
        id: service.id,
        type: service.type,
        description: service.description,
        startDate: service.startDate,
        endDate: service.endDate,
        status: service.status,
        prevStartDate: service.startDate,
        prevendDate: service.prevendDate,

        //actions: <LinkList url={'../update-service/' + service.id} />,
      }));
      setServices(data);
      setCount(response.data.totalElements);
    } catch (error) {
      console.log("lientActivity.getserviceDate.Erro", error);
    }
  };

  const onPageChange = async ({ page, pageSize }) => {
    try {
      var pag = page - 1;
      const response = await http.get(`/services/client/${clientId}`);
      getserviceDate(response);
    } catch (error) {}
  };

  useEffect(() => {
    // getAllServices()
    // getStatus()
    featchData();
  }, []);
  const tableActions = (service) => {
    return (
      <OverflowMenu flipped>
        <OverflowMenuItem
          key="optionOne"
          itemText="Edit"
          onClick={() => navigate("../update-service/" + service.id)}
        />
      </OverflowMenu>
    );
  };

  return (
    <Card title="Consultar todas actividades">
      <DataTable
        rows={services}
        headers={tableHeaders}
        isSortable
        useZebraStyles
      >
        {({ rows, headers, getHeaderProps, getTableProps }) => (
          <TableContainer>
            <Table {...getTableProps()}>
              <TableHead>
                <TableRow>
                  {headers.map((header) => (
                    <TableHeader {...getHeaderProps({ header })}>
                      {header.header}
                    </TableHeader>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow key={row.id}>
                    {row.cells.map((cell) => (
                      <TableCell key={cell.id}>
                        {cell.value?.content ?? cell.value}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </DataTable>

      <TablePagination
        currentPageSize={size}
        onPageChange={onPageChange}
        totalItems={count}
      />
    </Card>
  );
};

export default ClientActivityPage;
