import {
  Button,
  Form,
  TextInput,
  PasswordInput,
} from "carbon-components-react";
import {
  Credentials32,
  FaceDissatisfied32,
  Help32,
  ArrowRight24,
} from "@carbon/icons-react";
import React, { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import http from "../../http-common";
import { toast } from "react-toastify";
import { AuthContext } from "../../contexts/AuthContext";
import CancelButton from "../../components/Buttons/CancelButton";
import { RuleCancelled24 } from "@carbon/icons-react";
import SubmitButton from "../../components/Buttons/SubmitButton";

const requiredMessage = "Campo obrigatório";

const loginSchema = yup.object({
  username: yup.string().required(requiredMessage),
  password: yup.string().required(requiredMessage),
});

const HomePage = () => {
  const { authenticated, login } = useContext(AuthContext);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(loginSchema) });
  const navigate = useNavigate();

  const onSubmit = async (data) => {
    await login(data.username, data.password);
  };

  return (
    <div className="bx--row">
      <div className="bx--col-lg-4">
        <div className="card">
          <div className="card-header">
            <h3 className="card-title">Login</h3>
          </div>
          <div className="card-body">
            <Form onSubmit={handleSubmit(onSubmit)}>
              <TextInput
                {...register("username")}
                id="username"
                labelText="Número de Telefone"
                placeholder=""
                maxLength={9}
              />
              <p className="field-error">{errors.username?.message}</p>
              <PasswordInput
                id="password"
                {...register("password")}
                labelText="Senha"
                placeholder=""
              />
              <p className="field-error">{errors.password?.message}</p>
              <Button
                type="submit"
                renderIcon={ArrowRight24}
                className="mt-2 btn-fluid"
              >
                Login
              </Button> 
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
