import React, { useState, useEffect } from "react";
import Card from "../../components/Card/Card";
import { Form } from "carbon-components-react";
import {
  Row,
  Column,
  TextInput,
  Button,
  DataTable,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
  TableToolbar,
  TableToolbarContent,
  OverflowMenuItem,
  OverflowMenu,
} from "carbon-components-react";
import { Link, useNavigate } from "react-router-dom";
import {
  Add16,
  Search32,
  DataView24,
  Edit24,
  TrashCan24,
} from "@carbon/icons-react";
import CustomTable from "../../components/custom-table/custom-table";
import http from "../../http-common";
import TablePagination from "../../components/Pagination/Pagination";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import useGet from "../../hooks/useGet";

const tableHeaders = [
  {
    header: "Nome",
    key: "name",
  },
  {
    header: "Abreviatura",
    key: "abreviation",
  },
  {
    header: "Província",
    key: "province",
  },
  {
    header: "Endereço",
    key: "address",
  },
  {
    header: "Tipo de actividade",
    key: "typeOfActivity",
  },
  {
    header: "Tipo de Cliente",
    key: "typeOfClient",
  },
  {
    header: "NUIT",
    key: "nuit",
  },
  {
    header: "Operações",
    key: "actions",
  },
];

const LinkList = ({ url, url2 }) => (
  <ul style={{ display: "flex" }}>
    <li>
      <Link to={url} title="Editar">
        <Edit24 />
      </Link>
    </li>
    &nbsp;&nbsp;&nbsp;
  </ul>
);

const ClientPage = () => {
  const navigate = useNavigate();

  const [client, setClient] = useState([]);
  const [company, setCompany] = useState([]);
  const [count, setCount] = useState(0);
  const [size, setSize] = useState(20);
  const [type, setType] = useState([]);
  const [clientype, setClientType] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const [locations, setLocations] = useState([]);
  const [activitis, setActivitis] = useState([]);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  //  Location

  const getAllLocations = async () => {
    const response = await http.get("/locations");
    setLocations(response.data);
    setIsLoading(false);
  };

  //Activity

  const getAllActivities = async () => {
    const response = await http.get("/activitytypes");
    setActivitis(response.data);
    setIsLoading(false);
  };

  //Client

  const getAllClients = async () => {
    const response = await http.get("/clientstypes");
    setClient(response.data);
    setIsLoading(false);
  };

  //Client

  const getAllCompany = async () => {
    const response = await http.get("/company");
    setCompany(response.data);
    setIsLoading(false);
  };

  const featchData = async () => {
    try {
      const response = await http.get("/clients/search/filter?size=" + size);
      getClientData(response);
    } catch (error) {}
  };
  const getClientData = (response) => {
    const data = response.data.content.map((client) => ({
      id: client.id,
      code: client.code,
      name: client.name,
      abreviation: client.abreviation,
      province: client.province.description,
      address: client.address,
      // company: client.company,
      typeOfActivity: client.typeOfActivity,
      typeOfClient: client.typeOfClient,
      nuit: client.nuit,

      // actions: tableActions(client),
      actions: <LinkList url={"../update_client/" + client.id} />,
    }));
    setClient(data);
    setCount(response.data.totalElements);
  };

  const onPageChange = async ({ page, pageSize }) => {
    try {
      var pag = page - 1;
      const response = await http.get(
        "/clients/search/filter?page=" + pag + "&size=" + pageSize
      );
      getClientData(response);
    } catch (error) {}
  };

  const onSearch = async (data) => {
    try {
      const response = await http.get(
        `/clients/search/filter?name=${data.name}`
      );
      getClientData(response);
    } catch (error) {}
  };

  useEffect(() => {
    getAllLocations();
    getAllActivities();
    getAllClients();
    getAllCompany();
    featchData();
  }, []);

  // const tableActions = (client) => {
  //   return (
  //     <OverflowMenu flipped>
  //       <OverflowMenuItem
  //         key="optionOne"
  //         itemText="Edit"
  //         onClick={() => navigate('../update_client/' + client.id)}
  //       />
  //     </OverflowMenu>
  //   )
  // }

  return (
    <Card title="Consultar Clientes">
      <div className="mb-2 form-buttons">
        <p></p>
        <Button
          kind="ghost"
          type="button"
          onClick={() => navigate("../registar-clientes")}
          renderIcon={Add16}
        >
          Registar Cliente
        </Button>
      </div>

      <DataTable rows={client} headers={tableHeaders} isSortable useZebraStyles>
        {({ rows, headers, getHeaderProps, getTableProps }) => (
          <TableContainer>
            <TableToolbar aria-label="data table toolbar">
              <TableToolbarContent>
                <Form
                  className="data-table-search-form"
                  onSubmit={handleSubmit(onSearch)}
                >
                  <Row>
                    <Column sm={6} md={6} lg={5}>
                      <TextInput
                        id="name"
                        labelText=""
                        placeholder="Name"
                        {...register("name")}
                      />
                    </Column>
                  </Row>
                  <Button type="submit" renderIcon={Search32}>
                    Search
                  </Button>
                </Form>
              </TableToolbarContent>
            </TableToolbar>
            <Table {...getTableProps()}>
              <TableHead>
                <TableRow>
                  {headers.map((header) => (
                    <TableHeader {...getHeaderProps({ header })}>
                      {header.header}
                    </TableHeader>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow key={row.id}>
                    {row.cells.map((cell) => (
                      <TableCell key={cell.id}>
                        {cell.value?.content ?? cell.value}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </DataTable>
      <TablePagination
        currentPageSize={size}
        onPageChange={onPageChange}
        totalItems={count}
      />
    </Card>
  );
};
export default ClientPage;
