import React, { useEffect } from 'react'
import {
  Button,
  Form,
  TextInput,
  Row,
  Column,
  Select,
  SelectItem,
  PasswordInput,
  ButtonSet,
} from 'carbon-components-react'
import ArrowRight24 from '@carbon/icons-react/es/arrow--right/24'
import { toast } from 'react-toastify'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Link, useNavigate, useParams } from 'react-router-dom'
import {
  userSchema,
  roleRules,
  passwordRules,
} from '../../services/SchemaService'
import useGet from '../../hooks/useGet'
import http from '../../http-common'
import * as yup from 'yup'
import { useState } from 'react'
import { ArrowLeft24, SaveModel24 } from '@carbon/icons-react'
import ProvinceCombo from '../../components/Combos/ProvinceCombo'

const UserForm = ({
  disabled,
  mode,
  url,
  successMessage,
  onSuccessNavigateTo,
  userId,
}) => {
  const [opMode] = useState(mode)
  const { data: roles } = useGet('/user-roles')
  const [showProfiles] = useState(mode !== 'SIGNUP')
  const [showPassword] = useState(mode !== 'UPDATE')
  const [showFields] = useState(mode === 'UPDATE')
  const [user, setUser] = useState({})
  const [showSuccess, setShowSuccess] = useState(false)
  const [data, setData] = useState(null)

  const { username } = useParams()

  const getSchema = () => {
    switch (mode) {
      case 'CREATE':
        return userSchema.concat(passwordRules).concat(roleRules)
      case 'UPDATE':
        return userSchema
      case 'SIGNUP':
        return userSchema
      default:
        return userSchema
    }
  }

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    control,
    formState: { errors },
  } = useForm({ resolver: yupResolver(getSchema()), mode: 'onSubmit' })

  const navigate = useNavigate()

  const onSubmit = async (data) => {
    try {
      opMode === 'UPDATE'
        ? await http.post(url, data)
        : await http.post(url, data)
      toast.success(successMessage)

      if (opMode === 'SIGNUP') {
        reset({})
        setData(data)
        setShowSuccess(true)
      } else {
        navigate(onSuccessNavigateTo, { replace: true })
      }
    } catch (error) {
      toast.error(error.response?.data.message)
    }
  }
  const roleNameLabel = (role) => {
    switch (role) {
      case 'ROLE_ADMIN':
        return 'Administrador'
      case 'ROLE_HUMAN_RESOURCES':
        return 'Recursos Humanos'
      case 'ROLE_PROJECT_MANAGER':
        return 'Gestor de Projectos'
      case 'ROLE_ADMINISTRATION_FINANCE':
        return 'Administração e Finanças'
      case 'ROLE_TECHNICIAN':
        return 'Técnico'
      case 'ROLE_COMMERCIAL':
        return 'Comercial'
      default:
        return ''
    }
  }
  useEffect(() => {
    if (opMode === 'UPDATE') {
      const fetchData = async () => {
        try {
          const result = await http.get(`/users/${userId}`)
          const fields = [
            'id',
            'firstname',
            'lastname',
            'username',
            'email',
            'role',
          ]
          setUser(result.data)
          fields.forEach((field) => setValue(field, user[field]))
        } catch (error) {
          toast.error(error.response?.data.message)
        }
      }

      fetchData()
    }
    setShowSuccess(false)
  }, [opMode])

  useEffect(() => {
    reset(user)
  }, [user])

  return (
    <>
      {showSuccess ? (
        <div className="emprega-green">
          <h5>Obrigado por te registares</h5>
        </div>
      ) : (
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Column sm={6} md={6} lg={6} className="required">
              <TextInput
                id="firstname"
                labelText="Nome"
                maxLength={64}
                {...register('firstname')}
              />
              <p className="field-error">{errors.firstname?.message}</p>
            </Column>
            <Column sm={6} md={6} lg={6} className="required">
              <TextInput
                id="lastname"
                labelText="Apelido"
                maxLength={64}
                {...register('lastname')}
              />
              <p className="field-error">{errors.lastname?.message}</p>
            </Column>
          </Row>

          <Row>
            <Column sm={12} md={6} lg={6} className="required">
              <TextInput
                id="username"
                labelText="Usuário"
                {...register('username')}
                maxLength={9}
              />
              <p className="field-error">{errors.username?.message}</p>
            </Column>
            <Column sm={12} md={6} lg={6} className="required">
              <TextInput id="email" labelText="Email" {...register('email')} />
              <p className="field-error">{errors.email?.message}</p>
            </Column>
          </Row>
          {!showFields && (
            <Row>
              <Column sm={2} md={6} lg={6} className="required">
                <Select
                  id="role"
                  labelText="Perfil"
                  {...register('role')}
                // onChange={handleChangeRole}
                >
                  <SelectItem text="" value="" />
                  {roles.map((p) => (
                    <SelectItem text={roleNameLabel(p)} value={p} key={p} />
                  ))}
                </Select>

                <p className="field-error">{errors.role?.message}</p>
              </Column>
            </Row>
          )}

          {showPassword ? (
            <Row>
              <Column sm={12} md={6} lg={6} className="required">
                <PasswordInput
                  id="password"
                  labelText="Senha"
                  maxLength={16}
                  {...register('password')}
                />
                <p className="field-error">{errors.password?.message}</p>
              </Column>
              <Column sm={12} md={6} lg={6} className="required">
                <PasswordInput
                  id="passwordConfirm"
                  labelText="Confirmação da Senha"
                  maxLength={16}
                  {...register('passwordConfirm')}
                />
                <p className="field-error">{errors.passwordConfirm?.message}</p>
              </Column>
            </Row>
          ) : (
            <Row>
              <Column sm={12} md={6} lg={6} className="required">
                <PasswordInput
                  id="textPassword"
                  labelText="Senha"
                  maxLength={16}
                  {...register('textPassword')}
                />
                <p className="field-error">{errors.textPassword?.message}</p>
              </Column>
            </Row>
          )}
          <Row className="mt-2">
            <Column sm={12} md={12} lg={12} className="form-buttons">
              <ButtonSet>
                <Button
                  type="submit"
                  renderIcon={SaveModel24}
                  iconDescription="Save"
                >
                  Salvar
                </Button>

                <Button
                  onClick={() => navigate("../utilizadores/")}
                  renderIcon={ArrowLeft24}
                  iconDescription="Save"
                  style={{ marginLeft: "17px" }}
                >
                  voltar
                </Button>

              </ButtonSet>
            </Column>
          </Row>
        </Form>
      )}
    </>
  )
}

export default UserForm
