import React from 'react'
import { Select, SelectItem } from 'carbon-components-react'
import http from '../../http-common'
import { toast } from 'react-toastify'
import useGet from '../../hooks/useGet'
import { Controller } from 'react-hook-form'

const ProvinceCombo = ({
  id,
  name,
  label,
  disabled,
  control,
  setDistricts,
}) => {
  const { data: provinces } = useGet('/locations')

  const handleChange = async (e) => {
    // if (e.target.value) {
    //   try {
    //     const response = await http.get(
    //       "/utils/provinces/" + e.target.value + "/districts"
    //     );
    //     setDistricts(response.data);
    //   } catch (error) {
    //     toast.error(error?.response?.data.message);
    //   }
    // } else {
    //   setDistricts([]);
    // }
  }

  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <Select
          {...field}
          id={id}
          labelText={label}
          disabled={disabled}
          onChange={(e) => {
            field.onChange(e.target.value)
            handleChange(e)
          }}
        >
          <SelectItem text="" value="" />
          {provinces.map((p) => (
            <SelectItem text={p.description} value={p.id} key={p.id} />
          ))}
        </Select>
      )}
    />
  )
}

ProvinceCombo.defaultProps = {
  disabled: false,
}
export default ProvinceCombo
