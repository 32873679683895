// This should come from the database

const menu = (role) => {
  const menuItems = [
    {
      id: 1,
      label: "Minha conta",
      icon: "UserMultiple16",
      roles: ["ROLE_SELLER"],
      items: [
        {
          id: 1.1,
          label: "Registos",
          link: "/admin/detalhes-do-fornecedor",
          roles: ["ROLE_SELLER"],
        },
      ],
    },
    {
      id: 2,
      label: "Utilizadores",
      icon: "UserMultiple16",
      roles: ["ROLE_ADMIN"],
      items: [
        {
          id: 2.1,
          label: "Registar",
          link: "/admin/registar-utilizador",
          roles: ["ROLE_ADMIN"],
        },
        {
          id: 2.2,
          label: "Consultar",
          link: "/admin/utilizadores",
          roles: ["ROLE_ADMIN"],
        },
      ],
    },
    {
      id: 3,
      label: "Funcionários",
      icon: "UserMultiple16",
      roles: ["ROLE_ADMIN", "ROLE_HUMAN_RESOURCES"],
      items: [
        /*    {
          id: 3.1,
          label: "Registar",
          link: "registar-funcionario",
          roles: ["ROLE_ADMIN", "ROLE_HUMAN_RESOURCES"],
        },
      {
          id: 3.2,
          label: 'Consultar dados gerias',
          link: 'funcionarios2',
          roles: ['ROLE_ADMIN', 'ROLE_HUMAN_RESOURCES'],
        }, */
        {
          id: 3.3,
          label: "Consultar",
          link: "funcionarios",
          roles: ["ROLE_ADMIN", "ROLE_HUMAN_RESOURCES"],
        },
        {
          id: 3.4,
          label: "Gestao de ferias",
          link: "consultar-ferias",
          roles: ["ROLE_ADMIN"],
        },
      ],
    },
    {
      id: 4,
      label: "Clientes",
      icon: "UserMultiple16",
      roles: ["ROLE_ADMIN", "ROLE_PROJECT_MANAGER", "ROLE_TECHNICIAN"],
      items: [
       /*  {
          id: 4.1,
          label: "Registar",
          link: "registar-clientes",
          roles: ["ROLE_ADMIN", "ROLE_PROJECT_MANAGER", "ROLE_TECHNICIAN"],
        }, */
        {
          id: 4.2,
          label: "Consultar",
          link: "clientes",
          roles: ["ROLE_ADMIN", "ROLE_PROJECT_MANAGER", "ROLE_TECHNICIAN"],
        },
      ],
    },
    {
      id: 5,
      label: "Projectos",
      icon: "UserMultiple16",
      roles: ["ROLE_ADMIN", "ROLE_PROJECT_MANAGER"],
      items: [
        {
          id: 5.1,
          label: "Consultar",
          link: "projectos",
          roles: ["ROLE_ADMIN", "ROLE_PROJECT_MANAGER"],
        },
      ],
    },
    {
      id: 6,
      label: "Actividade",
      icon: "UserMultiple16",
      roles: ["ROLE_ADMIN", "ROLE_TECHNICIAN"],
      items: [
        {
          id: 6.1,
          label: "Consultar",
          link: "servicos",
          roles: ["ROLE_ADMIN", "ROLE_TECHNICIAN"],
        },
      ],
    },
    {
      id: 7,
      label: "Facturação",
      icon: "UserMultiple16",
      roles: ["ROLE_ADMIN", "ROLE_COMMERCIAL"],
      items: [
        /*         {
          id: 7.1,
          label: 'Registar',
          link: 'registar-contractos',
          roles: ['ROLE_ADMIN', 'ROLE_ACCOUNTANT', 'ROLE_MA'],
        }, */
        {
          id: 7.2,
          label: "Consultar",
          link: "contratos",
          roles: ["ROLE_ADMIN", "ROLE_COMMERCIAL"],
        },
      ],
    },
    {
      id: 8,
      label: "Gestão de Licensas",
      icon: "UserMultiple16",
      roles: ["ROLE_ADMIN"],
      items: [
        {
          id: 8.1,
          label: "Consultar Fornecedor",
          link: "fornecedor",
          roles: ["ROLE_ADMIN"],
        },
        {
          id: 8.2,
          label: "Consultar Licensas",
          link: "licensas",
          roles: ["ROLE_ADMIN"],
        },
      ],
    },

    {
      id: 9,
      label: "Administração",
      icon: "UserMultiple16",
      roles: ["ROLE_ADMIN", "ROLE_COMMERCIAL"],
      items: [
        {
          id: 9.1,
          label: "Configuraçâo",
          link: "configuration",
          roles: ["ROLE_ADMIN", "ROLE_COMMERCIAL"],
        },
      ],
    },

    {
      id: 10,
      label: "Relatórios",
      icon: "UserMultiple16",
      roles: ["ROLE_ADMIN", "ROLE_COMMERCIAL"],
      items: [
        {
          id: 10.1,
          label: "Resumos",
          link: "resume",
          roles: ["ROLE_ADMIN", "ROLE_COMMERCIAL"],
        },
        /*  {
          id: 10.2,
          label: 'Listagems',
          link: 'listing',
          roles: ['ROLE_ADMIN', 'ROLE_COMMERCIAL'],
        }, */
      ],
    },
  ];

  const userMenu = menuItems.filter((m) => m.roles.includes(role));
  return userMenu;
};

export default menu;
