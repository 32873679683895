import React from "react";

import { useParams } from "react-router-dom";
import Card from "../../components/Card/Card";
import ManagementForm from "./ManagementForm";

const UpdateManagementPage = () => {
  const params = useParams();
  return (
    <Card title="Actualizar plano de Ferias">
      <ManagementForm
        mode={"UPDATE"}
        url={"/absencemanagement/" + params.id}
        successMessage={"Plano Actualizado com sucesso"}
        onSuccessNavigateTo={"../absencemanagement"}
        managementId={params.id}
      />
    </Card>
  );
};

export default UpdateManagementPage;
