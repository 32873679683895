import React, { useState, useEffect } from "react";
import Card from "../../components/Card/Card";
import { Form } from "carbon-components-react";
import {
  Row,
  Column,
  TextInput,
  Button,
  DataTable,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
  TableToolbar,
  TableToolbarContent,
  OverflowMenuItem,
  OverflowMenu,
} from "carbon-components-react";
import { Link, useNavigate } from "react-router-dom";
import {
  Add16,
  Search32,
  DataView24,
  Edit24,
  TrashCan24,
} from "@carbon/icons-react";
import CustomTable from "../../components/custom-table/custom-table";
import http from "../../http-common";
import TablePagination from "../../components/Pagination/Pagination";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import useGet from "../../hooks/useGet";

const tableHeaders = [
  {
    header: "Nome",
    key: "name",
  },
  // {
  //   header: "Responsavel pelo projecto",
  //   key: "employee",
  // },
  {
    header: "Tipo de Projecto",
    key: "typeOfProject",
  },

  {
    header: "Meta",
    key: "goal",
  },
  {
    header: "Data de início",
    key: "startDate",
  },
  {
    header: "Data final",
    key: "endDate",
  },
  {
    header: "Operações",
    key: "actions",
  },
];

const LinkList = ({ url, url2 }) => (
  <ul style={{ display: "flex" }}>
    <li>
      <Link to={url} title="Editar">
        <Edit24 />
      </Link>
    </li>
    &nbsp;&nbsp;&nbsp;
  </ul>
);

const ProjectClientPage = ({ clientId }) => {
  const navigate = useNavigate();

  const [project, setProject] = useState([]);
  const [count, setCount] = useState(0);
  const [size, setSize] = useState(20);
  //const [clientId] = useState()

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const featchData = async () => {
    try {
      const response = await http.get(`/projects/client/${clientId}`);
      getProjectData(response);
    } catch (error) {}
  };

  const getProjectData = (response) => {
    try {
      const data = response?.data?.map((project) => ({
        id: project.id,
        name: project.name,
        termOfContract: project.termOfContract,
        typeOfProject: project.typeOfProject,
        goal: project.goal,
        startDate: project.startDate,
        endDate: project.endDate,
        client_id: project.client_id,
        //employee: project.employee.firstName + " " + project.employee.surName,
        actions: <LinkList url={"../actualizar-projecto/" + project.id} />,
      }));

      setProject(data);
      setCount(response.data.totalElements);
    } catch (error) {}
  };

  const onPageChange = async ({ page, pageSize }) => {
    try {
      var pag = page - 1;
      const response = await http.get(`/projects/client/${clientId}`);
      getProjectData(response);
    } catch (error) {}
  };
  useEffect(() => {
    featchData();
  }, []);

  const tableActions = (project) => {
    return (
      <OverflowMenu flipped>
        <OverflowMenuItem
          key="optionOne"
          itemText="Edit"
          onClick={() => navigate("../actualizar-projecto/" + project.id)}
        />
      </OverflowMenu>
    );
  };

  // change format for Project Value
  function formatMz(num) {
    var p = num.toFixed(2).split(".");
    return (
      p[0]
        .split("")
        .reverse()
        .reduce(function (acc, num, i, orig) {
          return num + (num != "-" && i && !(i % 3) ? "." : "") + acc;
        }, "") +
      "," +
      p[1]
    );
  }
  return (
    <Card title="Consultar Projectos">
      <div className="mb-2 form-buttons">
        <p></p>
        <Button
          kind="ghost"
          type="button"
          onClick={() => navigate("../registar-projecto/" + clientId)}
          renderIcon={Add16}
        >
          Registar Projecto
        </Button>
      </div>

      <DataTable
        rows={project}
        headers={tableHeaders}
        isSortable
        useZebraStyles
      >
        {({ rows, headers, getHeaderProps, getTableProps }) => (
          <TableContainer>
            <TableToolbar aria-label="data table toolbar"></TableToolbar>
            <Table {...getTableProps()}>
              <TableHead>
                <TableRow>
                  {headers.map((header) => (
                    <TableHeader {...getHeaderProps({ header })}>
                      {header.header}
                    </TableHeader>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow key={row.id}>
                    {row.cells.map((cell) => (
                      <TableCell key={cell.id}>
                        {cell.value?.content ?? cell.value}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </DataTable>
      <TablePagination
        currentPageSize={size}
        onPageChange={onPageChange}
        totalItems={count}
      />
    </Card>
  );
};
export default ProjectClientPage;
