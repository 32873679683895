import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate, useParams } from "react-router-dom";
import useApplication from "../../hooks/useApplication";
import useAuth from "../../hooks/useAuth";
import { basicApplicationSchema } from "../../services/SchemaService";
import Card from "../../components/Card/Card";
import { Form } from "carbon-components-react";
import { Tabs } from "carbon-components-react";
import { Tab } from "carbon-components-react";
import CompetitorDataForm from "../../components/Forms/CompetitorDataForm";
import { Button } from "carbon-components-react";
import { ArrowRight20 } from "@carbon/icons-react";
import ExecutiveSummaryForm from "../../components/Forms/ExecutiveSummaryForm";
import { ArrowLeft20 } from "@carbon/icons-react";
import BusinessProfileForm from "../../components/Forms/BusinessProfileForm";
import BusinessIdeaForm from "../../components/Forms/BusinessIdeaForm";
import { ButtonSet } from "carbon-components-react";
import SubmitButton from "../../components/Buttons/SubmitButton";
import CancelButton from "../../components/Buttons/CancelButton";
import http from "../../http-common";
import { toast } from "react-toastify";
import ApplicationFillingInstructions from "../../components/Miscellaneous/ApplicationFillingInstructions";

const EditApplicationPage = () => {
  const params = useParams();
  const [uuid] = useState(params.uuid);
  const [activeTab, setActiveTab] = useState(0);
  const { application } = useApplication(uuid);
  const { isCompetitor } = useAuth();
  const [hasBusinessCurrently, setHasBusinessCurrently] = useState(null);
  const [isOpen, setIsOpen] = useState(true);

  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(basicApplicationSchema),
    mode: "onBlur",
  });

  const onSubmit = async (data) => {
    try {
      const response = await http.put(`/applications/${params.uuid}`, data);
      toast.success("Candidatura Actualizada com sucesso");
      navigate("../visualizar-candidatura/" + response.data.uuid);
    } catch (error) {
      toast.error(error?.response?.data.message);
    }
  };

  useEffect(() => {
    reset(application);
    setHasBusinessCurrently(application.hasBusinessCurrently);
    setIsOpen(application.currentStageCode === 1);
    // console.log(application);
  }, [uuid, application]);

  const nextTab = () => {
    setActiveTab((prev) => prev + 1);
  };
  const previousTab = () => {
    setActiveTab((prev) => prev - 1);
  };

  return (
    <Card title="Editar Candidatura">
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Tabs
          scrollIntoView={false}
          selected={activeTab}
          type={"container"}
          onSelectionChange={(e) => setActiveTab(e)}
        >
          <Tab href="#" id="tab-0" label="INSTRUÇÕES">
            <ApplicationFillingInstructions />
            <div className="tab-navigation">
              <p></p>
              <Button kind="ghost" size="sm" onClick={nextTab}>
                Próximo <ArrowRight20 />
              </Button>
            </div>
          </Tab>
          <Tab href="#" id="tab-1" label="SECÇÃO A">
            <CompetitorDataForm
              register={register}
              control={control}
              errors={errors}
              application={application}
            />
            <div className="tab-navigation">
              <Button kind="ghost" size="sm" onClick={previousTab}>
                <ArrowLeft20 /> Anterior
              </Button>
              <Button kind="ghost" size="sm" onClick={nextTab}>
                Próximo <ArrowRight20 />
              </Button>
            </div>
          </Tab>
          <Tab href="#" id="tab-2" label="SECÇÃO B">
            <ExecutiveSummaryForm
              register={register}
              errors={errors}
              hasBusinessCurrently={hasBusinessCurrently}
              setHasBusinessCurrently={setHasBusinessCurrently}
            />
            <div className="tab-navigation">
              <Button kind="ghost" size="sm" onClick={previousTab}>
                <ArrowLeft20 /> Anterior
              </Button>
              <Button kind="ghost" size="sm" onClick={nextTab}>
                Próximo <ArrowRight20 />
              </Button>
            </div>
          </Tab>
          {hasBusinessCurrently !== null && hasBusinessCurrently === "Sim" && (
            <Tab href="#" id="tab-3" label="SECÇÃO C">
              <BusinessProfileForm
                register={register}
                control={control}
                errors={errors}
                application={application}
              />
              <div className="tab-navigation">
                <Button kind="ghost" size="sm" onClick={previousTab}>
                  <ArrowLeft20 /> Anterior
                </Button>
                <Button kind="ghost" size="sm" onClick={nextTab}>
                  Próximo <ArrowRight20 />
                </Button>
              </div>
            </Tab>
          )}
          <Tab href="#" id="tab-4" label="SECÇÃO D">
            <BusinessIdeaForm register={register} errors={errors} />
            <div className="tab-navigation">
              <Button kind="ghost" size="sm" onClick={previousTab}>
                <ArrowLeft20 /> Anterior
              </Button>
            </div>
          </Tab>
        </Tabs>

        <ButtonSet>
          {isOpen && <SubmitButton />}
          <CancelButton
            navigateTo={isCompetitor ? "../inicio" : "../candidaturas"}
          />
        </ButtonSet>
      </Form>
    </Card>
  );
};

export default EditApplicationPage;
