import React from 'react'
import Card from '../../components/Card/Card'
import ServiceForm from './ServiceForm'
import { useParams } from 'react-router-dom'

const CreateServicePage = () => {
  const params = useParams()

  return (
    <Card title="Registar Actividades">
      <ServiceForm
        mode={'CREATE'}
        url={'/services'}
        successMessage={'Actividades registado com sucesso'}
        onSuccessNavigateTo={'../actualizar-projecto/' + params.id}
        projectid={params.id}
      />
    </Card>
  )
}

export default CreateServicePage
