import React from 'react'
import { useParams } from 'react-router-dom'
import Card from '../../components/Card/Card'
import ProjectForm from './ProjectForm'

const CreateProjectPage = () => {
  const params = useParams()

  return (
    <div>
      <Card title="Registar Projecto">
        <ProjectForm
          mode={'CREATE'}
          url={'/projects/'}
          successMessage={'Projecto registado com sucesso'}
          onSuccessNavigateTo={'../actualizar-projecto/' + params.id}
          clientid={params.id}
        />
      </Card>
    </div>
  )
}

export default CreateProjectPage
