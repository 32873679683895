import React, { useEffect, useState } from "react";
import { TextInput, Row, Column, FormGroup } from "carbon-components-react";
import useGet from "../../hooks/useGet";
import useAuth from "../../hooks/useAuth";
import GenericCombo from "../Combos/GenericCombo";
import ProvinceCombo from "../Combos/ProvinceCombo";
import DistrictCombo from "../Combos/DistrictCombo";

const CompetitorDataForm = ({ disabled, register, control, errors }) => {
  const { data: genders } = useGet("/utils/genders");
  const { data: idDocTypes } = useGet("/utils/identification-doc-types");
  const { data: addressTypes } = useGet("/utils/address-types");
  const { data: academicLevels } = useGet("/utils/academic-levels");
  const [districts, setDistricts] = useState([]);

  const [otherDocType, setOtherDocType] = useState(false);

  const { isCompetitor } = useAuth();

  const handleDocTypeChange = (e) => {
    setOtherDocType(e.target.value == 0);
  };

  return (
    <FormGroup
      legendText="SECÇÃO A: DADOS PESSOAIS DO/A CANDIDATO/A"
      className="section-header"
    >
      <fieldset className="bx--fieldset">
        <Row>
          <Column xml={12} sm={6} md={6} lg={6} className="required">
            <TextInput
              labelText="A1. Nome"
              id="firstName"
              maxLength={64}
              disabled={disabled}
              {...register("firstName")}
            />
            <p className="field-error">{errors.firstName?.message}</p>
          </Column>
          <Column xml={12} sm={6} md={6} lg={6} className="required">
            <TextInput
              labelText="A1. Apelido"
              id="lastName"
              maxLength={64}
              disabled={disabled}
              {...register("lastName")}
            />
            <p className="field-error">{errors.lastName?.message}</p>
          </Column>
        </Row>
        <Row>
          <Column xml={12} sm={6} md={4} lg={3} className="required">
            <TextInput
              placeholder="dd/mm/aaaa"
              labelText="A2. Data de Nascimento"
              id="birthDate"
              disabled={disabled}
              {...register("birthDate")}
            />
            <p className="field-error">{errors.birthDate?.message}</p>
          </Column>
          <Column xml={12} sm={6} md={4} lg={3} className="required">
            <GenericCombo
              id="gender"
              name="gender"
              label="A3. Sexo"
              disabled={disabled}
              register={register}
              items={genders}
            />
            <p className="field-error">{errors.gender?.message}</p>
          </Column>
        </Row>
      </fieldset>
      <fieldset className="bx--fieldset">
        <legend className="bx--label">
          A4. Tipo de documento de identificação
        </legend>
        <Row>
          <Column xml={12} sm={6} md={4} lg={3}>
            <GenericCombo
              id="identificationDocTypeCode"
              name="identificationDocTypeCode"
              label="Tipo de Documento"
              disabled={disabled}
              register={register}
              items={idDocTypes}
              onChange={handleDocTypeChange}
            />
            <p className="field-error">
              {errors.identificationDocTypeCode?.message}
            </p>
          </Column>
          <Column xml={12} sm={6} md={4} lg={3}>
            <TextInput
              id="otherIdentificationDocType"
              labelText="Outro (especifique)"
              maxLength={64}
              disabled={!otherDocType}
              {...register("otherIdentificationDocType")}
            />
            <p className="field-error">
              {errors.otherIdentificationDocType?.message}
            </p>
          </Column>
          <Column xml={12} sm={6} md={4} lg={3}>
            <TextInput
              id="identificationDocNumber"
              labelText="Número do documento"
              maxLength={13}
              disabled={disabled}
              {...register("identificationDocNumber")}
            />
            <p className="field-error">
              {errors.identificationDocNumber?.message}
            </p>
          </Column>
          <Column xml={12} sm={6} md={4} lg={3}>
            <TextInput
              id="nuit"
              labelText="A5. NUIT"
              maxLength={9}
              disabled={disabled}
              {...register("nuit")}
            />
            <p className="field-error">{errors.nuit?.message}</p>
          </Column>
        </Row>
      </fieldset>
      <fieldset className="bx--fieldset">
        <Row></Row>
      </fieldset>
      <fieldset className="bx--fieldset">
        <legend className="bx--label">
          A6. Endereço de domicílio de negócio /residência
        </legend>
        <Row>
          <Column xml={12} sm={6} md={3} lg={3} className="required">
            <ProvinceCombo
              id="provinceId"
              name="provinceId"
              label="Província"
              disabled={disabled}
              control={control}
              setDistricts={setDistricts}
            />
            <p className="field-error">{errors.provinceId?.message}</p>
          </Column>

          <Column xml={12} sm={6} md={3} lg={3} className="required">
            <DistrictCombo
              id="districtId"
              name="districtId"
              label="Cidade/Distrito"
              disabled={disabled}
              items={districts}
              control={control}
            />
            <p className="field-error">{errors.districtId?.message}</p>
          </Column>
          <Column xml={12} sm={6} md={6} lg={6} className="required">
            <TextInput
              id="neighborhood"
              labelText="Bairro"
              maxLength={128}
              disabled={disabled}
              {...register("neighborhood")}
            />
            <p className="field-error">{errors.neighborhood?.message}</p>
          </Column>
        </Row>

        <Row>
          <Column xml={12} sm={6} md={3} lg={3} className="required">
            <TextInput
              id="street"
              labelText="Av/Rua"
              maxLength={128}
              disabled={disabled}
              {...register("street")}
            />
            <p className="field-error">{errors.street?.message}</p>
          </Column>
          <Column xml={12} sm={6} md={3} lg={3}>
            <TextInput
              id="block"
              labelText="Quarteirão Nº"
              maxLength={64}
              disabled={disabled}
              {...register("block")}
            />
            <p className="field-error">{errors.block?.message}</p>
          </Column>
          <Column xml={12} sm={6} md={3} lg={3}>
            <TextInput
              id="houseNumber"
              labelText="Casa Nº"
              maxLength={64}
              disabled={disabled}
              {...register("houseNumber")}
            />
            <p className="field-error">{errors.houseNumber?.message}</p>
          </Column>
          <Column xml={12} sm={6} md={4} lg={3} className="required">
            <GenericCombo
              id="addressTypeCode"
              name="addressTypeCode"
              label="O endereço registado é do/a"
              disabled={disabled}
              register={register}
              items={addressTypes}
            />
            <p className="field-error">{errors.addressTypeCode?.message}</p>
          </Column>
        </Row>
      </fieldset>
      <fieldset className="bx--fieldset">
        <Row>
          <Column sm={12} md={3} lg={3} className="required">
            <TextInput
              id="phoneNumber1"
              labelText="A7. Nº de telefone 1"
              maxLength={9}
              disabled={disabled || isCompetitor}
              {...register("phoneNumber1")}
            />
            <p className="field-error">{errors.phoneNumber1?.message}</p>
          </Column>
          <Column sm={12} md={3} lg={3}>
            <TextInput
              id="phoneNumber2"
              labelText="A7. Nº de telefone 2"
              maxLength={9}
              disabled={disabled}
              {...register("phoneNumber2")}
            />
            <p className="field-error">{errors.phoneNumber2?.message}</p>
          </Column>
          <Column sm={12} md={6} lg={6}>
            <TextInput
              id="email"
              labelText="A8. E-mail"
              maxLength={64}
              disabled={disabled}
              {...register("email")}
            />
            <p className="field-error">{errors.email?.message}</p>
          </Column>
        </Row>
      </fieldset>
      <fieldset className="bx--fieldset">
        <legend className="bx--label">
          A9. Nome, número de telefone e grau de relação da pessoa de contacto
          alternativo
        </legend>
        <Row>
          <Column sm={12} md={6} lg={6} className="required">
            <TextInput
              id="altContactPersonName"
              labelText="Nome da pessoa de contacto alternativo"
              maxLength={128}
              disabled={disabled}
              {...register("altContactPersonName")}
            />
            <p className="field-error">
              {errors.altContactPersonName?.message}
            </p>
          </Column>
          <Column sm={12} md={3} lg={3} className="required">
            <TextInput
              id="altContactPersonPhoneNumber"
              labelText="Nº de telefone da pessoa"
              maxLength={9}
              disabled={disabled}
              {...register("altContactPersonPhoneNumber")}
            />
            <p className="field-error">
              {errors.altContactPersonPhoneNumber?.message}
            </p>
          </Column>
          <Column sm={12} md={3} lg={3} className="required">
            <TextInput
              id="altContactPersonRelationship"
              labelText="Grau de relação da pessoa"
              maxLength={128}
              disabled={disabled}
              {...register("altContactPersonRelationship")}
            />
            <p className="field-error">
              {errors.altContactPersonRelationship?.message}
            </p>
          </Column>
        </Row>
      </fieldset>
      <Row>
        <Column sm={12} md={6} lg={6} className="required">
          <GenericCombo
            id="academicLevelCode"
            name="academicLevelCode"
            label="A11. Nível Académico"
            disabled={disabled}
            register={register}
            items={academicLevels}
          />

          <p className="field-error">{errors.academicLevelCode?.message}</p>
        </Column>
      </Row>
    </FormGroup>
  );
};

CompetitorDataForm.defaultProps = {
  disabled: false,
};

export default CompetitorDataForm;
