import React from "react";

import { useParams } from "react-router-dom";
import Card from "../../components/Card/Card";
import ManagementForm from "./ManagementForm";

const CreateManagementPage = () => {
  const params = useParams();
  return (


    
    <Card title="Plano de Ferias">
      <ManagementForm
        mode={"CREATE"}
        url={"/absencemanagement"}
        successMessage={"Plano registado com sucesso"}
        onSuccessNavigateTo={"../consultar-ferias"}
        employeeId={params.id}
      />
    </Card>
  );
};

export default CreateManagementPage;
