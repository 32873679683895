import Card from "../../components/Card/Card";

import { Add16, Edit24, TrashCan24, SaveModel24, ArrowLeft24 } from "@carbon/icons-react";
import {
  Button,
  TextArea,
  Form,
  Row,
  Column,
  DatePicker,
  DatePickerInput,
  FormGroup,
  TextInput,
  DataTable,
  Table,
  Loading,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
  TableToolbar,
  TableToolbarContent,
  Modal,
} from "carbon-components-react";
import React from "react";
import { useEffect, useState, useContext } from "react";
import { set, useForm } from "react-hook-form";
import { useNavigate, useParams, Link } from "react-router-dom";
import { toast } from "react-toastify";
import http from "../../http-common";

const tableHeaders = [
  {
    header: "Descrição",
    key: "description",
  },
  {
    header: "Data",
    key: "historyDate",
  },
  {
    header: "Operações",
    key: "actions",
  },
];

const ContractForm = () => {
  const [activeLoad, SetactiveLoad] = useState({
    loadValue: false,
    btnDisable: false,
  });

  const [service, setService] = useState({
    description: "",
    startDate: "",
    endDate: "",
    position: "",
    status: "",
    client: { id: "", firstName: "", surName: "" },
  });

  const [payment, setPayment] = useState({
    id: "",
    payment: "",
    paymentDate: "",
    prevPaymentDate: "",
  });

  const [paymentHistorie, setPaymentHistorie] = useState([]);

  async function onSubmit(data) {
    try {
      var _data = null;

      _data = await http.post("/payment", data);
      toast.success("Operação realizada com sucesso!");
    } catch (error) {
      toast.error(error.response?.data.message);
    }
  }

  const {
    register,
    handleSubmit,
    reset,
    setValue,

    formState: { errors },
  } = useForm({ mode: "onSubmit" });
  const [number, onChangeNumber] = React.useState("");
  const navigate = useNavigate();
  const params = useParams();

  const [showModal, setShowModal] = useState({
    modalval: false,
    hId: 0,
    hdesc: "",
  });
  const [paymentHistoryEdit, setPaymentHistoryEdit] = useState({
    modalval: false,
    id: 0,
    description: "",
    paymentTermId: 0,
  });
  const [paymentHistoryCreate, setPaymentHistoryCreate] = useState({
    modalval: false,
    id: 0,
    description: "",
    paymentTermId: 0,
  });



  const fetchData = async () => {
    try {
      const servic_result = await http.get(`/services/${params.id}`);
      console.log("servic_result", servic_result)
      const fields = [
        "description",
        "startDate",
        "endDate",
        "position",
        "status",
      ];
      fields.forEach((field) => setValue(field, service[field]));

      setService(servic_result.data);

      const payment_result = await http.get(`/payment/${params.id}`);

      const paymentHistorie_ = payment_result?.data?.paymentHistories.map(
        (x) => ({
          id: x.id,
          description: x.description,
          historyDate: x.historyDate,
          paymentTermId: x.paymentTermId,

          actions: (
            <>
              <ul style={{ display: "flex" }}>
                <li style={{ "margin-right": "10px" }}>
                  <Edit24
                    onClick={(e) => {
                      setPaymentHistoryEdit((prev) => ({
                        ...prev,

                        modalval: true,

                        id: x.id,
                        description: x.description,
                        paymentTermId: payment_result?.data?.id,
                      }));
                    }}
                  />
                </li>

                <li>
                  <TrashCan24
                    onClick={(e) => {
                      setShowModal((prev) => ({
                        ...prev,

                        modalval: true,
                        hId: x.id,
                        hdesc: x.description,
                      }));
                    }}
                  />
                </li>
              </ul>
            </>
          ),
        })
      );

      setPaymentHistorie(paymentHistorie_);

      setPayment(payment_result.data);
    } catch (error) {
      toast.error(error.response?.data.message);
    }
  };

  const [open, setOpen] = useState(false);
  const closeModal = () => {
    setOpen(false);
  };

  useEffect(() => {
    SetactiveLoad((prevValue) => ({
      ...prevValue,
      loadValue: true,
      btnDisable: true,
    }));

    fetchData();
    SetPaymentValue();

    SetactiveLoad((prevValue) => ({
      ...prevValue,
      loadValue: false,
      btnDisable: false,
    }));
  }, []);

  useEffect(() => {
    reset(payment);
  }, [payment]);

  useEffect(() => {
    setPaymentHistorie(paymentHistorie);
  }, [paymentHistorie]);

  const SetPaymentValue = async () => {
    setValue("payment", payment.payment);
    payment.payment = payment.payment;
  };

  const setpaymentDate = (e) => {
    setValue("paymentDate", e.target.value);
    payment.paymentDate = e.target.value;
  };

  const setprevPaymentDate = (e) => {
    setValue("prevPaymentDate", e.target.value);
    payment.prevPaymentDate = e.target.value;
  };

  const linkstyles = {
    links: { "margin-right": "10px", "margin-left": "10px" },
  };

  function handleChange(e) {
    const { name, value } = e.target;

    setPaymentHistoryEdit((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }

  function handleChangeCreate(e) {
    const { name, value } = e.target;

    setPaymentHistoryCreate((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }

  async function onDeleteHistory() {
    const resquest = await http.post(`/deletepaymenthistory/${showModal.hId}`);

    fetchData();

    setShowModal((prev) => ({
      ...prev,

      modalval: false,
      hId: 0,
    }));

    toast.success("Operação realizada com sucesso!");
  }

  async function onEditHistory() {
    var data = await http.post("/paymenthistory", paymentHistoryEdit);

    fetchData();

    setPaymentHistoryEdit((prev) => ({
      ...prev,

      modalval: false,
      description: paymentHistoryEdit.description,
    }));

    toast.success("Operação realizada com sucesso!");
  }

  async function onCreateHistory() {
    var data = await http.post("/paymenthistory", paymentHistoryCreate);

    fetchData();

    setPaymentHistoryCreate((prev) => ({
      ...prev,

      modalval: false,
      id: 0,
      description: "",
      paymentTermId: payment?.id,
    }));

    toast.success("Operação realizada com sucesso!");
  }

  const myEditStyle = {
    width: "100%",
    height: "100px",
  };

  return (
    <>
      <Loading
        description="Active loading indicator"
        withOverlay={true}
        active={activeLoad.loadValue}
      />

      <Modal
        modalHeading="Apagar Histórico Facturação"
        open={showModal.modalval}
        onRequestClose={(e) => {
          setShowModal(false);
        }}
        primaryButtonText="Sim"
        secondaryButtonText="Não"
        onRequestSubmit={handleSubmit(() => {
          onDeleteHistory();
        })}
      >
        <label> Quer Apagar essa histórico da factura ?</label>
        <p>
          <strong>Descrição:</strong> {" " + showModal.hdesc}
        </p>
      </Modal>

      <Modal
        modalHeading="Editar Histórico Facturação"
        open={paymentHistoryEdit.modalval}
        onRequestClose={(e) => {
          setPaymentHistoryEdit(false);
        }}
        primaryButtonText="Actualizar"
        secondaryButtonText="Cancelar"
        onRequestSubmit={handleSubmit(() => {
          onEditHistory();
        })}
      >
        <TextArea
          style={myEditStyle}
          id="description"
          name="description"
          value={paymentHistoryEdit.description}
          onChange={handleChange}
          labelText="Descrição"
        />
      </Modal>

      <Modal
        modalHeading="Criar Histórico Facturação"
        open={paymentHistoryCreate.modalval}
        onRequestClose={(e) => {
          setPaymentHistoryCreate(false);
        }}
        primaryButtonText="Criar"
        secondaryButtonText="Cancelar"
        onRequestSubmit={handleSubmit(() => {
          onCreateHistory();
        })}
      >
        <TextArea
          style={myEditStyle}
          id="description"
          name="description"
          value={paymentHistoryCreate.description}
          onChange={handleChangeCreate}
          labelText="Descrição"
        />
      </Modal>

      <Card title="Factura">
        <FormGroup
          legendId="form-group-1"
          legendText="Detalhes do Serviço a facturar"
          style={{
            marginBottom: "20px",
          }}
        >
          <div className="bx--grid">
            <div className="bx--row">
              <div className="bx--col">
                <strong>Descrição:</strong> {service.description}{" "}
              </div>
              <div className="bx--col">
                <strong>Data de inicio:</strong> {service.startDate}{" "}
              </div>
              <div className="bx--col"> </div>
              <div className="bx--col"> </div>
            </div>

            <div className="bx--row">
              <div className="bx--col">
                <strong>Estado:</strong> {service.status}{" "}
              </div>
              <div className="bx--col">
                <strong>Data de fim:</strong> {service.endDate}{" "}
              </div>
              <div className="bx--col"> </div>
              <div className="bx--col"> </div>
            </div>
          </div>
        </FormGroup>

        <hr />

        <FormGroup
          legendId="form-group-1"
          legendText="Detalhes da factura"
          style={{ marginTop: "20px" }}
        >
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Column>
                <TextInput
                  id="name"
                  labelText="Cliente"
                  value={service?.client?.name}
                  disabled
                  style={{ width: "330px" }}
                />
              </Column>

              <Column>
                <TextInput
                  id="name"
                  labelText="Projecto"
                  value={service?.project?.name}
                  disabled
                  {...register("description")}
                  style={{ width: "330px" }}
                />
              </Column>
            </Row>

            <Row>
              <Column sm={6} md={6} lg={6}>
                <DatePicker
                  locale="pt"
                  dateFormat="d/m/Y"
                  datePickerType="single"
                >
                  <DatePickerInput
                    style={{ width: "330px" }}
                    labelText="Data de previsão de factura"
                    id="prevPaymentDate"
                    name="prevPaymentDate"
                    value={payment.prevPaymentDate}
                    onSelect={setprevPaymentDate}
                  />
                </DatePicker>
              </Column>

              <Column sm={6} md={6} lg={6}>
                <DatePicker
                  locale="pt"
                  dateFormat="d/m/Y"
                  datePickerType="single"
                >
                  <DatePickerInput
                    style={{ width: "330px" }}
                    labelText="Data real da factura"
                    id="paymentDate"
                    name="paymentDate"
                    value={payment.paymentDate}
                    onSelect={setpaymentDate}
                  />
                </DatePicker>
              </Column>

              <Column sm={6} md={6} lg={6} className="required">
                <TextInput
                  id="name"
                  labelText="Valor"
                  onChange={onChangeNumber}
                  {...register("payment")}
                  style={{ width: "330px" }}
                />
              </Column>
            </Row>

            <Row>



              <Button
                type="submit"
                renderIcon={SaveModel24}
                iconDescription="Save"
                style={{ marginLeft: "17px", marginTop: "20px" }}
              >
                Salvar
              </Button>

              <Button
                onClick={() => navigate("../contratos/")}
                renderIcon={ArrowLeft24}
                iconDescription="Save"
                style={{ marginLeft: "17px", marginTop: "20px" }}
              >
                voltar
              </Button>

            </Row>

          </Form>
        </FormGroup>
      </Card>

      <Card title="Histórico Facturação">
        <DataTable
          rows={paymentHistorie}
          headers={tableHeaders}
          isSortable
          useZebraStyles
        >
          {({ rows, headers, getHeaderProps, getTableProps }) => (
            <TableContainer>
              <TableToolbar aria-label="data table toolbar">
                <TableToolbarContent>
                  <div className="data-table-search-form">
                    <Button
                      type="submit"
                      renderIcon={Add16}
                      onClick={(e) => {
                        setPaymentHistoryCreate((prev) => ({
                          ...prev,

                          modalval: true,

                          id: 0,
                          description: "",
                          paymentTermId: payment?.id,
                        }));
                      }}
                    >
                      Adicionar
                    </Button>
                  </div>
                </TableToolbarContent>
              </TableToolbar>
              <Table {...getTableProps()}>
                <TableHead>
                  <TableRow>
                    {headers.map((header) => (
                      <TableHeader {...getHeaderProps({ header })}>
                        {header.header}
                      </TableHeader>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => (
                    <TableRow key={row.id} className="text-danger">
                      {row.cells.map((cell) => (
                        <TableCell key={cell.id}>
                          {cell.value?.content ?? cell.value}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </DataTable>
      </Card>
    </>
  );
};

export default ContractForm;
