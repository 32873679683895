import "react-toastify/dist/ReactToastify.css";
import "./app.scss";
import { Routes, Route } from "react-router-dom";
import AdminLayout from "./layouts/AdminLayout";
import PublicLayout from "./layouts/PublicLayout";
import HomePage from "./pages/Home/HomePage";
import SignupPage from "./pages/User/SignupPage";
import ListGrievancesPage from "./pages/Grievance/ListGrievancesPage";
import DashboardPage from "./pages/Dashboard/DashboardPage";
import UsersPage from "./pages/User/UsersPage";
import NotFoudPage from "./pages/404/NotFoudPage";
import ManageGrievancePage from "./pages/Grievance/ManageGrievancePage";
import CreateUserPage from "./pages/User/CreateUserPage";
import UserProfilePage from "./pages/User/UserProfilePage";
import ContractsPage from "./pages/Contract/ContractsPage";
import CreateContractPage from "./pages/Contract/CreateContractPage";
import CreateBusinessPlanPage from "./pages/BusinessPlan/CreateBusinessPlanPage";
import BusinessPlansPage from "./pages/BusinessPlan/BusinessPlansPage";
import ApplicationEvaluationPage from "./pages/Application/ApplicationEvaluationPage";
import ApplicationRankingPage from "./pages/Application/ApplicationRankingPage";
import ApplicationJuryPanelPage from "./pages/Application/ApplicationJuryPanelPage";
import ApplicationTriagePage from "./pages/Application/ApplicationTriagePage";
import ViewApplicationPage from "./pages/Application/ViewApplicationPage";
import ApplicationValidationPage from "./pages/Application/ApplicationValidationPage";
import SubmitGrievancePage from "./pages/Grievance/SubmitGrievancePage";
import UpdateUserPage from "./pages/User/UpdateUserPage";
import LandingPage from "./pages/Dashboard/LandingPage";
import AddApplicationPage from "./pages/Application/AddApplicationPage";
import { AuthProvider } from "./contexts/AuthContext";
import EditApplicationPage from "./pages/Application/EditApplicationPage";
import ListApplicationsPage from "./pages/Application/ListApplicationsPage";
import TrackGrievancePage from "./pages/Grievance/TrackGrievancePage";
import ViewGrievancePage from "./pages/Grievance/ViewGrievancePage";
import ScoreApplicationPage from "./pages/Application/ScoreApplicationPage";
import LoginPage from "./pages/Home/LoginPage";
import ApplicationTiebreakerPage from "./pages/Application/ApplicationTiebreakerPage";
import PlanePage from "./pages/Plan/PlanePage";
import InsuranceCompanyPage from "./pages/InsuranceCompany/InsuranceCompanyPage";
import CreateProductPage from "./pages/Product/CreateProductPage";
import SellerProductListPage from "./pages/Product/SellerProductList";
import UpdateProductPage from "./pages/Product/UpdateProductPage";
import ConfigurationPage from "./pages/Admin/ConfigurationPage";
import ConfigurationForm from "./pages/Admin/ConfigurationForm";

import ListingPage from "./pages/report/listing/ListingPage";
import ResumePage from "./pages/report/resume/ResumePage";

import FairsPage from "./pages/Fair/FairPage";
import FairDetailsPage from "./pages/Fair/FairDetailsPage";
import CreateFairPage from "./pages/Fair/CreateFair";
import UpdateFairPage from "./pages/Fair/UpdateFair";
import SalesFairPage from "./pages/Fair/FairSales";
import CreateNewProduct from "./pages/Product/CreateNewProduct";
import PendingApprovals from "./pages/Approval/PendingApprovals";
import AcceptedApprovals from "./pages/Approval/AcceptedApprovals";
import RejectedApprovals from "./pages/Approval/RejectedApprovals";
import ApprovalForm from "./pages/Approval/ApprovalForm";
import InfoPage from "./pages/Home/InfoPage";
import InfoMaintenancePage from "./pages/Home/InfoMaintenancePage";
import ClientPage from "./pages/Client/ClientPage";
import UpdateClientPage from "./pages/Client/UpdateClientPage";
import CreateClientPage from "./pages/Client/CreateClientPage";
import CreateServicePage from "./pages/service/CreateServicePage";
import UpadateClientPage from "./pages/service/UpadateServicePage";
import CreateProjectPage from "./pages/Project/CreateProjectPage";
import UpdateProjectPage from "./pages/Project/UpdateProjectPage";
import ListProjectPage from "./pages/Project/ListProjectPage";

import CreateContactPage from "./pages/ClientContact/CreateContactPage";
import UpdateContactPage from "./pages/ClientContact/UpdateContactPage";

import ContactPage from "./pages/ClientContact/ContactPage";
import EmployeePage from "./pages/Employee/EmployeePage";
import CreateEmployeePage from "./pages/Employee/CreateEmployeePage";
import UpdateEmployeePage from "./pages/Employee/UpdateEmployeePage";

import ListServicePage from "./pages/service/ListServicePage";
import EmployeeListPage from "./pages/Employee/EmployeeListPage";
import ManagementForm from "./pages/Management/ManagementForm";
import ManagementPage from "./pages/Management/ManagementPage";
import CreateManagementPage from "./pages/Management/CreateManagementPage";
import ContractForm from "./pages/Contract/ContractForm";
import UpdateManagementPage from "./pages/Management/UpdateManagementPage";
import ProviderPage from "./pages/Provider/ProviderPage";
import CreateProviderPage from "./pages/Provider/CreateProviderPage";
import UpdateProviderPage from "./pages/Provider/UpdateProviderPage";
import LicenseManagementPage from "./pages/LicenseManagement/LicenseManagementPage";
import CreateLicenseManagementPage from "./pages/LicenseManagement/CreateLicenseManagementPage";
import UpdateLicenseManagementPage from "./pages/LicenseManagement/UpdateLicenseManagementPage";

import CreateConfigurationPage from "./pages/Admin/CreateConfigurationPage";
import UpdateConfigurationPage from "./pages/Admin/UpdateConfigurationPage";

function App() {
  return (
    <>
      <AuthProvider>
        <Routes>
          <Route path="/" element={<PublicLayout />}>
            <Route path="/adm" element={<HomePage />} />
            <Route path="/" element={<LoginPage />} />
            <Route path="/maintenance" element={<InfoMaintenancePage />} />

            <Route path="reclamacoes" element={<SubmitGrievancePage />} />
            <Route
              path="verificar-reclamacao/:occurrenceNumber"
              element={<TrackGrievancePage />}
            />
            <Route path="info" element={<InfoPage />} />
          </Route>
          <Route path="/admin/*" element={<AdminLayout />}>
            <Route path="dashboard" element={<DashboardPage />} />
            <Route path="planes" element={<PlanePage />} />
            <Route
              path="insurance-companies"
              element={<InsuranceCompanyPage />}
            />
            <Route path="seller-product" element={<SellerProductListPage />} />
            <Route path="candidaturas" element={<ListApplicationsPage />} />
            <Route path="inicio" element={<LandingPage />} />
            <Route path="create-product" element={<CreateProductPage />} />
            <Route
              path="edit-product/:sellerProductId"
              element={<UpdateProductPage />}
            />
            <Route path="fairs" element={<FairsPage />} />
            <Route path="fairs/:fairId" element={<FairDetailsPage />} />
            <Route path="create-fair" element={<CreateFairPage />} />
            <Route path="edit-fair/:fairId" element={<UpdateFairPage />} />
            <Route path="fairSales/:id" element={<SalesFairPage />} />
            <Route path="product-request" element={<CreateNewProduct />} />
            <Route path="pending-approvals" element={<PendingApprovals />} />
            <Route path="accepted-approvals" element={<AcceptedApprovals />} />
            <Route path="rejected-approvals" element={<RejectedApprovals />} />
            <Route path="approval-details" element={<ApprovalForm />} />
            <Route
              path="registar-candidatura"
              element={<AddApplicationPage />}
            />
            <Route
              path="editar-candidatura/:uuid"
              element={<EditApplicationPage />}
            />
            <Route
              path="visualizar-candidatura/:uuid"
              element={<ViewApplicationPage />}
            />
            <Route
              path="triagem-de-candidaturas"
              element={<ApplicationTriagePage />}
            />
            <Route
              path="painel-de-juri"
              element={<ApplicationJuryPanelPage />}
            />
            <Route
              path="avaliacao-de-candidaturas"
              element={<ApplicationEvaluationPage />}
            />
            <Route
              path="pontuar-candidatura/:uuid"
              element={<ScoreApplicationPage />}
            />
            <Route
              path="ranking-de-candidaturas"
              element={<ApplicationRankingPage />}
            />
            <Route
              path="desempate-de-pontuacao"
              element={<ApplicationTiebreakerPage />}
            />
            <Route
              path="validacao-de-candidaturas"
              element={<ApplicationValidationPage />}
            />
            <Route path="planos-de-negocio" element={<BusinessPlansPage />} />
            <Route
              path="registar-plano-de-negocio"
              element={<CreateBusinessPlanPage />}
            />

            <Route path="configuration" element={<ConfigurationPage />} />

            <Route path="listing" element={<ListingPage />} />
            <Route path="resume" element={<ResumePage />} />

            <Route path="contratos" element={<ContractsPage />} />
            <Route path="registar-contrato" element={<CreateContractPage />} />
            <Route path="reclamacoes" element={<ListGrievancesPage />} />
            <Route
              path="consultar-reclamacao/:occurrenceNumber"
              element={<ViewGrievancePage />}
            />
            <Route
              path="gerir-reclamacoes/:occurrenceNumber"
              element={<ManageGrievancePage />}
            />
            <Route path="utilizadores" element={<UsersPage />} />
            <Route path="registar-utilizador" element={<CreateUserPage />} />
            <Route
              path="actualizar-utilizador/:id"
              element={<UpdateUserPage />}
            />
            <Route path="minha-conta" element={<UserProfilePage />} />

            {/*     crm links */}
            <Route path="registar-clientes" element={<CreateClientPage />} />

            <Route path="clientes" element={<ClientPage />} />

            <Route path="update_client/:id" element={<UpdateClientPage />} />
            <Route
              path="update_client/:id/:saved"
              element={<UpdateClientPage />}
            />

            <Route
              path="registar-servicos/:id"
              element={<CreateServicePage />}
            />
            <Route path="registar-servicos" element={<CreateServicePage />} />

            <Route path="servicos" element={<ListServicePage />} />

            <Route path="update-service/:id" element={<UpadateClientPage />} />

            <Route
              path="actualiza-cliente/:id"
              element={<UpdateClientPage />}
            />

            <Route
              path="registar-projecto/:id"
              element={<CreateProjectPage />}
            />
            <Route path="registar-projecto" element={<CreateProjectPage />} />

            <Route path="projectos" element={<ListProjectPage />} />

            <Route
              path="actualizar-projecto/:id"
              element={<UpdateProjectPage />}
            />

            <Route path="update-payment-term/:id" element={<ContractForm />} />

            <Route
              path="configuration-create"
              element={<CreateConfigurationPage />}
            />
            <Route
              path="configuration-update/:id"
              element={<UpdateConfigurationPage />}
            />

            <Route
              path="registar-contacto/:id"
              element={<CreateContactPage />}
            />

            <Route path="contactos" element={<ContactPage />} />

            <Route
              path="actualizar-contacto/:id"
              element={<UpdateContactPage />}
            />

            <Route path="funcionarios" element={<EmployeePage />} />
            <Route path="funcionarios2" element={<EmployeeListPage />} />
            <Route
              path="registar-funcionario/:id"
              element={<CreateEmployeePage />}
            />
            <Route
              path="registar-funcionario"
              element={<CreateEmployeePage />}
            />
            <Route
              path="actualizar-funcionario/:id"
              element={<UpdateEmployeePage />}
            />

            <Route
              path="registar-contractos"
              element={<CreateContractPage />}
            />
            <Route path="contractos/:id" element={<ContractsPage />} />

            <Route path="consultar-ferias" element={<ManagementPage />} />
            <Route
              path="registar-gestao-de-ferias/:id"
              element={<CreateManagementPage />}
            />
            <Route
              path="registar-gestao-de-ferias"
              element={<CreateManagementPage />}
            />
            <Route
              path="actualizar-gestao-de-ferias/:id"
              element={<UpdateManagementPage />}
            />

            <Route path="fornecedor" element={<ProviderPage />} />
            <Route
              path="registar-fornecedor"
              element={<CreateProviderPage />}
            />
            <Route
              path="actualizar-fornecedor/:id"
              element={<UpdateProviderPage />}
            />
            <Route path="licensas" element={<LicenseManagementPage />} />
            <Route
              path="registar-licensas"
              element={<CreateLicenseManagementPage />}
            />

            <Route
              path="actualizar-licensas/:id"
              element={<UpdateLicenseManagementPage />}
            />
          </Route>

          <Route path="*" element={<NotFoudPage />} />
        </Routes>
      </AuthProvider>
    </>
  );
}

export default App;
