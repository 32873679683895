import React, { useEffect, useState } from "react";
import { Column, Row } from "carbon-components-react";
import Card from "../../components/Card/Card";
import { Button } from "carbon-components-react";
import { toast } from "react-toastify";
import http from "../../http-common";
import { useNavigate } from "react-router-dom";
import { ButtonSet } from "carbon-components-react";
import { Loading } from "carbon-components-react";

const ApplicationTriagePage = () => {
  const [stats, setStats] = useState([]);
  const [loading, setLoading] = useState(false);
  const [triageDone, setTriageDone] = useState(true);
  const [jurySet, setJurySet] = useState(false);
  const [eligible, setEligible] = useState(0);

  const navigate = useNavigate();

  const getStats = async () => {
    try {
      const response = await http.get("/applications/triage/totals");
      setStats(response.data);
    } catch (error) {
      toast.error("Erro ao carregar dados estatísticos");
    }
  };

  const getTotalEligibles = async () => {
    try {
      const response = await http.get("/applications/count/eligible");
      setTriageDone(response.data > 0 ? true : false);
      setEligible(response.data);
    } catch (error) {
      toast.error("Erro ao carregar dados estatísticos");
    }
  };

  const getTotalApplicationJury = async () => {
    try {
      const response = await http.get("/applications/count/application-jury");
      setJurySet(response.data > 0 ? true : false);
    } catch (error) {
      toast.error("Erro ao carregar dados estatísticos");
    }
  };

  useEffect(() => {
    getStats();
    getTotalEligibles();
    getTotalApplicationJury();
  }, []);

  const doTriage = async () => {
    try {
      setLoading(true);
      const response = await http.put(`/applications/triage/execute`);
      setEligible(response.data);
      toast.success("Triagem Preliminar executada com sucesso");
      setTriageDone(true);
      setLoading(false);
    } catch (error) {
      toast.error(error?.response?.data.message);
    }
  };

  const assignApplications = async () => {
    try {
      setLoading(true);
      const response = await http.put(`/applications/assign-jury`);
      toast.success("Distribuição de candidaturas executada com sucesso");
      setJurySet(true);
      setLoading(false);
      navigate("../painel-de-juri");
    } catch (error) {
      toast.error(error?.response?.data.message);
    }
  };

  return (
    <Card title="Triagem Preliminar">
      <Loading
        active={loading}
        description="Active loading indicator"
        withOverlay={true}
      />
      <Row className="inner-cards">
        {stats.map((item) => (
          <Column xsm={12} sm={12} lg={3} xlg={3} key={item.key}>
            <Card title={item.key}>
              <h1>{item.value}</h1>
            </Card>
          </Column>
        ))}
        <Column xsm={12} sm={12} lg={3} xlg={3}>
          <Card title="Apurados por triagem">
            <h1>{eligible}</h1>
          </Card>
        </Column>
      </Row>
      <Row className="mt-2">
        <Column>
          <p>São elegíveis as candidaturas submetidas cujos candidatos:</p>
          <ul>
            <li>- Tenham entre os 18 e 35 anos de idade</li>
            <li>- Tenham atingido o ensido secundário</li>
          </ul>
          {!triageDone && (
            <Button className="mt-2" onClick={doTriage}>
              Executar Triagem Preliminar
            </Button>
          )}
          {triageDone && !jurySet && (
            <Button className="mt-2" onClick={assignApplications}>
              Distribuir Candidaturas pelos membros do júri
            </Button>
          )}
        </Column>
      </Row>
    </Card>
  );
};

export default ApplicationTriagePage;
