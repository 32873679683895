import React, { createContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import http from "../http-common";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isCompetitor, setIsCompetitor] = useState(false);
  const [isJury, setIsJury] = useState(false);
  const [loading, setLoading] = useState(true);
  const [sellerID, setSellerID] = useState();
  const [statusApproval, setStatusApproval] = useState();
  const navigate = useNavigate();

  const login = async (username, password) => {
    try {
      const response = await http.post(
        "/auth/login?username=" + username + "&password=" + password
      );
      const principal = {
        username: response.data.username,
        firstName: response.data.firstName,
        lastName: response.data.lastName,
        roleName: response.data.roleName,
        token: response.data.token,
        roles: response.data.roles,
        role: response.data.roles[0].role,
      };
      setUser(principal);
      checkIsCompetitor(response.data.roleCode);
      setIsJury(response.data.roleName === "ROLE_APPLICANT");
      localStorage.setItem("principal", JSON.stringify(principal));
      if (principal.role === "ROLE_SELLER") {
        retrieveSeller(principal.username);
        retrieveSellerApproveState(principal.username);
        navigate(
          "/admin/detalhes-do-fornecedor"
        );
      }else{
        navigate(
          "/admin/inicio"
        );
      }
 
    } catch (error) {
      toast.error("Nuit ou senha inválidos");
    }
  };

  const checkIsCompetitor = (roleName) => {
    let result = roleName === "ROLE_APPLICANT";
    setIsCompetitor(result);
    return result;
  };
  const retrieveSeller = (userName) => {
    http.get('/sellers/users/' + userName).then(response => {
      setSellerID(response.data.id);
    });
  };
  const retrieveSellerApproveState = (userName) => {
    http.get('/approval/status/SELLER_REGISTRATION/' + userName).then(response => {
      setStatusApproval(response.data);
      if (response.data === 2) {
        navigate(
          "info"
        );
      }
    });
  };
  const logout = () => {
    setUser(null);
    setIsCompetitor(false);
    setSellerID(null);
    localStorage.removeItem("principal");
    navigate("/");
  };

  useEffect(() => {
    const loggedUser = localStorage.getItem("principal");
    if (loggedUser) {
      const parsed = JSON.parse(loggedUser);
      setUser(parsed);
      if (parsed.role === "ROLE_SELLER") {
        retrieveSeller(parsed.username);
      }
    }
    setLoading(false);
  }, []);
  return (
    <AuthContext.Provider
      value={{
        authenticated: !!user,
        user,
        isCompetitor,
        isJury,
        login,
        logout,
        loading,
        sellerID,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
