import { DataView24, Edit24 } from "@carbon/icons-react";
import { Search32 } from "@carbon/icons-react";
import { Add16 } from "@carbon/icons-react";
import { Form, Loading } from "carbon-components-react";
import {
  Row,
  Column,
  TextInput,
  Button,
  DataTable,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
  TableToolbar,
  TableToolbarContent,
} from "carbon-components-react";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import Card from "../../components/Card/Card";
import TablePagination from "../../components/Pagination/Pagination";
import http from "../../http-common";
import { toast } from "react-toastify";

const tableHeaders = [
  {
    header: "Nome",
    key: "firstName",
  },
  {
    header: "Apelido",
    key: "lastName",
  },
  {
    header: "Telefone",
    key: "phoneNumber",
  },
  {
    header: "Sexo",
    key: "gender",
  },
  {
    header: "Negócio Existente",
    key: "hasBusinessCurrently",
  },
  {
    header: "P1",
    key: "score1",
  },
  {
    header: "P2",
    key: "score2",
  },
  {
    header: "P1-P2",
    key: "diff",
  },
  {
    header: "Total",
    key: "totalScore",
  },
];

const LinkList = ({ url, url2, isOpen }) => (
  <ul style={{ display: "flex" }}>
    <li>
      <Link to={url2} title="Visualizar">
        <DataView24 />
      </Link>
    </li>
  </ul>
);

const getCellContent = (cell, id) => {
  return cell.value;
};
const ApplicationTiebreakerPage = () => {
  const [apps, setApps] = useState([]);
  const [count, setCount] = useState(0);
  const [size, setSize] = useState(20);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const featchData = async () => {
    try {
      const response = await http.get("/applications/evaluations/tiebreakers");
      getApplicationData(response);
    } catch (error) {}
  };

  const onSearch = async (data) => {
    try {
      console.log(data);
      const response = await http.get(
        `/applications/evaluations/search/filter?competitor.firstName=${data.firstName}&competitor.lastName=${data.lastName}&competitor.phoneNumber1=${data.phoneNumber1}`
      );
      getApplicationData(response);
    } catch (error) {}
  };

  const getApplicationData = (response) => {
    console.log(response.data);
    const data = response.data.map((app) => ({
      id: app.id,
      firstName: app.firstName,
      lastName: app.lastName,
      phoneNumber: app.phoneNumber,
      gender: app.gender.code,
      hasBusinessCurrently: app.hasBusinessCurrently ? "Sim" : "Não",
      score1: app.score1 < 0 ? "-" : app.score1 + "%",
      score2: app.score2 < 0 ? "-" : app.score2 + "%",
      diff:
        app.score1 - app.score2 < 0
          ? (app.score1 - app.score2) * -1 + "%"
          : app.score1 - app.score2 + "%",
      totalScore: app.totalScore < 0 ? "-" : app.totalScore + "%",
    }));
    setApps(data);
    setCount(response.data.totalElements);
  };

  const assignApplications = async () => {
    try {
      setLoading(true);
      const response = await http.put(`/applications/assign-tiebracker-jury`);
      toast.success("Atribuição de juri de desempate executada com sucesso");
      setLoading(false);
      // navigate("../painel-de-juri");
    } catch (error) {
      toast.error(error?.response?.data.message);
    }
  };

  useEffect(() => {
    featchData();
  }, []);

  return (
    <Card title="Desempate de Pontuação">
      <Loading
        active={loading}
        description="Active loading indicator"
        withOverlay={true}
      />
      <DataTable rows={apps} headers={tableHeaders} isSortable useZebraStyles>
        {({ rows, headers, getHeaderProps, getTableProps }) => (
          <TableContainer>
            <Table {...getTableProps()}>
              <TableHead>
                <TableRow>
                  {headers.map((header) => (
                    <TableHeader {...getHeaderProps({ header })}>
                      {header.header}
                    </TableHeader>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow key={row.id}>
                    {row.cells.map((cell) => (
                      <TableCell key={cell.id}>
                        {getCellContent(cell, row.id)}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </DataTable>
      <Button className="mt-2" onClick={assignApplications}>
        Atribuir juri de desempate
      </Button>
    </Card>
  );
};

export default ApplicationTiebreakerPage;
