import {
  Button,
  Form,
  TextInput,
  Row,
  Column,
  ButtonSet,
  DatePicker,
  DatePickerInput,
  Loading,
  Dropdown,
  FileUploader,
} from "carbon-components-react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import http from "../../http-common";
import { SaveModel24 } from "@carbon/icons-react";
import React from "react";
import IFrameDocument from "../Home/Documents";
import httpMedia from "../../http-common-media";

import { projectSchema } from "../../services/SchemaService";
import { yupResolver } from "@hookform/resolvers/yup";

import CurrencyInput from "../../components/Input/CurrencyInput";

//import CurrencyInput from "react-currency-input-field";

const ProjectForm = ({ mode, url, projectId, clientid, employeeId }) => {
  const [formMode] = useState(mode);
  const [showSuccess, setShowSuccess] = useState(false);
  const currencyObj = {
    id: 0,
    value: 0,
    valueCoinType: "MZN",
    correspondedValue: 0,
    correspondedValueCoinType: "",

    project: { id: projectId },
    serviceId: 0,
  };
  const [currency, setCurrency] = useState(currencyObj);

  const [project, setProject] = useState({
    id: 0,
    name: "",
    goal: "",
    prevstartDate: "",
    startDate: "",
    prevendDate: "",
    endDate: "",
    typeOfProject: "",
    projectValue: "",
    coinType: "",
    client: { id: clientid },

    company: "",
    status: "",
  });

  const navigate = useNavigate();
  const [clients, setClient] = useState([]);

  const [type, setType] = useState([]);
  const [media, setMedia] = useState({});
  const [documents, setDocuments] = useState([]);
  const [intcointypes, setIntCointypes] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [projsstatus, setStatus] = useState([]);

  const [contract, setContract] = useState([]);
  const [isContractCliked, setContractClicked] = useState(
    mode === "UPDATE" ? true : false
  );

  const [isSeedClicked, setSeddClicked] = useState(
    mode === "UPDATE" ? true : false
  );

  const [showLoading, setShowLoading] = useState(false);
  const [isBtnDisabled, setisBtnDisabled] = useState(false);

  const getSchema = () => {
    switch (mode) {
      case "UPDATE":
        project.employee_id = projectId;
        return projectSchema;
      case "CREATE":
        return projectSchema;
      default:
        return projectSchema;
    }
  };

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({ resolver: yupResolver(getSchema()), mode: "onSubmit" });

  const params = useParams();

  const delay = (ms) => new Promise((res) => setTimeout(res, ms));

  async function onSubmit(data) {
    try {
      setisBtnDisabled(true);

      var result = null;

      if (formMode === "UPDATE") {
        result = await http.post(url, data);
        const PostCurrency = await http.post("/currencies", currency);

        toast.success("Operação realizada com sucesso!");
        await delay(2000); // breaking
        navigate("../actualizar-projecto/" + result?.data?.id);
      } else {
        result = await http.post(url, data);

        currency.projectId = result?.data.id;
        const PostCurrency = await http.post("/currencies", currency);

        toast.success("Operação realizada com sucesso!");
        await delay(2000); // breaking

        // to do: apos gravar ir para tela de editar projecto
        navigate("../actualizar-projecto/" + result?.data?.id);

        if (result?.data.Create_error_msg !== undefined) {
          toast.error(
            "Operação não realizada!: \n " + result?.data.Create_error_msg
          );
          setisBtnDisabled(false);
        } else {
          toast.success("Operação realizada com sucesso!");
          await delay(2000); // breaking
          navigate("../actualizar-projecto/" + result?.data?.id);
        }
      }
    } catch (error) {
      setisBtnDisabled(false);
      toast.error(error.response?.data.message);
    }
  }

  useEffect(() => {
    (async () => {
      let response = await getclients();
      if (response.success) {
        setClient(response.data);
      }
    })();
  }, []);

  const getclients = async () => {
    try {
      const response = await http.get("/clients");
      let items = response.data.map((e) => {
        return {
          id: e.id,
          text: e.firstName,
          children: e.children,
        };
      });
      return { success: true, data: items };
    } catch (error) {
      return { success: false };
    }
  };
  //fetch Type

  useEffect(() => {
    (async () => {
      let response = await fetchTypePorject();
      if (response.success) {
        setType(response.data);
      }
    })();
  }, []);

  const fetchTypePorject = async () => {
    try {
      const response = await http.get("/typeofproject");
      let items = response.data.map((e) => {
        return { id: e.id, text: e.description, children: e.children };
      });
      return { success: true, data: items };
    } catch (error) {
      return { success: false };
    }
  };

  //Gett Employess

  //fetch Company
  useEffect(() => {
    (async () => {
      let response = await fetchCompany();
      if (response.success) {
        setCompanies(response.data);
      }
    })();
  }, []);

  const fetchCompany = async () => {
    try {
      const response = await http.get("/company");
      let items = response.data.map((e) => {
        return { id: e.id, text: e.description, children: e.children };
      });
      return { success: true, data: items };
    } catch (error) {
      return { success: false };
    }
  };

  //Coin Type
  useEffect(() => {
    (async () => {
      let response = await fetchIntCoinType();
      if (response.success) {
        setIntCointypes(response.data);
      }
    })();
  }, []);

  const fetchIntCoinType = async () => {
    try {
      const response = await http.get("/cointype");
      let items = response.data.map((e) => {
        return { id: e.code, text: e.description, children: e.children };
      });
      return { success: true, data: items };
    } catch (error) {
      return { success: false };
    }
  };

  //Status
  useEffect(() => {
    (async () => {
      let response = await fetchStatus();
      if (response.success) {
        setStatus(response.data);
      }
    })();
  }, []);

  const fetchStatus = async () => {
    try {
      const response = await http.get("/projectstatus");
      let items = response.data.map((e) => {
        return { id: e.code, text: e.description, children: e.children };
      });
      return { success: true, data: items };
    } catch (error) {
      return { success: false };
    }
  };

  useEffect(() => {
    if (formMode === "UPDATE") {
      const fechcurrency = async () => {
        const coint_result = await http.get(`/currency/project/${projectId}`);
        const constCointFields = [
          "id",
          "value",
          "valueCoinType",
          "correspondedValue",
          "correspondedValueCoinType ",

          "projectId",
          "serviceId",
        ];
        constCointFields.forEach((field) => setValue(field, currency[field]));
        setCurrency(coint_result.data);
      };
      fechcurrency();
    }
  }, []);

  useEffect(() => {
    if (formMode === "UPDATE") {
      const fetchData = async () => {
        try {
          const result = await http.get(`/projects/${projectId}`);

          const fields = [
            "id",
            "name",
            "goal",

            "prevstartDate",
            "startDate",
            "prevendDate",
            "endDate",
            "typeOfProject",
            "company",
            "status",
          ];
          fields.forEach((field) => setValue(field, project[field]));
          setProject(result.data);
          console.log("Out data", result.data);
        } catch (error) {
          toast.error(error.response?.data.message);
        }
      };

      fetchData();
    }
    setShowSuccess(false);
  }, [formMode]);

  const getProject = async () => {
    const response = await http.get(`projects/client/${clientid}`);
    setClient(response.data);
  };
  useEffect(() => {
    reset(project);
  }, [project]);

  const setprevstartDate = (e) => {
    setValue("prevstartDate", e.target.value);
    project.prevstartDate = e.target.value;
  };
  const setstartDate = (e) => {
    setValue("startDate", e.target.value);
    project.startDate = e.target.value;
  };

  const setprevendDate = (e) => {
    setValue("prevendDate", e.target.value);
    project.prevendDate = e.target.value;
  };

  const setendDate = (e) => {
    setValue("endDate", e.target.value);
    project.endDate = e.target.value;
  };

  function onTypeChange(e) {
    project.typeOfProject = e.selectedItem.text;
    setValue("typeOfProject", e.selectedItem.text);
  }
  function onEmployeeChange(e) {
    project.employee_id = e.selectedItem.id;
    setValue("employee_id", e.selectedItem.id);
  }

  function onCointTypeChange(e) {
    currency.correspondedValueCoinType = e.selectedItem.text;
    setValue("correspondedValueCoinType", e.selectedItem.text);
  }

  function onCompanyChange(e) {
    project.company = e.selectedItem.text;
    setValue("company", e.selectedItem.text);
  }

  function onStatusChange(e) {
    project.status = e.selectedItem.text;
    setValue("status", e.selectedItem.text);
  }
  //On file change for contracts

  function handleChangeCoint(e) {
    const { name, value } = e.target;
    setCurrency((prevValue) => ({
      ...prevValue,
      [name]: value,
    }));

    console.log("c", currency.value);
  }
  const onFileChange = (event) => {
    const file = window.URL.createObjectURL(event.target.files[0]);
    const formData = new FormData();
    formData.append(
      "file",
      event.target.files[0],
      "Contract -" + " " + getValues("name") + "_" + event.target.files[0].name
    );
    setContract(formData);
    setContractClicked(true);
    setDocuments([...documents, formData]);
  };

  return (
    <>
      <>
        {formMode == "UPDATE" ? (
          <>
            <p>
              <strong>Cliente:</strong> {project.client.name}
            </p>
            <p>
              <strong>Empresa:</strong> {project.company}
            </p>
            <hr />
          </>
        ) : (
          <></>
        )}
      </>

      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Column sm={6} md={6} lg={6} className="required">
            <TextInput
              id="name"
              labelText="Nome do projecto"
              {...register("name")}
            />
            <p className="field-error">{errors.name?.message}</p>
          </Column>
          <Column sm={6} md={6} lg={6} className="required">
            <Dropdown
              id="typeOfProject"
              titleText="Tipo de Serviço"
              label={formMode == "UPDATE" ? project.typeOfProject : "Selecione"}
              items={type}
              onChange={onTypeChange}
              name="typeOfProject"
              itemToString={(item) => (item ? item.text : "")}
            />
            <p className="field-error">{errors.typeOfProject?.message}</p>
          </Column>
        </Row>
        <Row>
          <Column sm={6} md={6} lg={6}>
            {formMode === "UPDATE" ? (
              <DatePicker
                locale="pt"
                dateFormat="d/m/Y"
                datePickerType="single"
              >
                <DatePickerInput
                  style={{ width: "330px" }}
                  labelText="Data de previsão de início"
                  id="prevstartDate"
                  name="prevstartDate"
                  value={project.prevstartDate}
                  {...register("prevstartDate")}
                  onSelect={setprevstartDate}
                />
              </DatePicker>
            ) : (
              <DatePicker
                locale="pt"
                dateFormat="d/m/Y"
                datePickerType="single"
              >
                <DatePickerInput
                  style={{ width: "330px" }}
                  labelText="Data de previsão de início"
                  id="prevstartDate"
                  name="prevstartDate"
                  {...register("prevstartDate")}
                  onSelect={setprevstartDate}
                />
              </DatePicker>
            )}
          </Column>
          <Column sm={6} md={6} lg={6}>
            {formMode === "UPDATE" ? (
              <DatePicker
                locale="pt"
                dateFormat="d/m/Y"
                datePickerType="single"
              >
                <DatePickerInput
                  style={{ width: "330px" }}
                  labelText="Data de início"
                  id="startDate"
                  name="startDate"
                  value={project.startDate}
                  {...register("startDate")}
                  onSelect={setstartDate}
                />
              </DatePicker>
            ) : (
              <DatePicker
                locale="pt"
                dateFormat="d/m/Y"
                datePickerType="single"
              >
                <DatePickerInput
                  style={{ width: "330px" }}
                  labelText="Data de início"
                  id="startDate"
                  name="startDate"
                  {...register("startDate")}
                  onSelect={setstartDate}
                />
              </DatePicker>
            )}
          </Column>
        </Row>
        <Row>
          <Column sm={6} md={6} lg={6}>
            {formMode === "UPDATE" ? (
              <DatePicker
                locale="pt"
                dateFormat="d/m/Y"
                datePickerType="single"
              >
                <DatePickerInput
                  style={{ width: "330px" }}
                  labelText="Data de previsão de término"
                  id="prevendDate"
                  name="prevendDate"
                  value={project.prevendDate}
                  {...register("prevendDate")}
                  onSelect={setprevendDate}
                />
              </DatePicker>
            ) : (
              <DatePicker
                locale="pt"
                dateFormat="d/m/Y"
                datePickerType="single"
              >
                <DatePickerInput
                  style={{ width: "330px" }}
                  labelText="Data de previsão de término"
                  id="prevendDate"
                  name="prevendDate"
                  {...register("prevendDate")}
                  onSelect={setprevendDate}
                />
              </DatePicker>
            )}
          </Column>

          <Column sm={6} md={6} lg={6}>
            {formMode === "UPDATE" ? (
              <DatePicker
                locale="pt"
                dateFormat="d/m/Y"
                datePickerType="single"
              >
                <DatePickerInput
                  style={{ width: "330px" }}
                  labelText="Data de término"
                  id="endDate"
                  name="endDate"
                  value={project.endDate}
                  {...register("endDate")}
                  onSelect={setendDate}
                />
              </DatePicker>
            ) : (
              <DatePicker
                locale="pt"
                dateFormat="d/m/Y"
                datePickerType="single"
              >
                <DatePickerInput
                  style={{ width: "330px" }}
                  labelText="Data de término"
                  id="endDate"
                  name="endDate"
                  {...register("startDate")}
                  onSelect={setendDate}
                />
              </DatePicker>
            )}
          </Column>
        </Row>
        <Row>
          <Column sm={6} md={6} lg={6} className="required">
            <TextInput
              id="goal"
              labelText="Objecto do projecto"
              {...register("goal")}
            />
            <p className="field-error">{errors.goal?.message}</p>
          </Column>
          <Column sm={6} md={6} lg={6} className="required">
            <Dropdown
              id="company"
              titleText="Empresa"
              label={formMode == "UPDATE" ? project.company : "Selecione"}
              items={companies}
              onChange={onCompanyChange}
              name="company"
              itemToString={(item) => (item ? item.text : "")}
            />
            <p className="field-error">{errors.company?.message}</p>
          </Column>
        </Row>
        <Row>
          <Column sm={2} md={6} lg={2} className="required">
            <TextInput
              id="currencyProject.coinType"
              labelText="Moeda"
              value={"MZN"}
              disabled
            />
          </Column>
          <Column sm={4} md={6} lg={4} className="required">
            <TextInput
              labelText="Valor do projecto (MZN)"
              value={currency.value}
              name="value"
              onChange={handleChangeCoint}
            />
            <p className="field-error">{errors.currencyProject?.message}</p>
          </Column>
          <Column sm={4} md={4} lg={4}>
            <TextInput
              labelText="Valor correspondente em outra moeda"
              value={currency.correspondedValue}
              name="correspondedValue"
              onChange={handleChangeCoint}
            />
          </Column>
          <Column sm={2} md={2} lg={2}>
            <Dropdown
              titleText="Tipo de moeda"
              items={intcointypes}
              onChange={onCointTypeChange}
              label={
                formMode == "UPDATE"
                  ? currency.correspondedValueCoinType
                  : "Selecione"
              }
              itemToString={(item) => (item ? item.text : "")}
            ></Dropdown>
          </Column>
        </Row>
        <Row>
          <Column sm={6} md={6} lg={6}>
            <Dropdown
              id="status"
              titleText="Estado do Projecto"
              label={formMode == "UPDATE" ? project.status : "Selecione"}
              items={projsstatus}
              onChange={onStatusChange}
              name="status"
              itemToString={(item) => (item ? item.text : "")}
            ></Dropdown>
          </Column>
        </Row>
        <Row className="mt-2">
          <Column>
            <ButtonSet>
              <Button
                disabled={isBtnDisabled}
                type="submit"
                renderIcon={SaveModel24}
                iconDescription="Save"
              >
                Salvar
                {showLoading == true ? (
                  <Loading
                    description="Active loading indicator"
                    withOverlay={false}
                    small
                  />
                ) : (
                  ""
                )}
              </Button>
              <Button
                onClick={() =>
                  navigate("../update_client/" + project?.client?.id)
                }
              >
                Voltar para cliente
              </Button>
            </ButtonSet>
          </Column>
        </Row>
      </Form>
    </>
  );
};

export default ProjectForm;
