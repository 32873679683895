import React from "react";
import { useParams } from "react-router-dom";
import Card from "../../components/Card/Card";
import EmployeeForm from "./EmployeeForm";
import ManagementPage from "../Management/ManagementPage";

const UpdateEmployeePage = () => {
  const params = useParams();
  return (
    <>
      <Card title="Actualizar Funcionário">
        <EmployeeForm
          mode={"UPDATE"}
          url={"/employees/" + params.id}
          successMessage={"Funcionário registado com sucesso"}
          onSuccessNavigateTo={"../funcionarios/"}
          employeeId={params.id}
        />
      </Card>
 
    </>
  );
};

export default UpdateEmployeePage;
