import React from 'react'
import Card from '../../components/Card/Card'
import LicenseManagementForm from './LicenseManagementForm'

const CreateLicenseManagementPage = () => {
  return (
    <Card title="Registar Lincenças">
      <LicenseManagementForm
        mode={"CREATE"}
        url={"/licensemanagement"}
        successMessage={"Licensa registada com sucesso"}
        onSuccessNavigateTo={"../licensa"}
      />
    </Card>
  );
}

export default CreateLicenseManagementPage
