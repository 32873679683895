import { useState, useEffect } from "react";
import {
  TextInput,
  Row,
  Column,
  FileUploader,
  Modal,
} from "carbon-components-react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import http from "../../http-common";
import { toast } from "react-toastify";
import httpMedia from "../../http-common-media";

function AppModal({ url, title, setOpen, setClose, successMessage, mode }) {
  const [showSuccess, setShowSuccess] = useState(false);
  const [objDoc, setobjDoc] = useState({
    description: "",
    file: "",
  });

  const obj = {
    description: "",
    file: "",
  };

  const [contract, setContract] = useState([]);

  const [media, setMedia] = useState({});
  const [isSeedClicked, setSeddClicked] = useState(
    mode === "UPDATE" ? true : false
  );

  //const [showLoading, setShowLoading] = useState(false)
  const [showLoading, setShowLoading] = useState(false);
  const [isBtnDisabled, setisBtnDisabled] = useState(false);

  const { register, handleSubmit, getValues, reset } = useForm();
  const navigate = useNavigate();
  const params = useParams();

  const delay = (ms) => new Promise((res) => setTimeout(res, ms));

  const onSubmit = async (data) => {
    try {
      setisBtnDisabled(true);

      const responseMedia = await httpMedia.post(
        "media/upload/contract/" + params.id,
        media
      );

      toast.success("Documento gravado com sucesso");
      await delay(4000); // breaking
      navigate("../actualizar-projecto/" + params.id, { replace: true });
    } catch (error) {
      setisBtnDisabled(false);
      toast.error(error.response?.data.message);
    }
  };

  // usar a url para gravar os docs etc
  const [documents, setDocuments] = useState([]);

  const onFileChange = (event) => {
    const file = window.URL.createObjectURL(event.target.files[0]);
    const formData = new FormData();
    formData.append("file", event.target.files[0], event.target.files[0].name);
    setMedia(formData);
    setSeddClicked(true);
    const iframe = document.querySelector("iframe");
    if (iframe?.src) iframe.src = file;
  };

  return (
    <div>
      <Modal
        modalHeading={title}
        open={setOpen}
        onRequestClose={setClose}
        primaryButtonText="Gravar"
        secondaryButtonText="Cancelar"
        onRequestSubmit={handleSubmit(onSubmit)}
      >
        <div>
          <Row>
            <Column sm={6} md={6} lg={6} className="required">
              <TextInput
                id="description"
                labelText="Descrição do documento:"
                {...register("description")}
              />
            </Column>
          </Row>
          <Row>
            {/*  <FileUploaderDropContainer
                          accept={['.jpg', '.pdf', 'image/jpeg', 'image/png']}
                          labelText='Araste um documento ou clica aqui'
                          name=''
                          
                      /> */}

            <Column sm={6} md={6} lg={6} className="required">
              <FileUploader
                accept={[".jpg", ".pdf"]}
                buttonKind="tertiary"
                buttonLabel="carregar"
                filenameStatus="edit"
                labelDescription="O tamanho maximo é de 20MB"
                labelTitle="Adicionar documento"
                iconDescription="Adicionar o documento"
                id="file"
                {...register("file")}
                onChange={onFileChange}
              />
            </Column>
          </Row>
        </div>
      </Modal>
    </div>
  );
}

export default AppModal;
