import React, { useState, useEffect } from "react";
import Card from "../../components/Card/Card";
import { Form } from "carbon-components-react";
import {
  Row,
  Column,
  TextInput,
  Button,
  DataTable,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
  TableToolbar,
  TableToolbarContent,
  OverflowMenuItem,
  OverflowMenu,
} from "carbon-components-react";
import { Link, useNavigate, useLocation } from "react-router-dom";

import http from "../../http-common";
import { Add16, Search32, Edit24, Document16 } from "@carbon/icons-react";
import TablePagination from "../../components/Pagination/Pagination";
import { useForm } from "react-hook-form";
import jsPDF from "jspdf";

const tableHeaders = [
  {
    header: "Empresa",
    key: "company",
  },
  {
    header: "Departamento",
    key: "department",
  },
  {
    header: "Nome do Funcionario",
    key: "employeeName",
  },
  {
    header: "Data de admissao",
    key: "admissionDate",
  },
  {
    header: "Dias de ferias consoante a data de admissao",
    key: "daysAvalableForYear",
  },
  {
    header: "Dias gozados",
    key: "enjoyedDays",
  },
  {
    header: "Dias de ferias disponiveis",
    key: "availableDays",
  },
  {
    header: "Operações",
    key: "actions",
  },
];

const ManagementPage = ({ employeeId, employeeData }) => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [management, setManagement] = useState([]);
  const [count, setCount] = useState(0);
  const [size, setSize] = useState(20);
  const [isLoading, setIsLoading] = useState(true);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  //Text of navigation on an
  const LinkList = ({ url, url2, management }) => (
    <ul style={{ display: "flex" }}>
      <li>
        <Edit24
          onClick={() =>
            navigate(url, {
              state: { employee: management },
            })
          }
        />
      </li>
      &nbsp;&nbsp;&nbsp;
    </ul>
  );
  const featchData = async () => {
    try {
      const response = await http.get(
        "/absencemanagement/search/filter?size=" + size
      );
      getManagementData(response);
    } catch (error) {}
  };
  const getManagementData = (response) => {
    const data = response.data.content.map((management) => ({
      id: management.id,
      admissionDate: management.admissionDate,
      company: management.company,
      department: management.department,
      employeeName: management.employeeName,
      daysAvalableForYear: management.daysAvalableForYear,
      enjoyedDays: management.enjoyedDays,
      availableDays: management.availableDays,
      employee_id: management.employee_id,

      actions: (
        <LinkList
          url={"../actualizar-gestao-de-ferias/" + management.id}
          management={management}
        />
      ),
    }));

    setManagement(data);
    console.log("result", data);

    setCount(response.data.totalElements);
  };

  const onPageChange = async ({ page, pageSize }) => {
    try {
      var pag = page - 1;

      const response = await http.get(
        "/absencemanagement/search/filter?page=" + pag + "&size=" + pageSize
      );
      getManagementData(response);
    } catch (error) {}
  };

  useEffect(() => {
    featchData();

    console.log("Id EMployee", employeeId);
  }, []);

  const onSearch = async (data) => {
    try {
      const response = await http.get(
        `/absencemanagement/search/filter?daysAvalableForYear=${data.daysAvalableForYear}`
      );
      getManagementData(response);
      console.log("Visual", response.data);
    } catch (error) {}
  };

  //Export documents
  const exportPDF = () => {
    console.log("TEST");
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 200;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = "Plano de ferias";
    const headers = [
      [
        "Nome do Funcionario",
        "Dias de ferias consoante a data de admissao",
        "Dias gozados",
        "Dias de ferias disponiveis",
      ],
    ];
    const date = new Date().toLocaleDateString();
    const data = management.map((st) => [
      //st.firstName  ,
      st.daysAvalableForYear,
      st.enjoyedDays,
      st.availableDays,
    ]);

    let content = {
      startY: 50,
      head: headers,
      body: data,
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save(`Plano de ferias.pdf`);
  };

  const tableActions = (management) => {
    return (
      <OverflowMenu flipped>
        <OverflowMenuItem
          key="optionOne"
          itemText="Edit"
          onClick={() =>
            navigate("../actualizar-gestao-de-ferias/" + management.id)
          }
        />
      </OverflowMenu>
    );
  };

  return (
    <Card title="Plano de ferias">
      <div className="mb-2 form-buttons">
        <p></p>
        <Button
          kind="ghost"
          type="button"
          onClick={() =>
            navigate("../registar-gestao-de-ferias/" + employeeId, {
              state: { employee: employeeData },
            })
          }
          renderIcon={Add16}
        >
          Registar
        </Button>
      </div>

      <DataTable
        rows={management}
        headers={tableHeaders}
        isSortable
        useZebraStyles
      >
        {({ rows, headers, getHeaderProps, getTableProps }) => (
          <TableContainer>
            <TableToolbar aria-label="data table toolbar">
              <TableToolbarContent>
                <Form
                  className="data-table-search-form"
                  onSubmit={handleSubmit(onSearch)}
                >
                  <Row>
                    <Column sm={6} md={6} lg={5}>
                      <TextInput
                        id="name"
                        labelText=""
                        placeholder=""
                        {...register("employee")}
                      />
                    </Column>
                  </Row>
                  <Button type="submit" renderIcon={Search32}>
                    Search
                  </Button>
                  <Button
                    style={{ marginLeft: "0.1rem" }}
                    onClick={exportPDF}
                    renderIcon={Document16}
                  >
                    Exportar
                  </Button>
                </Form>
              </TableToolbarContent>
            </TableToolbar>
            <Table {...getTableProps()}>
              <TableHead>
                <TableRow>
                  {headers.map((header) => (
                    <TableHeader {...getHeaderProps({ header })}>
                      {header.header}
                    </TableHeader>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow key={row.id}>
                    {row.cells.map((cell) => (
                      <TableCell key={cell.id}>
                        {cell.value?.content ?? cell.value}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </DataTable>
      <TablePagination
        currentPageSize={size}
        onPageChange={onPageChange}
        totalItems={count}
      />
    </Card>
  );
};
export default ManagementPage;
