import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate, useParams } from "react-router-dom";
import Card from "../../components/Card/Card";
import CompetitorDataForm from "../../components/Forms/CompetitorDataForm";
import useAuth from "../../hooks/useAuth";
import {
  submitApplicationSchema,
  submitBusinessProfileSchema,
} from "../../services/SchemaService";
import ExecutiveSummaryForm from "../../components/Forms/ExecutiveSummaryForm";
import BusinessProfileForm from "../../components/Forms/BusinessProfileForm";
import BusinessIdeaForm from "../../components/Forms/BusinessIdeaForm";
import useApplication from "../../hooks/useApplication";
import { Form } from "carbon-components-react";
import SubmitButton from "../../components/Buttons/SubmitButton";
import CancelButton from "../../components/Buttons/CancelButton";
import { ButtonSet } from "carbon-components-react";
import { Edit24 } from "@carbon/icons-react";
import { SendAlt24 } from "@carbon/icons-react";
import http from "../../http-common";
import { toast } from "react-toastify";
import useGet from "../../hooks/useGet";

const ViewApplicationPage = () => {
  const params = useParams();
  const [uuid] = useState(params.uuid);
  const { application } = useApplication(uuid);
  const { user, isCompetitor, isJury } = useAuth();
  const [hasBusinessCurrently, setHasBusinessCurrently] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(true);
  const [scored, setScored] = useState(false);
  const [canScore, setCanScore] = useState(false);

  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(
      application.hasBusinessCurrently !== null &&
        application.hasBusinessCurrently === "Sim"
        ? submitApplicationSchema.concat(submitBusinessProfileSchema)
        : submitApplicationSchema
    ),
  });

  const onSubmit = async (data) => {
    try {
      const response = await http.post("/applications/submit/" + uuid);
      setIsOpen(false);
      toast.success("Candidatura submetida com sucesso. Obrigado e boa sorte!");
    } catch (error) {
      toast.error(error?.response?.data.message);
    }
  };

  const checkCanScore = async () => {
    try {
      const response = await http.get(
        `/applications/evaluation/can-score?username=${user.username}&uuid=${uuid}`
      );
      setCanScore(response.data);
    } catch (error) {}
  };

  useEffect(() => {
    reset(application);
    setIsOpen(application.currentStageCode === 1);
    setScored(application.currentStageCode === 3);
    setHasBusinessCurrently(application.hasBusinessCurrently);
    setLoading(false);
    checkCanScore();
  }, [uuid, application]);

  return (
    <>
      <Card title="Candidatura">
        <Form onSubmit={handleSubmit(onSubmit)}>
          {!isJury && (
            <CompetitorDataForm
              register={register}
              control={control}
              errors={errors}
              disabled={true}
            />
          )}
          <ExecutiveSummaryForm
            register={register}
            errors={errors}
            disabled={true}
            hasBusinessCurrently={hasBusinessCurrently}
          />
          {hasBusinessCurrently !== null && hasBusinessCurrently === "Sim" && (
            <BusinessProfileForm
              register={register}
              control={control}
              errors={errors}
              disabled={true}
              application={application}
            />
          )}
          <BusinessIdeaForm
            register={register}
            errors={errors}
            disabled={true}
          />
          <div className="form-buttons">
            <ButtonSet>
              {isJury && canScore && (
                <SubmitButton
                  type="button"
                  label="Pontuar"
                  icon={Edit24}
                  onClick={() => navigate("../pontuar-candidatura/" + uuid)}
                />
              )}
              {isOpen && (
                <SubmitButton
                  type="button"
                  label="Editar"
                  icon={Edit24}
                  onClick={() => navigate("../editar-candidatura/" + uuid)}
                />
              )}
              <CancelButton
                navigateTo={
                  isCompetitor
                    ? "../inicio"
                    : isJury
                    ? "../avaliacao-de-candidaturas"
                    : "../candidaturas"
                }
              />
            </ButtonSet>
            {isOpen && (
              <ButtonSet>
                <SubmitButton
                  label="Submeter"
                  icon={SendAlt24}
                  className="emprega-bg-orange"
                />
              </ButtonSet>
            )}
          </div>
        </Form>
      </Card>
    </>
  );
};

export default ViewApplicationPage;
