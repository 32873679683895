import React from "react";

import { useParams } from "react-router-dom";
import Card from "../../components/Card/Card";
import ConfigurationForm from "./ConfigurationForm";

const UpdateConfigurationPage = () => {
  const params = useParams();
  return (
    <>
      <ConfigurationForm
        mode={"UPDATE"}
        url={"/updateconfiguration/" + params.id}
        onSuccessNavigateTo={"../configuration-update" + params.id}
      />
    </>
  );
};

export default UpdateConfigurationPage;
