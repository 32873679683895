import {
  Button,
  Form,
  TextInput,
  Row,
  Column,
  ButtonSet,
  Loading,
} from "carbon-components-react";
import { useEffect, useState, useContext } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import http from "../../http-common";
import { Cost, SaveModel24 } from "@carbon/icons-react";
import React from "react";

import useGet from "../../hooks/useGet";
import { getValue } from "@testing-library/user-event/dist/utils";

const ManagementForm = ({
  disabled,
  mode,
  url,
  managementId,
  employeeId,
  date,
  employeeData,
}) => {
  const [formMode] = useState(mode);
  const [showSuccess, setShowSuccess] = useState(false);

  const [management, setManagement] = useState({
    daysAvalableForYear: "",
    enjoyedDays: "",
    daysToEnjoy: "",
    availableDays: "",
    date: "",
    employeeName: "",
    employee: {
      id: employeeId,
      firstName: "",
      surName: "",
      company: "",
      department: "",
      admissionDate: "",
    },
  });

  const [showLoading, setShowLoading] = useState(false);
  const [isBtnDisabled, setisBtnDisabled] = useState(false);
  const [company, setCompany] = useState([]);
  const [department, setDepartment] = useState([]);
  const [employee, setEmployees] = useState([]);
  const [admissionDate, SetAdmissionDate] = useState(date);
  const [availableDays, setavailableDays] = useState([]);

  const [enjoyedDays, setEnjoyedDays] = useState([]);
  const [daysAvalableForYear, setdaysAvalableForYear] = useState([]);
  const [actutal, setActual] = useState("12/31/2023");

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    control,
    formState: { errors },
  } = useForm({ mode: "onSubmit" });
  const navigate = useNavigate();
  const params = useParams();

  const { state } = useLocation();

  const delay = (ms) => new Promise((res) => setTimeout(res, ms));

  async function onSubmit(data) {
    try {
      setisBtnDisabled(true);

      var _data = null;

      if (formMode === "UPDATE") {
        _data = await http.post(url, {
          // employee: { id: `${employeeId}` },
          daysToEnjoy: `${data.daysToEnjoy}`,
          enjoyedDays:
            parseInt(`${data.enjoyedDays}`) + parseInt(`${data.daysToEnjoy}`),

          availableDays: parseInt(
            parseInt(`${data.daysAvalableForYear}`) -
              parseInt(
                parseInt(`${data.enjoyedDays}`) +
                  parseInt(`${data.daysToEnjoy}`)
              )
          ),
        });
        toast.success("Operação realizada com sucesso!");
        await delay(2000); // breaking
      } else {
        _data = await http.post(url, {
          managementId: `${params.managementId}`,
          employee: { id: `${employeeId}` },
          company: `${state.employee.company}`,
          department: `${state.employee.department}`,
          admissionDate: `${state.employee.admissionDate}`,
          daysAvalableForYear: `${daysAvalableForYear}`,
          daysToEnjoy: `${data.daysToEnjoy}`,
          availableDays: `${data.availableDays}`,
          employeeName:
            `${state.employee.firstName}` + `${state.employee.surName}`,

          enjoyedDays: `${data.daysToEnjoy}`,
          availableDays: `${daysAvalableForYear}` - `${data.daysToEnjoy}`,
        });
      }

      //Add the new value of enjoyed days
      // enjoyedDays = management.daysToEnjoy;
      // console.log("New enjoyed days", enjoyedDays);

      toast.success("Operação realizada com sucesso!");
      await delay(4000); // breaking

      navigate("../consultar-ferias");
    } catch (error) {
      setisBtnDisabled(false);
      toast.error(error.response?.data.message);
    }
  }

  //Text of navigation on outher
  useEffect(() => {
    if (formMode === "CREATE") {
      const fetchData = async () => {
        try {
          const fields = [
            "id",
            "firstName",
            "company",
            "department",
            "admissionDate",
          ];
          employeeData.forEach((field) => setValue(field, management[field]));
          setEmployees(employeeData);
        } catch (error) {
          toast.error(error.response?.data.message);
        }
      };

      fetchData();
    }
    setShowSuccess(false);
  }, [formMode]);

  //Old
  useEffect(() => {
    if (formMode === "UPDATE") {
      const fetchData = async () => {
        try {
          const result = await http.get(`/absencemanagement/${managementId}`);
          const fields = [
            "id",
            "company",
            "department",
            "admissionDate",
            "enjoyedDays",
            "availableDays",
            "employeeName",
          ];
          fields.forEach((field) => setValue(field, employee[field]));
          setManagement(result.data);
        } catch (error) {
          toast.error(error.response?.data.message);
        }
      };

      fetchData();
    }
    setShowSuccess(false);
  }, [formMode]);

  useEffect(() => {
    reset(management);
  }, [management]);

  //Employee
  useEffect(() => {
    (async () => {
      let response = await fetchEmployee();
      if (response.success) {
        setEmployees(response.data);
      }
    })();
  }, []);

  const fetchEmployee = async () => {
    try {
      const response = await http.get(`/employees/${employeeId}`);
      let items = response.data.map((e) => {
        return {
          idEmployee: e.id,
          company: e.company,
          department: e.department,
          name: e.firstName + "   " + e.surName,
          date: e.admissionDate,
          children: e.children,
        };
      });
      return { success: true, data: items };
    } catch (error) {
      return { success: false };
    }
  };

  function onEmployeeChange(e) {
    try {
      console.log("denteo");
      setavailableDays(null);

      SetAdmissionDate(state.employee.admissionDate);
      setValue("admissionDate");
      var newac = new Date(actutal);
      var newdate = new Date(admissionDate);

      var diff = newac - newdate;

      var diffmilyear = 1000 * 60 * 60 * 24 * 365.25;

      var diffyear = Math.floor(diff / diffmilyear);

      console.log("Md");
      if (diffyear > 3) {
        setdaysAvalableForYear(30);
        // setValue('daysAvalableForYear', 30)

        setValue("availableDays", 30 - management.enjoyedDays);
        setavailableDays(30 - management.enjoyedDays);
      } else if (diffyear > 2) {
        setdaysAvalableForYear(24);
        //setValue('daysAvalableForYear', 24)

        setValue("availableDays", 24 - management.enjoyedDays);
        setavailableDays(24 - management.enjoyedDays);
      } else if (diffyear >= 0) {
        setdaysAvalableForYear(12);
        //setValue('daysAvalableForYear', 12)

        setValue("availableDays", 12 - management.enjoyedDays);
        setavailableDays(12 - management.enjoyedDays);
      } else {
        setdaysAvalableForYear(0);
        setValue("availableDays", 0);
        setavailableDays(0);
      }
    } catch (error) {
      toast.error(error.response?.data.message);
    }
    // else console.Console("errro ao calculasos disdisponiceis");
  }

  function calcAvailableDays() {
    console.log("asasas");

    var daysEn = management.daysToEnjoy;
    console.log("Days to enjoy", daysEn);

    enjoyedDays = management.daysToEnjoy;
    console.log("enjoyedDays", enjoyedDays);

    var availa = availableDays - enjoyedDays;

    console.log("Avalila", availa);

    setavailableDays(availa);
  }

  const featchData = async () => {
    try {
      const response = await http.get("/absencemanagement");
      getManagementData(response);
    } catch (error) {}
  };
  const getManagementData = (response) => {
    const data = response.data.content.map((management) => ({
      id: management.id,
      admissionDate: management.admissionDate,
      company: management.company,
      department: management.department,
      employeeName: management.employeeName,
      daysAvalableForYear: management.daysAvalableForYear,
      enjoyedDays: management.enjoyedDays,
      availableDays: management.availableDays,
      employee_id: management.employee_id,
    }));

    setManagement(data);
    console.log("result", data);
  };

  useEffect(() => {
    featchData();
  });

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Row>
        <Column sm={4} md={6} lg={4} className="required">
          <TextInput
            id="employee.company"
            labelText="Empresa"
            value={state.employee.company}
            disabled
          />
        </Column>
        <Column sm={4} md={6} lg={4} className="required">
          <TextInput
            id="employee.department"
            labelText="Departamento"
            value={state.employee.department}
            disabled
          />
        </Column>

        {formMode === "CREATE" ? (
          <Column sm={4} md={6} lg={4} className="required">
            <TextInput
              id="employee.firstName"
              labelText="Funcionario"
              value={state.employee.firstName + "" + state.employee.surName}
              disabled
            />
          </Column>
        ) : (
          ""
        )}
        <Column sm={4} md={6} lg={4} className="required">
          <TextInput
            id="admissionDate"
            labelText="Data de admissao"
            value={state.employee.admissionDate}
            disabled
          />
        </Column>
        {formMode === "CREATE" ? (
          <Column sm={4} md={6} lg={4} className="required">
            <TextInput
              id="daysAvalableForYear"
              labelText="Dias de ferias disponiveis consoante a data de admissao"
              value={daysAvalableForYear}
              disabled
            />
          </Column>
        ) : (
          ""
        )}
        {formMode === "UPDATE" ? (
          <Column sm={4} md={6} lg={4} className="required">
            <TextInput
              labelText="Dias gozadas"
              value={management.enjoyedDays}
              disabled
            />
          </Column>
        ) : (
          ""
        )}

        {formMode === "CREATE" ? (
          <Column sm={4} md={6} lg={4} className="required">
            <TextInput
              labelText="Dias de ferias disponiveis"
              value={availableDays ?? getValue("availableDays")}
              onChange={(e) => {
                e = "" + availableDays;
              }}
              disabled
            />
          </Column>
        ) : (
          ""
        )}
        {formMode === "UPDATE" ? (
          <Column sm={4} md={6} lg={4} className="required">
            <TextInput
              labelText="Dias de ferias disponiveis"
              value={management.availableDays}
              disabled
            />
          </Column>
        ) : (
          ""
        )}
        <Column sm={4} md={6} lg={4} className="required">
          <TextInput
            id="daysToEnjoy"
            labelText="Dias a gozar"
            {...register("daysToEnjoy")}
            maxLength={9}
          />
          <p className="field-error">{errors.daysToEnjoy?.message}</p>
        </Column>
      </Row>
      <Row className="mt-2">
        <Column sm={12} md={6} lg={6}>
          <ButtonSet>
            <Button
              disabled={isBtnDisabled}
              type="submit"
              renderIcon={SaveModel24}
              iconDescription="Save"
            >
              Salvar
              {showLoading == true ? (
                <Loading
                  description="Active loading indicator"
                  withOverlay={false}
                  small
                />
              ) : (
                ""
              )}
            </Button>
            {formMode === "CREATE" ? (
              <Button onClick={onEmployeeChange}>
                Calcular Dias de ferias
              </Button>
            ) : (
              ""
            )}
            <Button onClick={() => navigate("../inicio")}>Voltar</Button>
          </ButtonSet>
        </Column>
      </Row>
    </Form>
  );
};

export default ManagementForm;
