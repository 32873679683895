import { useEffect, useState } from "react";
import {
  Button,
  Form,
  TextInput,
  Row,
  Column,
  ButtonSet,
  DatePicker,
  DatePickerInput,
  Loading,
  Dropdown,
  FileUploader,
} from "carbon-components-react";
import http from "../../../http-common";
import React from "react";
import Card from "../../../components/Card/Card";
import * as XLSX from 'xlsx';
import { DocumentDownload16 } from "@carbon/icons-react";
import { render } from 'react-dom';



const ResumePage = () => {
  var style = {
    btnsStyle: {
      marginBottom: "5px",
    },
  };


  const [activeLoad, SetactiveLoad] = useState({ loadValue: false, btnDisable: false })


  const employeeByProject = async () => {

    const response = await http.get("/countprojectbyemployee");
    const response_dto = response.data.map((x) => ({
      "Projecto": x.description,
      "Numero de funcionários": x.value,
    }))
    handleOnExport(response_dto, "Total De Funcionários Por Projecto");

  }


  const serviceStatusList = async () => {

    const response = await http.get("/serviceStatusList");
    const response_dto = response.data.map((x) => ({
      "Projecto": x.project_name,
      "Cliente": x.client,
      "Serviço": x.service,
      "Estado do Serviço": x.service_status
    }))
    handleOnExport(response_dto, "Listagem - Estados Dos Serviços Dos Projectos Activos");

  }

  const employeeDepartmentList = async () => {

    const response = await http.get("/employeeDepartmentList");
    const response_dto = response.data.map((x) => ({
      "Funcionárop": x.employee,
      "Genero": x.gender,
      "Departamento": x.department

    }))

    handleOnExport(response_dto, "Listagem - Funcionário Por Departamento");

  }


  const exportCountprojectbyemployee = async (report) => {

    SetactiveLoad(prevValue => ({

      ...prevValue,
      loadValue: true,
      btnDisable: true,

    }));

    switch (report) {
      case "serviceStatusList":
        await serviceStatusList();
        break;
      case "EmployeeByProject":
        await employeeByProject();
      case "employeeDepartmentList":
        await employeeDepartmentList();
        break;
      default:
      // code block
    }


    SetactiveLoad(prevValue => ({

      ...prevValue,
      loadValue: false,
      btnDisable: false,

    }));

  }


  const handleOnExport = async (sheetData, reportName) => {


    console.log("sheetData", sheetData)
    console.log("reportName", reportName)

    var wb = XLSX.utils.book_new(),
      ws = XLSX.utils.json_to_sheet(sheetData);

    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    XLSX.writeFile(wb, reportName + ".xlsx");

  }

  return (
    <>

      <Loading
        description="Active loading indicator"
        withOverlay={true}
        active={activeLoad.loadValue}
      />

      <Card title="Relatórios">



        <Button
          disabled={activeLoad.btnDisable}
          style={style.btnsStyle}
          type="submit"
          renderIcon={DocumentDownload16}
          iconDescription="Save"
          title="Listagem - Funcionário Por Departamento  "
          onClick={(e) => exportCountprojectbyemployee("employeeDepartmentList")}
        >
          Listagem - Funcionário Por Departamento  

        </Button>

        <br></br>
        <Button
          disabled={activeLoad.btnDisable}
          style={style.btnsStyle}
          type="submit"
          renderIcon={DocumentDownload16}
          iconDescription="Save"
          title="Estados Dos Serviços Dos Projectos Activos   "
          onClick={(e) => exportCountprojectbyemployee("serviceStatusList")}
        >
          Listagem - Estados Dos Serviços Dos Projectos Activos  


        </Button>

        <br></br>

        <Button
          disabled={activeLoad.btnDisable}
          style={style.btnsStyle}
          type="submit"
          renderIcon={DocumentDownload16}
          iconDescription="Save"
          title="Total De Funcionários Por Projecto   "
          onClick={(e) => exportCountprojectbyemployee("EmployeeByProject")}
        >
          Resumo - Total De Funcionários Por Projecto  


        </Button>
 
 
        <br></br>
 
      </Card>


    </>
  );
};

export default ResumePage;
