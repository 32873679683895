import React, { useState, useEffect } from "react";
import Card from "../../components/Card/Card";
import { Form } from "carbon-components-react";
import {
  Row,
  Column,
  TextInput,
  Button,
  DataTable,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
  TableToolbar,
  TableToolbarContent,
  OverflowMenuItem,
  OverflowMenu,
} from "carbon-components-react";
import { Link, useNavigate } from "react-router-dom";
import {
  Add16,
  Search32,
  DataView24,
  Edit24,
  TrashCan24,
} from "@carbon/icons-react";
import CustomTable from "../../components/custom-table/custom-table";
import http from "../../http-common";
import TablePagination from "../../components/Pagination/Pagination";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import useGet from "../../hooks/useGet";

const tableHeaders = [
  {
    header: "Nome",
    key: "name",
  },
  {
    header: "Endereço",
    key: "address",
  },
  {
    header: "NUIT",
    key: "nuit",
  },
  {
    header: "Telefone",
    key: "phoneNumber",
  },
  {
    header: "Telefone opcional",
    key: "faxNumber",
  },
  {
    header: "Email",
    key: "email",
  },
  {
    header: "Website",
    key: "website",
  },
  {
    header: "Operações",
    key: "actions",
  },
];

const LinkList = ({ url, url2 }) => (
  <ul style={{ display: "flex" }}>
    <li>
      <Link to={url} title="Editar">
        <Edit24 />
      </Link>
    </li>
    &nbsp;&nbsp;&nbsp;
  </ul>
);

const ProviderPage = () => {
  const navigate = useNavigate();

  const [provider, setProvider] = useState([]);
  const [count, setCount] = useState(0);
  const [size, setSize] = useState(20);

  const [isLoading, setIsLoading] = useState(true);
  const [locations, setLocations] = useState([]);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const featchData = async () => {
    try {
      const response = await http.get("/provider/search/filter?size=" + size);
      getProviderData(response);
    } catch (error) {}
  };
  const getProviderData = (response) => {
    const data = response.data.content.map((provider) => ({
      id: provider.id,
      name: provider.name,
      address: provider.address,
      nuit: provider.nuit,
      email: provider.email,
      phoneNumber: provider.phoneNumber,
      faxNumber: provider.faxNumber,
      website: provider.website,

      actions: <LinkList url={"../actualizar-fornecedor/" + provider.id} />,
    }));
    setProvider(data);
    setCount(response.data.totalElements);
  };

  const onPageChange = async ({ page, pageSize }) => {
    try {
      var pag = page - 1;
      const response = await http.get(
        "/provider/search/filter?page=" + pag + "&size=" + pageSize
      );
      getProviderData(response);
    } catch (error) {}
  };

  const onSearch = async (data) => {
    try {
      const response = await http.get(
        `/provider/search/filter?name=${data.name}`
      );
      getProviderData(response);
    } catch (error) {}
  };

  //  Location

  const getAllLocations = async () => {
    const response = await http.get("/locations");
    setLocations(response.data);
    setIsLoading(false);
  };

  useEffect(() => {
    getAllLocations();
    featchData();
  }, []);

  return (
    <Card title="Consultar Fornecedor">
      <div className="mb-2 form-buttons">
        <p></p>
        <Button
          kind="ghost"
          type="button"
          onClick={() => navigate("../registar-fornecedor")}
          renderIcon={Add16}
        >
          Registar Fornecedor
        </Button>
      </div>

      <DataTable
        rows={provider}
        headers={tableHeaders}
        isSortable
        useZebraStyles
      >
        {({ rows, headers, getHeaderProps, getTableProps }) => (
          <TableContainer>
            <TableToolbar aria-label="data table toolbar">
              <TableToolbarContent>
                <Form
                  className="data-table-search-form"
                  onSubmit={handleSubmit(onSearch)}
                >
                  <Row>
                    <Column sm={6} md={6} lg={5}>
                      <TextInput
                        id="name"
                        labelText=""
                        placeholder="Name"
                        {...register("name")}
                      />
                    </Column>{" "}
                    <Column sm={6} md={6} lg={5}>
                      <TextInput
                        id="nuit"
                        labelText=""
                        placeholder="Nuit"
                        {...register("nuit")}
                      />
                    </Column>
                  </Row>
                  <Button type="submit" renderIcon={Search32}>
                    Search
                  </Button>
                </Form>
              </TableToolbarContent>
            </TableToolbar>
            <Table {...getTableProps()}>
              <TableHead>
                <TableRow>
                  {headers.map((header) => (
                    <TableHeader {...getHeaderProps({ header })}>
                      {header.header}
                    </TableHeader>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow key={row.id}>
                    {row.cells.map((cell) => (
                      <TableCell key={cell.id}>
                        {cell.value?.content ?? cell.value}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </DataTable>
      <TablePagination
        currentPageSize={size}
        onPageChange={onPageChange}
        totalItems={count}
      />
    </Card>
  );
};
export default ProviderPage;
