import React from "react";
import http from "../../http-common";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Card from "../../components/Card/Card";
import ServiceEmployeePage from "./ServiceEmployeePage";
import ServiceForm from "./ServiceForm";

import { Button } from "carbon-components-react";
import AppActivityModal from "../Application/AppActivityModal";
import IFrameDocument from "../Home/Documents";

const UpadateClientPage = () => {
  const params = useParams();
  const [open, setOpen] = useState(false);
  const [openDocs, setOpenDocs] = useState(false);
  const [client, setClientId] = useState();

  const [mediaList, setMediaList] = useState([]);

  const closeModal = () => {
    setOpen(false);
  };
  const closeModalOpnDcs = () => {
    setOpenDocs(false);
  };

  async function readClientId() {
    const result = await http.get(`/projects/${params.id}`);

    setClientId(result.data?.client?.id);
  }

  //View Docs

  const viewDocuments = async () => {
    const response = await http
      .get("/media/contract/service/" + params.id)
      .then((response) => {
        setMediaList(response.data);
        console.log("File", response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    viewDocuments();
  }, []);

  return (
    <>
      <Card title="Actualizar Actividade">
        <ServiceForm
          mode={"UPDATE"}
          url={"/services/" + params.id}
          successMessage={"Actividade Actualizado com sucesso"}
          onSuccessNavigateTo={"../services"}
          serviceId={params.id}
        />
      </Card>
      {/* <Card>
        <ServiceEmployeePage serviceId={params.id} />
      </Card> */}

      <Card>
        <Button onClick={() => setOpen(true)}>Carregar documentos</Button>
        <AppActivityModal
          mode={"UPDATE"}
          url={"/upload/contract/" + params.id}
          setClose={closeModal}
          setOpen={open}
          title="Upload de Documentos do projecto"
        />
      </Card>
      <Card title="Visualizar documentos">
        {mediaList.map((document) => (
          <div className="cds--file__container">
            <br />
            <IFrameDocument
              mediaId={document.id}
              documentName={" " + document.name.toString().split("-")[0]}
            />
          </div>
        ))}
      </Card>
    </>
  );
};

export default UpadateClientPage;
