import {
    Button,
    Form,
    TextInput,
    Row,
    Column,
    ButtonSet,
    DatePicker,
    DatePickerInput,
    Loading,
    Dropdown,
    FileUploader,
} from 'carbon-components-react'
import React from "react";
import Card from "../../../components/Card/Card";
import { DocumentDownload16 } from '@carbon/icons-react'
 

const ListingPage = () => {


    var style = {
        btnsStyle: {
            marginBottom: '5px',

        },
    }


const exdata =[
    
    {"col1": "data"},
    {"col1": "data"},
    {"col1": "data"},
    {"col1": "data"},
    {"col1": "data"},

]

    return (
        <Card title="Relatórios - Listagens" >


         

          {/*   <Button

                style={style.btnsStyle}
                type="submit"
                renderIcon={DocumentDownload16}
                iconDescription="Save"
                title='Total Tarefas feitas Por Projecto'

            >

            </Button>

            <br></br>

            <Button

                style={style.btnsStyle}
                type="submit"
                renderIcon={DocumentDownload16}
                iconDescription="Save"
                title='Total Tarefas feitas Por Projecto'

            >
                Listagem - Total Tarefas feitas Por Projecto
            </Button>

            <br></br>

            <Button

                type="submit"
                renderIcon={DocumentDownload16}
                iconDescription="Save"
                title='Total Tarefas feitas Por Projecto'

            >
                Listagem - Total Tarefas feitas Por Projecto
            </Button> */}


        </Card>
    );
};

export default ListingPage;