import {
  Button,
  DataTable,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
  OverflowMenuItem,
  OverflowMenu,
} from 'carbon-components-react'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Link, useNavigate } from 'react-router-dom'
import http from '../../http-common'
import { Add16, Edit24 } from '@carbon/icons-react'
import Card from '../../components/Card/Card'
import TablePagination from '../../components/Pagination/Pagination'

const tableHeaders = [
  {
    header: 'Nome',
    key: 'name',
  },
  {
    header: 'Apelido',
    key: 'surName',
  },
  {
    header: 'Cargo',
    key: 'position',
  },
  {
    header: 'Contacto na Ologa',
    key: 'contactOloga',
  },
  {
    header: 'Email',
    key: 'email',
  },
  {
    header: 'Email Opcional',
    key: 'opcionalEmail',
  },
  {
    header: 'Numero de telefone',
    key: 'phoneNumber',
  },
  {
    header: 'Telefone opcional',
    key: 'faxNumber',
  },
  {
    header: 'Operações',
    key: 'actions',
  },
]

const LinkList = ({ url, url2 }) => (
  <ul style={{ display: 'flex' }}>
    <li>
      <Link to={url} title="Editar">
        <Edit24 />
      </Link>
    </li>
    &nbsp;&nbsp;&nbsp;
  </ul>
)

const ClientContactPage = ({ clientId }) => {
  const [contact, setContact] = useState({
    description: '',
  })

  const [contacts, setContacts] = useState([])
  const [count, setCount] = useState(0)
  const [size, setSize] = useState(20)
  const [type, setType] = useState([])

  const {
    formState: { errors },
  } = useForm()

  const navigate = useNavigate()

  const featchData = async () => {
    try {
      const response = await http.get(`/contact/client/${clientId}`)
      getContactData(response)
    } catch (error) {}
  }

  const getContactData = (response) => {
    try {
      const data = response?.data?.map((contact) => ({
        id: contact.id,
        name: contact.name,
        surName: contact.surName,
        position: contact.position,
        contactOloga: contact.contactOloga,
        email: contact.email,
        opcionalEmail: contact.opcionalEmail,
        phoneNumber: contact.phoneNumber,
        faxNumber: contact.faxNumber,

        actions: <LinkList url={'../actualizar-contacto/' + contact.id} />,
      }))
      setContacts(data)
      setCount(response.data.totalElements)
    } catch (error) {}
  }

  const onPageChange = async ({ page, pageSize }) => {
    try {
      var pag = page - 1
      const response = await http.get(`/contact/client/${clientId}`)
      getContactData(response)
    } catch (error) {}
  }

  useEffect(() => {
    featchData()
  }, [])

  const tableActions = (contact) => {
    return (
      <OverflowMenu flipped>
        <OverflowMenuItem
          key="optionOne"
          itemText="Edit"
          onClick={() => navigate('../actualizar-contacto/' + contact.id)}
        />
      </OverflowMenu>
    )
  }

  return (
    <Card title="Consultar Contacto">
      <div className="mb-2 form-buttons">
        <p></p>
        <Button
          kind="ghost"
          type="button"
          onClick={() => navigate('../registar-contacto/' + clientId)}
          renderIcon={Add16}
        >
          Registar Contacto
        </Button>
      </div>

      <DataTable
        rows={contacts}
        headers={tableHeaders}
        isSortable
        useZebraStyles
      >
        {({ rows, headers, getHeaderProps, getTableProps }) => (
          <TableContainer>
            <Table {...getTableProps()}>
              <TableHead>
                <TableRow>
                  {headers.map((header) => (
                    <TableHeader {...getHeaderProps({ header })}>
                      {header.header}
                    </TableHeader>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow key={row.id}>
                    {row.cells.map((cell) => (
                      <TableCell key={cell.id}>
                        {cell.value?.content ?? cell.value}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </DataTable>

      <TablePagination
        currentPageSize={size}
        onPageChange={onPageChange}
        totalItems={count}
      />
    </Card>
  )
}

export default ClientContactPage
