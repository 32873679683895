import axios from "axios";

const axiosInstance = axios.create({
 baseURL: "http://crm.ologa.com:8000/api/v1",
  //baseURL: " http://teste-crm.ologa.com:8005/api/v1",
  //baseURL: "http://localhost:8082/api/v1",
  headers: {
    "Content-type": "application/json",
  },
});

axiosInstance.interceptors.request.use(
  (config) => {
    const user = localStorage.getItem("principal");
    if (user) {
      config.headers.Authorization = `Bearer ${JSON.parse(user).token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

export default axiosInstance;
