import { Link, useNavigate } from "react-router-dom";

import { Add16, Search32, Edit24 } from "@carbon/icons-react";
import http from "../../http-common";
import TablePagination from "../../components/Pagination/Pagination";
import { useForm } from "react-hook-form";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Card from "../../components/Card/Card";
import {
  Row,
  Column,
  TextInput,
  Button,
  DataTable,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
  TableToolbar,
  TableToolbarContent,
  OverflowMenuItem,
  OverflowMenu,
  Form,
  Tabs,
  Tab,
  Dropdown,
} from "carbon-components-react";

const tableHeaders = [
  {
    header: "Descriçâo",
    key: "description",
  },
  {
    header: "Tipo",
    key: "code",
  },
  {
    header: "Operações",
    key: "actions",
  },
];

const LinkList = ({ url, url2 }) => (
  <ul style={{ display: "flex" }}>
    <li>
      <Link to={url} title="Editar">
        <Edit24 />
      </Link>
    </li>
    &nbsp;&nbsp;&nbsp;
  </ul>
);

const ConfigurationPage = () => {
  const navigate = useNavigate();
  const [count, setCount] = useState(0);
  const [configurations, setConfigurations] = useState([]);
  const [size, setSize] = useState(20);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  // const onSearch = async (data) => {
  //   try {
  //     if (data.name === "") {
  //       data.name = "-1";
  //     }

  //     const response = await http.get(`/readconfigurations`);

  //     getConfigurationtData(response);
  //   } catch (error) {}
  // };

  //    }

  const featchData = async () => {
    try {
      const response = await http.get(
        "/configuration/search/filter?size=" + size
      );
      //const response = await http.get("readconfigurations");
      getConfigurationtData(response);
    } catch (error) {}
  };

  const getConfigurationtData = (response) => {
    try {
      const data = response.data.content.map((result) => ({
        id: result.id,
        description: result.description,
        type: result.type,
        code: result.code,
        actions: <LinkList url={"../configuration-update/" + result.id} />,
      }));

      setConfigurations(data);
      setCount(response.data.totalElements);
    } catch (error) {}
  };

  const onPageChange = async ({ page, pageSize }) => {
    try {
      var pag = page - 1;
      const response = await http.get(
        "/configuration/search/filter?page=" + pag + "&size=" + pageSize
      );
      //const response = await http.map("/readconfigurations");
      getConfigurationtData(response);
    } catch (error) {}
  };

  const onSearch = async (data) => {
    try {
      const response = await http.get(
        `/configuration/search/filter?type=${data.type}&description=${data.description}`
      );
      getConfigurationtData(response);
    } catch (error) {}
  };

  useEffect(() => {
    featchData();
  }, []);

  function onTypeChange(e) {
    console.log("eeeee", e);

    configurations.type = e.selectedItem.id;
    setValue("type", e.selectedItem.id);
  }

  return (
    <Card title="Configuraçôes">
      {/*       <Tabs>

          <Tab label="INSTRUÇÕES">Tab Label 1</Tab>
          <Tab>Tab Label 2</Tab>
          <Tab>Tab Label 3</Tab>


      </Tabs> */}

      <div className="mb-2 form-buttons">
        <p></p>
        <Button
          kind="ghost"
          type="button"
          onClick={() => navigate("../configuration-create")}
          renderIcon={Add16}
        >
          Registar Configuraçâo
        </Button>
      </div>

      <DataTable
        rows={configurations}
        headers={tableHeaders}
        isSortable
        useZebraStyles
      >
        {({ rows, headers, getHeaderProps, getTableProps }) => (
          <TableContainer>
            <TableToolbar aria-label="data table toolbar">
              <TableToolbarContent>
                <Form
                  className="data-table-search-form"
                  onSubmit={handleSubmit(onSearch)}
                >
                  <Row>
                    <Column sm={6} md={6} lg={5}>
                      <TextInput
                        id="description"
                        labelText=""
                        placeholder="Descriçâo"
                        {...register("description")}
                      />
                    </Column>
                    <Column sm={6} md={6} lg={6}>
                      <Dropdown
                        id="typeOfProject"
                        titleText=""
                        label="Tipo"
                        items={[
                          { id: "", text: "Selecione  " },
                          { id: "SERVICE", text: "TIPO DE ACTIVIDADE" },
                          { id: "CLIENT", text: "TIPO DE ORGANIZAÇÃO" },
                          { id: "COMPANY", text: "EMPRESA" },
                          { id: "DEPARTMENT", text: "DEPARTAMENTO" },
                          {
                            id: "POSITION_CONTACT",
                            text: "FUNÇÃO",
                          },
                          { id: "TYPE_OF_PROJECT", text: "TIPO DE SERVIÇO" },
                          {
                            id: "ACTIVITY",
                            text: "TIPO DE ACTIVIDADE DO DO CLIENTE",
                          },
                          { id: "STATUS", text: "ESTADO" },
                          {
                            id: "ACADEMIC_DEGREE",
                            text: "NÍVEL ACADÊMICO",
                          },
                          { id: "POSITION_OF_EMPLOYEE", text: "CARGO" },
                          { id: "TYPE_OF_LICENSE", text: "TIPO DE LICENÇA" },
                          { id: "PAYMENT_FORM", text: "FORMAS DE PAGAMENTO" },
                        ]}
                        onChange={onTypeChange}
                        name="typeOfProject"
                        itemToString={(item) => (item ? item.text : "")}
                      />
                      <p className="field-error">{errors.type?.message}</p>
                    </Column>
                  </Row>
                  <Button type="submit" renderIcon={Search32}>
                    Pesquisar
                  </Button>
                </Form>
              </TableToolbarContent>
            </TableToolbar>
            <Table {...getTableProps()}>
              <TableHead>
                <TableRow>
                  {headers.map((header) => (
                    <TableHeader {...getHeaderProps({ header })}>
                      {header.header}
                    </TableHeader>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow key={row.id} className="text-danger">
                    {row.cells.map((cell) => (
                      <TableCell key={cell.id}>
                        {cell.value?.content ?? cell.value}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </DataTable>
      <TablePagination
        currentPageSize={size}
        onPageChange={onPageChange}
        totalItems={count}
      />
    </Card>
  );
};

export default ConfigurationPage;
