import React, { useEffect, useState } from "react";
import Card from "../../components/Card/Card";
import { toast } from "react-toastify";
import http from "../../http-common";
import { DataTable } from "carbon-components-react";
import { TableContainer } from "carbon-components-react";
import { TableToolbar } from "carbon-components-react";
import { Table } from "carbon-components-react";
import { TableHead } from "carbon-components-react";
import { TableRow } from "carbon-components-react";
import { TableBody } from "carbon-components-react";
import { TableCell } from "carbon-components-react";
import { TableHeader } from "carbon-components-react";

const tableHeaders = [
  {
    header: "Nome",
    key: "firstName",
  },
  {
    header: "Apelido",
    key: "lastName",
  },
  {
    header: "Telefone",
    key: "phoneNumber",
  },
  {
    header: "Candidaturas Atribuídas",
    key: "totalAssignedApplications",
  },
  {
    header: "Avaliadas",
    key: "totalEvaluated",
  },
  {
    header: "Por Avaliar",
    key: "totalRemaining",
  },
];

const ApplicationJuryPanelPage = () => {
  const [stats, setStats] = useState([]);
  const [total, setTotal] = useState(0);

  const getStats = async () => {
    try {
      const response = await http.get("/applications/jury/stats");
      // const data = response.data.map((s) => ({
      //   id: s.phoneNumber,
      //   name: s.key,
      //   phone: s.group,
      //   applications: s.value,
      // }));
      setStats(response.data);
    } catch (error) {
      toast.error("Erro ao carregar dados estatísticos");
    }
  };

  useEffect(() => {
    getStats();
  }, []);

  return (
    <Card title="Painel de júri">
      <DataTable rows={stats} headers={tableHeaders} isSortable useZebraStyles>
        {({ rows, headers, getHeaderProps, getTableProps }) => (
          <TableContainer>
            <TableToolbar aria-label="data table toolbar"></TableToolbar>
            <Table {...getTableProps()}>
              <TableHead>
                <TableRow>
                  {headers.map((header) => (
                    <TableHeader {...getHeaderProps({ header })}>
                      {header.header}
                    </TableHeader>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow key={row.id}>
                    {row.cells.map((cell) => (
                      <TableCell key={cell.id}>{cell.value}</TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </DataTable>
    </Card>
  );
};

export default ApplicationJuryPanelPage;
