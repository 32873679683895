import React from "react";
import Card from "../../components/Card/Card";


const InfoMaintenancePage = () => {

    return (

        <div className="bx--row">
            <div className="bx--col-lg-6 content"></div>

            <div className="bx--col-sm-12 bx--col-lg-6">
                <Card title="Informação">
                    <p>
                        Ola, neste momento a plataforma encontra se em manunteção. 
                        Em breve poderá, desculpas pelos transtornos causados. 
                        Em caso de apoio entra em contacto pelo:<br/>
                         Email: feiras@ideglobal.org
                         Whatsapp: +258843101867
                    </p>
                </Card>
            </div>
        </div>
    );
};

export default InfoMaintenancePage;