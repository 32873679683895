import React, { useState, useEffect } from "react";
import Card from "../../components/Card/Card";
import { Form } from "carbon-components-react";
import {
  Row,
  Column,
  TextInput,
  Button,
  DataTable,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
  TableToolbar,
  TableToolbarContent,
  OverflowMenuItem,
  OverflowMenu,
} from "carbon-components-react";
import { Link, useNavigate } from "react-router-dom";
import { Add16, Search32, Edit24 } from "@carbon/icons-react";
import http from "../../http-common";
import TablePagination from "../../components/Pagination/Pagination";
import { useForm } from "react-hook-form";

const tableHeaders = [
  {
    header: "Nome",
    key: "name",
  },
  {
    header: "Apelido",
    key: "surName",
  },
  {
    header: "Cargo",
    key: "position",
  },
  {
    header: "Contacto na Ologa",
    key: "contactOloga",
  },
  {
    header: "Email",
    key: "email",
  },
  {
    header: "Email Opcional",
    key: "opcionalEmail",
  },

  {
    header: "Número de celular",
    key: "phoneNumber",
  },
  {
    header: "Telefone fixo",
    key: "faxNumber",
  },
  {
    header: "Operações",
    key: "actions",
  },
];
const LinkList = ({ url, url2 }) => (
  <ul style={{ display: "flex" }}>
    <li>
      <Link to={url} title="Editar">
        <Edit24 />
      </Link>
    </li>
    &nbsp;&nbsp;&nbsp;
  </ul>
);

const ContactPage = () => {
  const navigate = useNavigate();

  const [contact, setContact] = useState([]);
  const [count, setCount] = useState(0);
  const [size, setSize] = useState(20);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const featchData = async () => {
    try {
      const response = await http.get("/contacts/search/filter?size=" + size);
      getContacData(response);
    } catch (error) {}
  };
  const getContacData = (response) => {
    const data = response.data.content.map((contact) => ({
      id: contact.id,
      name: contact.name,
      surName: contact.surName,
      position: contact.position,
      contactOloga: contact.contactOloga,
      email: contact.email,
      opcionalEmail: contact.opcionalEmail,
      phoneNumber: contact.phoneNumber,
      faxNumber: contact.faxNumber,

      actions: tableActions(contact),
    }));
    setContact(data);
    setCount(response.data.totalElements);
  };

  const onPageChange = async ({ page, pageSize }) => {
    try {
      var pag = page - 1;
      const response = await http.get(
        "/contacts/search/filter?page=" + pag + "&size=" + pageSize
      );
      getContacData(response);
    } catch (error) {}
  };

  const onSearch = async (data) => {
    try {
      const response = await http.get(
        `/contacts/search/filter?name=${data.name}`
      );
      getContacData(response);
    } catch (error) {}
  };

  useEffect(() => {
    featchData();
  }, []);

  const tableActions = (contact) => {
    return (
      <OverflowMenu flipped>
        <OverflowMenuItem
          key="optionOne"
          itemText="Edit"
          onClick={() => navigate("../actualizar-contacto/" + contact.id)}
        />
      </OverflowMenu>
    );
  };

  return (
    <Card title="Consultar Contactos">
      <div className="mb-2 form-buttons">
        <p></p>
        <Button
          kind="ghost"
          type="button"
          onClick={() => navigate("../registar-contacto")}
          renderIcon={Add16}
        >
          Registar Contacto
        </Button>
      </div>

      <DataTable
        rows={contact}
        headers={tableHeaders}
        isSortable
        useZebraStyles
      >
        {({ rows, headers, getHeaderProps, getTableProps }) => (
          <TableContainer>
            <TableToolbar aria-label="data table toolbar">
              <TableToolbarContent>
                <Form
                  className="data-table-search-form"
                  onSubmit={handleSubmit(onSearch)}
                >
                  <Row>
                    <Column sm={6} md={6} lg={5}>
                      <TextInput
                        id="email"
                        labelText=""
                        placeholder="email"
                        {...register("email")}
                      />
                    </Column>
                  </Row>
                  <Button type="submit" renderIcon={Search32}>
                    Pesquisar
                  </Button>
                </Form>
              </TableToolbarContent>
            </TableToolbar>
            <Table {...getTableProps()}>
              <TableHead>
                <TableRow>
                  {headers.map((header) => (
                    <TableHeader {...getHeaderProps({ header })}>
                      {header.header}
                    </TableHeader>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow key={row.id}>
                    {row.cells.map((cell) => (
                      <TableCell key={cell.id}>
                        {cell.value?.content ?? cell.value}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </DataTable>
      <TablePagination
        currentPageSize={size}
        onPageChange={onPageChange}
        totalItems={count}
      />
    </Card>
  );
};
export default ContactPage;
