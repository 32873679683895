import {
  Button,
  Form,
  TextInput,
  Row,
  Column,
  ButtonSet,
  Dropdown,
  DatePicker,
  DatePickerInput,
  Loading,
} from "carbon-components-react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import http from "../../http-common";
import { Activity, SaveModel24 } from "@carbon/icons-react";
import Card from "../../components/Card/Card";
import { serviceSchema } from "../../services/SchemaService";
import { yupResolver } from "@hookform/resolvers/yup";

const ServiceForm = ({ mode, url, serviceId, projectid }) => {
  const [formMode] = useState(mode);
  const [showSuccess, setShowSuccess] = useState(false);

  const currencyObj = {
    id: 0,
    value: 0,
    valueCoinType: "MZN",
    correspondedValue: 0,
    correspondedValueCoinType: "",

    projectId: 0,
    serviceId: 0,
  };
  const [currency, setCurrency] = useState(currencyObj);
  const [service, setService] = useState({
    description: "",
    type: "",
    prevstartDate: "",
    startDate: "",
    prevendDate: "",
    endDate: "",
    project: { id: projectid },
    //employee: { id: "" },
    employee_id: "",
    coinType: "",
    status:  "",
  });

  const [employees, setEmployees] = useState([]);
  const [activityStatus, setActivityStatus] = useState([]);
  const [status, setStatus] = useState([]);
  const [types, setServiceTypes] = useState([]);
  const [showLoading, setShowLoading] = useState(false);
  const [isBtnDisabled, setisBtnDisabled] = useState(false);
  const [intcointypes, setIntCointypes] = useState([]);

  const getSchema = () => {
    switch (mode) {
      case "UPDATE":
        return serviceSchema;
      case "CREATE":
        return serviceSchema;
      default:
        return serviceSchema;
    }
  };

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({ resolver: yupResolver(getSchema()), mode: "onSubmit" });
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    (async () => {
      let response = await fetchServiceTypes();
      if (response.success) {
        setServiceTypes(response.data);
      }
    })();
  }, []);

  const fetchServiceTypes = async () => {
    try {
      const response = await http.get("/servicetypes");
      let items = response.data.map((e) => {
        return { id: e.id, text: e.description, children: e.children };
      });
      return { success: true, data: items };
    } catch (error) {
      return { success: false };
    }
  };

  //Gett Employess

  useEffect(() => {
    (async () => {
      let response = await fetchEmployee();
      if (response.success) {
        setEmployees(response.data);
      }
    })();
  }, []);

  const fetchEmployee = async () => {
    try {
      const response = await http.get("/employees");
      let items = response.data.map((e) => {
        return {
          id: e.id,
          text: e.firstName + " " + e.surName,
          children: e.children,
        };
      });
      return { success: true, data: items };
    } catch (error) {
      return { success: false };
    }
  };

  useEffect(() => {
    (async () => {
      let response = await fetchActivityStatus();
      if (response.success) {
        setActivityStatus(response.data);
      }
    })();
  }, []);

  const fetchActivityStatus = async () => {
    try {
      const response = await http.get("/activityStatus");
      console.log("xxx", response)
      let items = response.data.map((e) => {
        return {
          id: e.description,
          text: e.description,
          children: e.children,
        };
      });
      return { success: true, data: items };
    } catch (error) {
      return { success: false };
    }
  };


  //fetch Status

  useEffect(() => {
    (async () => {
      let response = await fetchStatus();
      if (response.success) {
        setStatus(response.data);
      }
    })();
  }, []);

  //Coin Type
  useEffect(() => {
    (async () => {
      let response = await fetchIntCoinType();
      if (response.success) {
        setIntCointypes(response.data);
      }
    })();
  }, []);

  const fetchIntCoinType = async () => {
    try {
      const response = await http.get("/cointype");
      let items = response.data.map((e) => {
        return { id: e.code, text: e.description, children: e.children };
      });
      return { success: true, data: items };
    } catch (error) {
      return { success: false };
    }
  };

  const fetchStatus = async () => {
    try {
      const response = await http.get("/status");
      let items = response.data.map((e) => {
        return { id: e.code, text: e.description, children: e.children };
      });
      return { success: true, data: items };
    } catch (error) {
      return { success: false };
    }
  };
  const delay = (ms) => new Promise((res) => setTimeout(res, ms));

  async function onSubmit(data) {
    try {
      setisBtnDisabled(true);
      var _data = null;

      if (formMode === "UPDATE") {
        _data = await http.post(url, data);

        const PostCurrency = await http.post("/currencies", currency);
        toast.success("Operação realizada com sucesso!");
        await delay(2000); // breaking
        navigate("../update-service/" + serviceId);
      } else {
        _data = await http.post(url, data);

        currency.serviceId = _data?.data.id;
        const PostCurrency = await http.post("/currencies", currency);

        if (_data?.data.msg !== undefined) {
          toast.error("Operação não realizada!: \n " + _data?.data.msg);
          setisBtnDisabled(false);
        } else {
          toast.success("Operação realizada com sucesso!");
          await delay(2000); // breaking
          navigate("../actualizar-projecto/" + projectid);
        }
      }
    } catch (error) {
      setisBtnDisabled(false);
      toast.error(error.response?.data.message);
    }
  }

  useEffect(() => {
    if (formMode === "UPDATE") {
      const fechcurrency = async () => {
        const coint_result = await http.get(`/currency/service/${serviceId}`);
        const constCointFields = [
          "id",
          "value",
          "valueCoinType",
          "correspondedValue",
          "correspondedValueCoinType ",

          "projectId",
          "serviceId",
        ];
        constCointFields.forEach((field) => setValue(field, currency[field]));
        setCurrency(coint_result.data);
      };
      fechcurrency();
    }
  }, []);

  useEffect(() => {
    if (formMode === "UPDATE") {
      const fetchData = async () => {
        try {
          const result = await http.get(`/services/${serviceId}`);
          const fields = [
            "id",
            "type",
            "description",
            "startDate",
            "endDate",
            "coinType",
          ];
          fields.forEach((field) => setValue(field, service[field]));
          setService(result.data);
        } catch (error) {
          toast.error(error.response?.data.message);
        }
      };

      fetchData();
    }
    setShowSuccess(false);
  }, [formMode]);

  useEffect(() => {
    reset(service);
  }, [service]);

  const setprevstartDate = (e) => {
    setValue("prevstartDate", e.target.value);
    service.prevstartDate = e.target.value;
  };
  const setstartDate = (e) => {
    setValue("startDate", e.target.value);
    service.startDate = e.target.value;
  };

  const setprevendDate = (e) => {
    setValue("prevendDate", e.target.value);
    service.prevendDate = e.target.value;
  };
  const setendDate = (e) => {
    setValue("endDate", e.target.value);
    service.endDate = e.target.value;
  };

  function onTypeChange(e) {
    setValue("type", e.selectedItem.text);
    service.type = e.selectedItem.text;
  }

  function onEmployeeChange(e) {
    service.employee_id = e.selectedItem.id;
    setValue("employee_id", e.selectedItem.id);
  }

  function onActivityStatusChange(e) {
    service.status = e.selectedItem.id;
    setValue("status", e.selectedItem.id);
  }

  function onCointTypeChange(e) {
    currency.correspondedValueCoinType = e.selectedItem.text;
    setValue("correspondedValueCoinType", e.selectedItem.text);
  }
  //On file change for contracts

  function handleChangeCoint(e) {
    const { name, value } = e.target;
    setCurrency((prevValue) => ({
      ...prevValue,
      [name]: value,
    }));

    console.log("c", currency.value);
  }
  return (
    <Card>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Column sm={6} md={6} lg={6} className="required">
            <TextInput
              id="description"
              labelText="Descrição"
              {...register("description")}
            />
            <p className="field-error">{errors.description?.message}</p>
          </Column>

          <Column sm={6} md={6} lg={6} className="required">
            <Dropdown
              id="type"
              titleText="Tipo de servico"
              label={formMode == "UPDATE" ? service.type : "Selecione"}
              items={types}
              onChange={onTypeChange}
              name="type"
              itemToString={(item) => (item ? item.text : "")}
            />
            <p className="field-error">{errors.type?.message}</p>
          </Column>
        </Row>

        <Row>
          <Column sm={6} md={6} lg={6}>
            {formMode === "UPDATE" ? (
              <DatePicker
                locale="pt"
                dateFormat="d/m/Y"
                datePickerType="single"
              >
                <DatePickerInput
                  style={{ width: "330px" }}
                  labelText="Data de previsão de Início"
                  id="prevstartDate"
                  name="prevstartDate"
                  value={service.prevstartDate}
                  {...register("prevstartDate")}
                  onSelect={setprevstartDate}
                />
              </DatePicker>
            ) : (
              <DatePicker
                locale="pt"
                dateFormat="d/m/Y"
                datePickerType="single"
              >
                <DatePickerInput
                  style={{ width: "330px" }}
                  labelText="Data de previsão de Início"
                  id="prevstartDate"
                  name="prevstartDate"
                  {...register("prevstartDate")}
                  onSelect={setprevstartDate}
                />
              </DatePicker>
            )}
          </Column>
          <Column sm={6} md={6} lg={6}>
            {formMode === "UPDATE" ? (
              <DatePicker
                locale="pt"
                dateFormat="d/m/Y"
                datePickerType="single"
              >
                <DatePickerInput
                  style={{ width: "330px" }}
                  labelText="Data de Início"
                  id="startDate"
                  name="startDate"
                  value={service.startDate}
                  {...register("startDate")}
                  onSelect={setstartDate}
                />
              </DatePicker>
            ) : (
              <DatePicker
                locale="pt"
                dateFormat="d/m/Y"
                datePickerType="single"
              >
                <DatePickerInput
                  style={{ width: "330px" }}
                  labelText="Data de Início"
                  id="startDate"
                  name="startDate"
                  {...register("startDate")}
                  onSelect={setstartDate}
                />
              </DatePicker>
            )}
          </Column>
        </Row>
        <Row>
          <Column sm={6} md={6} lg={6}>
            {formMode === "UPDATE" ? (
              <DatePicker
                locale="pt"
                dateFormat="d/m/Y"
                datePickerType="single"
              >
                <DatePickerInput
                  style={{ width: "330px" }}
                  labelText="Data de previsão de término"
                  id="prevendDate"
                  name="prevendDate"
                  value={service.prevendDate}
                  {...register("prevendDate")}
                  onSelect={setprevendDate}
                />
              </DatePicker>
            ) : (
              <DatePicker
                locale="pt"
                dateFormat="d/m/Y"
                datePickerType="single"
              >
                <DatePickerInput
                  style={{ width: "330px" }}
                  labelText="Data de previsão de término"
                  id="prevendDate"
                  name="prevendDate"
                  {...register("prevendDate")}
                  onSelect={setprevendDate}
                />
              </DatePicker>
            )}
          </Column>

          <Column sm={6} md={6} lg={6}>
            {formMode === "UPDATE" ? (
              <DatePicker
                locale="pt"
                dateFormat="d/m/Y"
                datePickerType="single"
              >
                <DatePickerInput
                  style={{ width: "330px" }}
                  labelText="Data de término"
                  id="endDate"
                  name="endDate"
                  value={service.endDate}
                  {...register("endDate")}
                  onSelect={setendDate}
                />
              </DatePicker>
            ) : (
              <DatePicker
                locale="pt"
                dateFormat="d/m/Y"
                datePickerType="single"
              >
                <DatePickerInput
                  style={{ width: "330px" }}
                  labelText="Data de término"
                  id="endDate"
                  name="endDate"
                  {...register("startDate")}
                  onSelect={setendDate}
                />
              </DatePicker>
            )}
          </Column>
        </Row>
        <Row>
          <Column sm={2} md={6} lg={2} className="required">
            <TextInput
              id="currencyProject.coinType"
              labelText="Moeda"
              value={"MZN"}
              disabled
            />
          </Column>
          <Column sm={4} md={6} lg={4} className="required">
            <TextInput
              labelText="Valor do projecto (MZN)"
              value={currency.value}
              name="value"
              onChange={handleChangeCoint}
            />
            <p className="field-error">{errors.currencyProject?.message}</p>
          </Column>
          <Column sm={4} md={4} lg={4}>
            <TextInput
              labelText="Valor correspondente em outra moeda"
              value={currency.correspondedValue}
              name="correspondedValue"
              onChange={handleChangeCoint}
            />
          </Column>
          <Column sm={2} md={2} lg={2}>
            <Dropdown
              titleText="Tipo de moeda"
              items={intcointypes}
              onChange={onCointTypeChange}
              label={
                formMode == "UPDATE"
                  ? currency.correspondedValueCoinType
                  : "Selecione"
              }
              itemToString={(item) => (item ? item.text : "")}
            ></Dropdown>
          </Column>
        </Row>
        <Row>
          <Column sm={6} md={6} lg={6} className="required">
            <Dropdown
              id="employee.id"
              label="Selecione"
              titleText="Responsável pela actividade"
              items={employees}
              onChange={onEmployeeChange}
              name="employee.id"
              itemToString={(item) => (item ? item.text : "")}
            />
            <p className="field-error">{errors.employee?.message}</p>
          </Column>
          <Column sm={6} md={6} lg={6} className="required">
            <Dropdown
              id="status" 
              label={formMode == "UPDATE" ? service.status : "Selecione"}
              titleText="Estado da actividade"
              items={activityStatus}
              onChange={onActivityStatusChange}
              name="status"
              itemToString={(item) => (item ? item.text : "")}
            />
            <p className="field-error">{errors.employee?.message}</p>
          </Column>
        </Row>
        <Row className="mt-2">
          <Column sm={9} md={9} lg={9}>
            <ButtonSet>
              <Button
                disabled={isBtnDisabled}
                type="submit"
                renderIcon={SaveModel24}
                iconDescription="Save"
              >
                Salvar
                {showLoading == true ? (
                  <Loading
                    description="Active loading indicator"
                    withOverlay={false}
                    small
                  />
                ) : (
                  ""
                )}
              </Button>
              <Button
                onClick={() =>
                  navigate("../actualizar-projecto/" + service?.project?.id)
                }
              >
                Voltar
              </Button>
            </ButtonSet>
          </Column>
          <Column sm={3} md={3} lg={3}>
            <ButtonSet>
              {/* <Button onClick={() => navigate("../update_client/" + projectid)}>
                Voltar para o cliente
              </Button> */}
            </ButtonSet>
          </Column>
        </Row>
      </Form>
    </Card>
  );
};

export default ServiceForm;
