import React from "react";
import { useParams } from "react-router-dom";
import Card from "../../components/Card/Card";
import ProviderForm from "./ProviderForm";

const UpdateProviderPage = () => {
  const params = useParams();
  return (
    <>
      <Card title="Actualizar Fornecedor">
        <ProviderForm
          mode={"UPDATE"}
          url={"/provider/" + params.id}
          successMessage={"Fornecedor actualizado com sucesso'"}
          onSuccessNavigateTo={"../fornecedor/"}
          providerId={params.id}
        />
      </Card>
    </>
  );
};

export default UpdateProviderPage;
