import React from "react";

import { useParams } from "react-router-dom";
import Card from "../../components/Card/Card";
import EmployeeForm from "./EmployeeForm";

const CreateEmployeePage = () => {
  const params = useParams();
  return (
    <Card title="Registar Funcionário">
      <EmployeeForm
        mode={"CREATE"}
        url={"/employees"}
        successMessage={"Funcionário registado com sucesso"}
        onSuccessNavigateTo={"../actualizar-funcionario/" + params.id}
        employeeId={params.id}
      />
    </Card>
  );
};

export default CreateEmployeePage;
