import React from "react";
import { useParams } from "react-router-dom";
import Card from "../../components/Card/Card";
import LicenseManagementForm from "./LicenseManagementForm";

const UpdateLicenseManagementPage = () => {
  const params = useParams();
  return (
    <>
      <Card title="Actualizar Lincenças">
        <LicenseManagementForm
          mode={"UPDATE"}
          url={"/licensemanagement/" + params.id}
          successMessage={"Gestão de lincenças Actualizada com sucesso"}
          onSuccessNavigateTo={"../licensas/"}
          licensaId={params.id}
        />
      </Card>
    </>
  );
};

export default UpdateLicenseManagementPage;
