import React, { useState, useEffect } from "react";
import Card from "../../components/Card/Card";
import { Form } from "carbon-components-react";
import {
  Row,
  Column,
  TextInput,
  Button,
  DataTable,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
  TableToolbar,
  TableToolbarContent,
  OverflowMenuItem,
  OverflowMenu,
} from "carbon-components-react";
import { Link, useNavigate } from "react-router-dom";
import { Add16, Search32, Edit24 } from "@carbon/icons-react";
import http from "../../http-common";
import TablePagination from "../../components/Pagination/Pagination";
import { useForm } from "react-hook-form";

const tableHeaders = [
  {
    header: "Tipo da licença",
    key: "typeOfLicence",
  },
  {
    header: "Descrição/Nome",
    key: "description",
  },
  {
    header: "Versão",
    key: "version",
  },
  {
    header: "Data de compra da licença",
    key: "dateOfPurchase",
  },
  {
    header: "Data de validade da licença",
    key: "expirationDate",
  },
  {
    header: "Referência",
    key: "numberOfLincence",
  },
  {
    header: "Custo da licença",
    key: "licenseCost",
  },
  {
    header: "Condições de uso da licença",
    key: "conditionOfUse",
  },
  {
    header: "Restrições de uso da licença",
    key: "restrictionOfUse",
  },
  {
    header: "Operações",
    key: "actions",
  },
];

const LinkList = ({ url, url2 }) => (
  <ul style={{ display: "flex" }}>
    <li>
      <Link to={url} title="Editar">
        <Edit24 />
      </Link>
    </li>
    &nbsp;&nbsp;&nbsp;
  </ul>
);

const LicenseManagementPage = () => {
  const navigate = useNavigate();

  const [license, setLicense] = useState([]);
  const [count, setCount] = useState(0);
  const [size, setSize] = useState(20);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const featchData = async () => {
    try {
      const response = await http.get(
      //  "/licensemanagement/search/filter?size=" + size

      "licensemanagement/provider/client/search/filter?size" + size
      )
      getLicenseData(response);
    } catch (error) {}
  };

  const getLicenseData = (response) => {
    const data = response.data.content.map((license) => ({
      id: license.id,
      typeOfLicence: license.typeOfLicence,
      description: license.description,
      version: license.version,
      dateOfPurchase: license.dateOfPurchase,
      expirationDate: license.expirationDate,
      numberOfLincence: license.numberOfLincence,
      licenseCost: license.licenseCost,
      conditionOfUse: license.conditionOfUse,
      restrictionOfUse: license.restrictionOfUse,
      client: license.client,
      providerId: license.providerId,

      actions: <LinkList url={"../actualizar-licensas/" + license.id} />,
    }));
    setLicense(data);
    console.log("Out data", data)
    setCount(response.data.totalElements);
  };

  const onPageChange = async ({ page, pageSize }) => {
    try {
      var pag = page - 1;
      const response = await http.get(
      //  "/licensemanagement/search/filter?page=" + pag + "&size=" + pageSize

      "licensemanagement/provider/client/search/filter" + pag + "&size=" + pageSize
      );
      getLicenseData(response);
    } catch (error) {}
  };

  const onSearch = async (data) => {
    try {
      const response = await http.get(
      //  `/licensemanagement/search/filter?description=${data.description}`

        `licensemanagement/provider/client/search/filter?typeOfLicence=${data.typeOfLicence}`
      );
      getLicenseData(response);
    } catch (error) {}
  };

  useEffect(() => {
    featchData();
  }, []);

  const tableActions = (license) => {
    return (
      <OverflowMenu flipped>
        <OverflowMenuItem
          key="optionOne"
          itemText="Edit"
          onClick={() => navigate("../actualizar-licensa/" + license.id)}
        />
      </OverflowMenu>
    );
  };

  return (
    <Card title="Consultar Licensas">
      <div className="mb-2 form-buttons">
        <p></p>
        <Button
          kind="ghost"
          type="button"
          onClick={() => navigate("../registar-licensas")}
          renderIcon={Add16}
        >
          Registar Licensas
        </Button>
      </div>

      <DataTable
        rows={license}
        headers={tableHeaders}
        isSortable
        useZebraStyles
      >
        {({ rows, headers, getHeaderProps, getTableProps }) => (
          <TableContainer>
            <TableToolbar aria-label="data table toolbar">
              <TableToolbarContent>
                <Form
                  className="data-table-search-form"
                  onSubmit={handleSubmit(onSearch)}
                >
                  <Row>
                    <Column sm={6} md={6} lg={5}>
                      <TextInput
                        id="description"
                        labelText=""
                        placeholder="Nme ou Descricao"
                        {...register("description")}
                      />
                    </Column>
                    <Column sm={6} md={6} lg={5}>
                      <TextInput
                        id="type_of_licence"
                        labelText=""
                        placeholder="Tipo de licensa"
                        {...register("type_of_licence")}
                      />
                    </Column>
                  </Row>
                  <Button type="submit" renderIcon={Search32}>
                    Pesquisar
                  </Button>
                </Form>
              </TableToolbarContent>
            </TableToolbar>
            <Table {...getTableProps()}>
              <TableHead>
                <TableRow>
                  {headers.map((header) => (
                    <TableHeader {...getHeaderProps({ header })}>
                      {header.header}
                    </TableHeader>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow key={row.id}>
                    {row.cells.map((cell) => (
                      <TableCell key={cell.id}>
                        {cell.value?.content ?? cell.value}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </DataTable>
      <TablePagination
        currentPageSize={size}
        onPageChange={onPageChange}
        totalItems={count}
      />
    </Card>
  );
};
export default LicenseManagementPage;
