import { Link, useNavigate } from "react-router-dom";
import { SaveModel24 } from "@carbon/icons-react";
import { toast } from "react-toastify";
import { Add16, Search32, Edit24, ArrowLeft24 } from "@carbon/icons-react";
import http from "../../http-common";
import TablePagination from "../../components/Pagination/Pagination";
import { useForm } from "react-hook-form";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Card from "../../components/Card/Card";
import {
  Dropdown,
  Column,
  TextInput,
  DataTable,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
  TableToolbar,
  TableToolbarContent,
  OverflowMenuItem,
  OverflowMenu,
  Form,
  SelectItem,
  Select,
  Button,
  Row,
  ButtonSet,
  Loading,
} from "carbon-components-react";

const ConfigurationForm = ({ url, configurationId, mode }) => {
  const navigate = useNavigate();
  const [count, setCount] = useState(0);
  const [services, setService] = useState([]);
  const [size, setSize] = useState(20);
  const [formMode] = useState(mode);
  const params = useParams();
  const {
    register,
    reset,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "onSubmit" });

  const [isBtnDisabled, setisBtnDisabled] = useState(false);
  const [title, setTitle] = useState("");

  const [configuration, setconfiguration] = useState({
    code: "",
    type: "",
    description: "",
    id: params.id > 0 ? params.id : 0,
  });

  useEffect(() => {
    reset(configuration);
    console.log("eeeeee");
  }, [configuration]);

  useEffect(() => {
    if (formMode === "UPDATE") {
      setTitle(" Editar params");

      const fetchData = async () => {
        try {
          const result = await http.get(`/readconfiguration/${params.id}`);
          const fields = ["id", "code", "description"];
          fields.forEach((field) => setValue(field, configuration[field]));
          setconfiguration(result.data);
          console.log("Data configuration", result.data);
        } catch (error) {
          toast.error(error.response?.data.message);
        }
      };
      fetchData();
    } else {

      setTitle(" Criar Configuraçâo");
    }
  }, [formMode]);

  useEffect(() => {
    const fetchData = async () => {
      const result = await http.get(`/readconfiguration/${params.id}`);

      const fields = ["id", "code", "type", "description"];

      console.log("fieldsk", result);
      fields.forEach((field) => setValue(field, configuration[field]));
      setconfiguration(result.data);
    };
  }, []);

  function onTypeChange(e) {
    console.log("eeeee", e);

    configuration.type = e.selectedItem.id;
    setValue("type", e.selectedItem.id);
  }

  async function onSubmit(data) {
    try {
      setisBtnDisabled(true);

      if (formMode === "UPDATE") {
        var _data = null;
        _data = await http.post(url, data);
        toast.success("Operação realizada com sucesso!");

        setisBtnDisabled(false);
      } else {
        var _data = null;
        _data = await http.post(url, data);
        toast.success("Operação realizada com sucesso!");

        setisBtnDisabled(false);
      }
    } catch (error) {
      setisBtnDisabled(false);

      console.log("ERROR ON onSubmit", error);

      toast.error(error.response?.data.message);
    }
  }

  return (
    <Card title={title}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Column sm={6} md={6} lg={6} className="required">
            <TextInput
              id="name"
              labelText="Descrição"
              {...register("description")}
            />
            <p className="field-error">{errors.name?.message}</p>
          </Column>
        </Row>

        <Row>
          {formMode === "CREATE" ? (
            <Column sm={6} md={6} lg={6} className="required">
              <Dropdown
                id="typeOfProject"
                titleText="Tipo"
                label="Selecione"
                items={[
                  { id: "SERVICE", text: "TIPO DE SERVICO" },
                  { id: "CLIENT", text: "TIPO DE ORGANIZAÇÃO" },
                  { id: "COMPANY", text: "EMPRESA" },
                  { id: "DEPARTMENT", text: "DEPARTAMENTO" },
                  {
                    id: "POSITION_CONTACT",
                    text: "FUNÇÃO",
                  },
                  { id: "TYPE_OF_PROJECT", text: "TIPO DE PROJECTO" },
                  {
                    id: "ACTIVITY",
                    text: "TIPO DE ACTIVIDADE",
                  },
                  { id: "STATUS", text: "ESTADO" },
                  {
                    id: "ACADEMIC_DEGREE",
                    text: "NÍVEL ACADÊMICO",
                  },
                  { id: "POSITION_OF_EMPLOYEE", text: "CARGO" },
                  { id: "TYPE_OF_LICENSE", text: "TIPO DE LICENÇA" },
                  { id: "PAYMENT_FORM", text: "FORMAS DE PAGAMENTO" },
                ]}
                onChange={onTypeChange}
                name="typeOfProject"
                itemToString={(item) => (item ? item.text : "")}
              />
              <p className="field-error">{errors.type?.message}</p>
            </Column>
          ) : (
            ""
          )}
          {formMode === "UPDATE" ? (
            <Column sm={6} md={6} lg={6}>
              <TextInput
                id="code"
                labelText="Tipo"
                value={configuration.code}
                disabled
              />
            </Column>
          ) : (
            ""
          )}
        </Row>

        <Row className="mt-2">
          <Column>
          
              <Button
                disabled={isBtnDisabled}
                type="submit"
                renderIcon={SaveModel24}
                
                iconDescription="Save"
              >
                Salvar
              </Button>

              <Button
                onClick={() => navigate("../configuration/")}
                renderIcon={ArrowLeft24}
                iconDescription="Save"
                style={{ marginLeft: "17px"  }}
              >
                voltar
              </Button>

           
          </Column>
        </Row>
      </Form>
    </Card>
  );
};

export default ConfigurationForm;
