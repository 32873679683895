import {
  Tabs,
  Tab,
  DataTable,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
  TableToolbar,
  TableToolbarContent,
  OverflowMenuItem,
  OverflowMenu,
  Button,
  Form,
  TextInput,
  NumberInput,
  Row,
  Column,
  ButtonSet,
  Loading,
  Dropdown,
  DatePicker,
  DatePickerInput,
  Modal,
} from "carbon-components-react";
import { getValue } from "@testing-library/user-event/dist/utils";
import React from "react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import http from "../../http-common";
import {
  SaveModel24,
  ArrowLeft24,
  Add16,
  Edit24,
  TrashCan24,
} from "@carbon/icons-react";
import { employeeSchema } from "../../services/SchemaService";
import { yupResolver } from "@hookform/resolvers/yup";
import Card from "../../components/Card/Card";
import ManagementPage from "../Management/ManagementPage";
import ManagementEmployeePage from "../Management/ManagementEmployeePage";
import TablePagination from "../../components/Pagination/Pagination";
import { render } from "react-dom";
import "./EmployeeTabs.css";

const EmployeeForm = ({ disabled, mode, url, projectid, employeeId }) => {
  const [actutal, setActual] = useState("12/31/2023");
  const [admissionDate, SetAdmissionDate] = useState();
  const [availableDays, setavailableDays] = useState([]);
  const [daysAvalableForYear, setdaysAvalableForYear] = useState([]);
  const [showModal, setShowModal] = useState({
    modalval: false,
    hidebtn: false,
    modaltype: "",
  });
  const [showModalCompany, setShowModalCompany] = useState({
    modalval: false,
    hidebtn: false,
    modaltype: "",
  });
  const [showModalCompanyEdit, setShowModalCompanyEdit] = useState({
    modalval: false,

    id: 0,
    company: "",
    department: "",
    position: "",
    salary: 0,
    coinType: "",
    admissionDate: "",
    resignationDate: "",
  });

  const [contacts, setContacts] = useState([]);
  const [modaltype, setModaltype] = useState("");

  const [formMode] = useState(mode);
  const [showSuccess, setShowSuccess] = useState(false);
  const [department, setDepartment] = useState([]);
  const [categories, setCategory] = useState([]);
  const [employee, setEmployee] = useState({
    id: "",
    firstName: "",
    surName: "",
    gender: "",
    phoneNumber: "",
    faxNumber: "",
    academicDegree: "",

    status: "Activo",

    dateOfBirth: "",

    biCode: "",
    nuit: "",
    maritalStatus: "",

    inss: "",
    category: "",
    // project: { id: projectid },
  });

  const companyDefault = {
    id: 0,
    company: "",
    department: "",
    position: "",
    salary: 0,
    coinType: "",
    email: "",
    admissionDate: "",
    resignatioDate: "",
    employee: { id: employee.id },
  };

  const [company_of_employee, setcompany_of_employee] =
    useState(companyDefault);
  const [companies, setCompanies] = useState([]);

  const [management, setManagement] = useState({
    modalval: false,
    daysAvalableForYear: 0,
    enjoyedDays: 0,
    daysToEnjoy: 0,
    availableDays: 0,
    date: "",
    employeeName: "",
    employee: {
      id: employeeId,
      firstName: "",
      surName: "",
      company: "",
      department: "",
      admissionDate: "",
    },
  });

  const CompanytableHeaders = [
    {
      header: "Empresa",
      key: "company",
    },
    {
      header: "Departamento",
      key: "department",
    },
    {
      header: "Cargo",
      key: "position",
    },
    {
      header: "email",
      key: "email",
    },
    {
      header: "Data de admissão",
      key: "admissionDate",
    },

    {
      header: "Operações",
      key: "actions",
    },
  ];

  const [showLoading, setShowLoading] = useState(false);
  const [isBtnDisabled, setisBtnDisabled] = useState(false);
  const [company, setCompany] = useState([]);
  const [gender, setGender] = useState([]);
  const [maritalstatus, setMaritalStatus] = useState([]);
  const [academicDegree, setAcademicDegree] = useState([]);
  const [position, SetPositionEmployee] = useState([]);

  const getSchema = () => {
    switch (mode) {
      case "UPDATE":
        return employeeSchema;
      case "CREATE":
        return employeeSchema;
      default:
        return employeeSchema;
    }
  };
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    control,
    formState: { errors },
  } = useForm({ resolver: yupResolver(getSchema()), mode: "onSubmit" });
  const navigate = useNavigate();
  const params = useParams();

  const delay = (ms) => new Promise((res) => setTimeout(res, ms));

  async function onSubmit(data) {
    try {
      //TODO: Tens de validar se o funcionário tem  é maior de idade antes de tentar criar ou actualizar

      setisBtnDisabled(true);
      var result = null;

      if (formMode === "UPDATE") {
        result = await http.post(url, data);
        toast.success("Operação realizada com sucesso!");
        await delay(2000); // breaking

        navigate("../actualizar-funcionario/" + result?.data?.id);
      } else {
        result = await http.post(url, data);

        if (result?.data.msg !== undefined) {
          toast.error("Operação não realizada!: \n " + result?.data.msg);
          setisBtnDisabled(false);
        } else {
          toast.success("Operação realizada com sucesso!");
          await delay(2000); // breaking

          navigate("../actualizar-funcionario/" + result?.data?.id);
        }
      }
    } catch (error) {
      setisBtnDisabled(false);
      toast.error(error.response?.data.message);
    }
  }

  const fetchComponies = async () => {
    const result = await http.get(`/companies/${employeeId}`);

    console.log("xxxxxxx", result);

    const companies_data = result.data?.map((c) => ({
      id: c.id,
      company: c.company,
      department: c.department,
      position: c.position,
      email: c.email,
      salary: c.salary,
      coinType: c.coinType,
      admissionDate: c.admissionDate,
      actions: (
        <>
          <ul style={{ display: "flex" }}>
            <li style={{ "margin-right": "10px" }}>
              <Edit24
                onClick={(e) => {
                  setShowModalCompanyEdit((prev) => ({
                    ...prev,

                    modalval: true,

                    id: c.id,
                    company: c.company,
                    department: c.department,
                    position: c.position,
                    email: c.email,
                    salary: c.salary,
                    coinType: c.coinType,
                    admissionDate: c.admissionDate,
                    resignationDate: c.resignationDate,
                    employee: { id: employee.id },
                  }));
                }}
              />
            </li>

            <li>
              <TrashCan24
                onClick={(e) => {
                  /*  setShowModal((prev) => ({
                     ...prev,

                     modalval: true,
                     hId: x.id,
                     hdesc: x.description,
                   })); */
                }}
              />
            </li>
          </ul>
        </>
      ),
    }));

    setCompanies(companies_data);
  };

  useEffect(() => {
    if (formMode === "UPDATE") {
      const fetchData = async () => {
        try {
          const result = await http.get(`/employees/${employeeId}`);

          const fields = [
            "id",
            "firstName",
            "surName",
            "gender",
            "academicDegree",

            "status",

            "phoneNumber",
            "faxNumber",

            "dateOfBirth",

            "biCode",
            "maritalStatus",
            "nuit",

            "inss",
            "category",
          ];

          fields.forEach((field) => setValue(field, employee[field]));
          setEmployee(result?.data);

          await fetchComponies();
        } catch (error) {
          toast.error(error.response?.data.message);
        }
      };

      fetchData();
    }
    setShowSuccess(false);
  }, [formMode]);

  useEffect(() => {
    reset(employee);
  }, [employee]);

  //fetch Department

  useEffect(() => {
    (async () => {
      let response = await fetchDepartment();
      if (response.success) {
        setDepartment(response.data);
      }
    })();
  }, []);

  const fetchDepartment = async () => {
    try {
      const response = await http.get("/departments");
      let items = response.data.map((e) => {
        return { id: e.id, text: e.description, children: e.children };
      });
      return { success: true, data: items };
    } catch (error) {
      return { success: false };
    }
  };

  //fetch Company

  useEffect(() => {
    (async () => {
      let response = await fetchCompany();
      if (response.success) {
        setCompany(response.data);
      }
    })();
  }, []);

  const fetchCompany = async () => {
    try {
      const response = await http.get("/company");
      let items = response.data.map((e) => {
        return { id: e.id, text: e.description, children: e.children };
      });
      return { success: true, data: items };
    } catch (error) {
      return { success: false };
    }
  };

  //Gender
  useEffect(() => {
    (async () => {
      let response = await fetchGender();
      if (response.success) {
        setGender(response.data);
      }
    })();
  }, []);

  const fetchGender = async () => {
    try {
      const response = await http.get("/gender");
      let items = response.data.map((e) => {
        return { id: e.code, text: e.description, children: e.children };
      });
      return { success: true, data: items };
    } catch (error) {
      return { success: false };
    }
  };

  //Marital status
  useEffect(() => {
    (async () => {
      let response = await fetchMaritalStatus();
      if (response.success) {
        setMaritalStatus(response.data);
      }
    })();
  }, []);

  const fetchMaritalStatus = async () => {
    try {
      const response = await http.get("/maritalstatus");
      let items = response.data.map((e) => {
        return { id: e.code, text: e.description, children: e.children };
      });
      return { success: true, data: items };
    } catch (error) {
      return { success: false };
    }
  };

  //Position Employee
  useEffect(() => {
    (async () => {
      let response = await fetchPositionEmployee();
      if (response.success) {
        SetPositionEmployee(response.data);
      }
    })();
  }, []);

  const fetchPositionEmployee = async () => {
    try {
      const response = await http.get("/positioemployee");
      let items = response.data.map((e) => {
        return { id: e.id, text: e.description, children: e.children };
      });
      return { success: true, data: items };
    } catch (error) {
      return { success: false };
    }
  };

  // Academic degree
  useEffect(() => {
    (async () => {
      let response = await fetchAcademicDegree();
      if (response.success) {
        setAcademicDegree(response.data);
      }
    })();
  }, []);

  const fetchAcademicDegree = async () => {
    try {
      const response = await http.get("/academicdegree");
      let items = response.data.map((e) => {
        return { id: e.id, text: e.description, children: e.children };
      });
      return { success: true, data: items };
    } catch (error) {
      return { success: false };
    }
  };

  //Category Employee
  useEffect(() => {
    (async () => {
      let response = await fetchEmployeeCategory();
      if (response.success) {
        setCategory(response.data);
      }
    })();
  }, []);

  const fetchEmployeeCategory = async () => {
    try {
      const response = await http.get("/employee/categoty");
      let items = response.data.map((e) => {
        return { id: e.id, text: e.description, children: e.children };
      });
      return { success: true, data: items };
    } catch (error) {
      return { success: false };
    }
  };

  const setDateOfBirth = (e) => {
    setValue("dateOfBirth", e.target.value);
    employee.dateOfBirth = e.target.value;
  };

  function onCompanyChange(e) {
    company_of_employee.company = e.selectedItem.text;
    setValue("company", e.selectedItem.text);
  }
  function onDepartmentChange(e) {
    company_of_employee.department = e.selectedItem.text;
    setValue("department", e.selectedItem.text);
  }
  function onPositionChange(e) {
    company_of_employee.position = e.selectedItem.text;
    setValue("position", e.selectedItem.text);
  }
  const setAdmissionDate = (e) => {
    company_of_employee.admissionDate = e.target.value;
    setValue("admissionDate", e.target.value);
  };

  function onEditCompanyChange(e) {
    showModalCompanyEdit.company = e.selectedItem.text;
    setValue("company", e.selectedItem.text);
  }
  function onEditDepartmentChange(e) {
    showModalCompanyEdit.department = e.selectedItem.text;
    setValue("department", e.selectedItem.text);
  }
  function onEditPositionChange(e) {
    showModalCompanyEdit.position = e.selectedItem.text;
    setValue("position", e.selectedItem.text);
  }
  const setEditAdmissionDate = (e) => {
    showModalCompanyEdit.admissionDate = e.target.value;
    setValue("admissionDate", e.target.value);
  };
  const setEditResignationDate = (e) => {
    showModalCompanyEdit.resignationDate = e.target.value;
    setValue("resignationDate", e.target.value);
  };

  function onGenderChange(e) {
    employee.gender = e.selectedItem.id;
    setValue("gender", e.selectedItem.id);
  }

  function onMaritalStatusChange(e) {
    employee.maritalStatus = e.selectedItem.id;
    setValue("maritalStatus", e.selectedItem.id);
  }
  function onAcademicDegreeChance(e) {
    employee.academicDegree = e.selectedItem.text;
    setValue("academicDegree", e.selectedItem.text);
  }

  function onCategoryCategoryChange(e) {
    employee.category = e.selectedItem.text;
    setValue("category", e.selectedItem.text);
    console.log("cate", employee.category);
  }

  const onSaveCompany = async () => {
    var data = await http.post("/addcompany", company_of_employee);

    toast.success("Operação realizada com sucesso!");
    await fetchComponies();
    setShowModalCompanyEdit((prev) => ({
      ...prev,

      modalval: false,
    }));
  };

  const onEditCompany = async () => {
    var data = await http.post("/addcompany", showModalCompanyEdit);

    toast.success("Operação realizada com sucesso!");
    await fetchComponies();
    setShowModalCompanyEdit((prev) => ({
      ...prev,

      modalval: false,
    }));
  };

  function handleChange(e) {
    const { name, value } = e.target;

    setcompany_of_employee((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }

  function handleEditChange(e) {
    const { name, value } = e.target;

    setShowModalCompanyEdit((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }

  async function onSaveVocationPlan() {
    setisBtnDisabled(true);
    var data = null;

    if (showModal.modaltype === "CREATE") {
      try {
        //TODO create
        data = await http.post("/absencemanagement", {
          managementId: `${params.managementId}`,
          employee: { id: `${employeeId}` },
          company: `${employee.company}`,
          department: `${employee.department}`,
          admissionDate: `${employee.admissionDate}`,
          daysAvalableForYear: `${daysAvalableForYear}`,
          daysToEnjoy: `${data.daysToEnjoy}`,
          availableDays: `${data.availableDays}`,
          employeeName: `${employee.firstName}` + `${employee.surName}`,

          enjoyedDays: `${management.daysToEnjoy}`,
          availableDays: `${daysAvalableForYear}` - `${management.daysToEnjoy}`,
        });

        toast.success("Operação realizada com sucesso!");
        await delay(2000); // breaking

        navigate("../actualizar-funcionario/" + data?.id);
      } catch (error) {
        toast.error("Erro na operacao realizada!");
      }
    } else {
      // data = await http.post(url, data);

      showModal((prev) => ({
        ...prev,

        modalval: false,
        modaltype: "",
      }));

      toast.success("Operação realizada com sucesso!");
    }
  }

  function onEmployeeChange(e) {
    try {
      setavailableDays(null);

      SetAdmissionDate(employee.admissionDate);
      setValue("admissionDate");
      var newac = new Date(actutal);
      var newdate = new Date(admissionDate);

      var diff = newac - newdate;

      var diffmilyear = 1000 * 60 * 60 * 24 * 365.25;

      var diffyear = Math.floor(diff / diffmilyear);

      if (diffyear >= 3 && diffyear) {
        setdaysAvalableForYear(30);
        // setValue('daysAvalableForYear', 30)

        setValue("availableDays", 30 - management.enjoyedDays);
        setavailableDays(30 - management.enjoyedDays);
      } else if (diffyear >= 2) {
        setdaysAvalableForYear(24);
        //setValue('daysAvalableForYear', 24)

        setValue("availableDays", 24 - management.enjoyedDays);
        setavailableDays(24 - management.enjoyedDays);
      } else if (diffyear >= 0) {
        setdaysAvalableForYear(12);
        //setValue('daysAvalableForYear', 12)

        setValue("availableDays", 12 - management.enjoyedDays);
        setavailableDays(12 - management.enjoyedDays);
      } else {
        setdaysAvalableForYear(0);
        setValue("availableDays", 0);
        setavailableDays(0);
      }
    } catch (error) {
      toast.error(error.response?.data.message);
    }
  }

  const myStyle = {
    marginTop: "100px;",
  };

  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (index) => {
    setActiveTab(index);
  };

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Column sm={4} md={6} lg={4} className="required">
            <TextInput
              id="firstName"
              labelText="Nome"
              maxLength={64}
              {...register("firstName")}
            />
            <p className="field-error">{errors.firstName?.message}</p>
          </Column>
          <Column sm={4} md={6} lg={4} className="required">
            <TextInput
              id="surName"
              labelText="Apelido"
              maxLength={50}
              {...register("surName")}
            />
            <p className="field-error">{errors.surName?.message}</p>
          </Column>
          <Column sm={4} md={6} lg={4} className="required">
            <Dropdown
              id="gender"
              titleText="Género"
              label={formMode == "UPDATE" ? employee.gender : "Selecione"}
              items={gender}
              onChange={onGenderChange}
              name="gender"
              itemToString={(item) => (item ? item.text : "")}
            />
            <p className="field-error">{errors.gender?.message}</p>
          </Column>
        </Row>
        <Row>
          <Column sm={4} md={6} lg={4} className="required">
            <Dropdown
              id="maritalStatus"
              titleText="Estado civil"
              label={
                formMode == "UPDATE" ? employee.maritalStatus : "Selecione"
              }
              items={maritalstatus}
              onChange={onMaritalStatusChange}
              name="maritalStatus"
              itemToString={(item) => (item ? item.text : "")}
            />
            <p className="field-error">{errors.maritalStatus?.message}</p>
          </Column>
          <Column sm={4} md={6} lg={4} className="required">
            {formMode === "UPDATE" ? (
              <DatePicker
                locale="pt"
                dateFormat="d/m/Y"
                datePickerType="single"
              >
                <DatePickerInput
                  style={{ width: "330px" }}
                  labelText="Data de nascimento"
                  id="dateOfBirth"
                  name="dateOfBirth"
                  value={employee.dateOfBirth}
                  {...register("dateOfBirth")}
                  onSelect={setDateOfBirth}
                />
              </DatePicker>
            ) : (
              <DatePicker
                locale="pt"
                dateFormat="d/m/Y"
                datePickerType="single"
              >
                <DatePickerInput
                  style={{ width: "330px" }}
                  labelText="Data de nascimento"
                  id="dateOfBirth"
                  name="dateOfBirth"
                  {...register("dateOfBirth")}
                  onSelect={setDateOfBirth}
                />
              </DatePicker>
            )}
            <p className="field-error">{errors.maritalStatus?.message}</p>
          </Column>

          <Column sm={4} md={6} lg={4}>
            <TextInput
              id="biCode"
              labelText="Número de BI"
              {...register("biCode")}
              maxLength={13}
            />
          </Column>
        </Row>
        <Row>
          <Column sm={4} md={6} lg={4}>
            <TextInput
              id="nuit"
              labelText="NUIT"
              {...register("nuit")}
              maxLength={9}
            />
          </Column>
          <Column sm={4} md={6} lg={4}>
            <TextInput
              id="phoneNumber"
              labelText="Contacto"
              {...register("phoneNumber")}
              maxLength={50}
            />
          </Column>
          <Column sm={4} md={6} lg={4}>
            <TextInput
              id="faxNumber"
              labelText="Contacto Opcional"
              {...register("faxNumber")}
              maxLength={50}
            />
          </Column>
        </Row>
        <Row>
          <Column sm={4} md={6} lg={4}>
            <Dropdown
              id="academicDegree"
              titleText="Nível académico"
              label={
                formMode == "UPDATE" ? employee.academicDegree : "Selecione"
              }
              items={academicDegree}
              onChange={onAcademicDegreeChance}
              name="academicDegree"
              itemToString={(item) => (item ? item.text : "")}
            />
          </Column>
          <Column sm={4} md={6} lg={4}>
            <TextInput
              id="inss"
              labelText="INSS"
              {...register("inss")}
              maxLength={20}
            />
          </Column>
          <Column sm={4} md={6} lg={4}>
            <Dropdown
              id="category"
              titleText="Categoria"
              label={formMode == "UPDATE" ? employee.category : "Selecione"}
              items={categories}
              onChange={onCategoryCategoryChange}
              name="category"
              itemToString={(item) => (item ? item.text : "")}
            />
          </Column>
        </Row>
        <Row className="mt-2 mb-1">
          <Column sm={12} md={6} lg={6}>
            <ButtonSet>
              <Button
                disabled={isBtnDisabled}
                type="submit"
                renderIcon={SaveModel24}
                iconDescription="Save"
              >
                Salvar
                {showLoading == true ? (
                  <Loading
                    description="Active loading indicator"
                    withOverlay={false}
                    small
                  />
                ) : (
                  ""
                )}
              </Button>
              <Button
                onClick={() => navigate("../funcionarios")}
                renderIcon={ArrowLeft24}
              >
                Voltar
              </Button>
            </ButtonSet>
          </Column>
        </Row>
        {formMode == "UPDATE" ? (
          <></>
        ) : (
          /*   <Button
              type="button"
              onClick={(e) => {
                setShowModal((prev) => ({
                  ...prev,
  
                  modaltype: "CREATE",
                  modalval: true,
                  hidebtn: true,
                }));
                setModaltype("Criar");
              }}
            >
              Adicionar ✨
            </Button> */

          ""
        )}
        <Modal
          modalHeading={modaltype + " Plano de Ferias"}
          open={showModal.modalval}
          onRequestClose={(e) => {
            setShowModal(false);
          }}
          primaryButtonText="Salvar"
          secondaryButtonText="Cancelar"
          onRequestSubmit={handleSubmit(() => {
            onSaveVocationPlan();
          })}
        >
          <Form>
            <Row>
              <Column sm={4} md={6} lg={4}>
                <TextInput
                  id="employee.company"
                  labelText="Empresa"
                  value={employee.company}
                  disabled
                />
              </Column>
              <Column sm={4} md={6} lg={4}>
                <TextInput
                  id="employee.department"
                  labelText="Departamento"
                  value={employee.department}
                  disabled
                />
              </Column>

              <Column sm={4} md={6} lg={4}>
                <TextInput
                  id="employee.firstName"
                  labelText="Funcionario"
                  value={employee.firstName + " " + employee.surName}
                  disabled
                />
              </Column>

              <Column sm={4} md={6} lg={4}>
                <TextInput
                  id="admissionDate"
                  labelText="Data de admissao"
                  value={employee.admissionDate}
                  disabled
                />
              </Column>

              <Column sm={4} md={6} lg={4}>
                <TextInput
                  id="daysAvalableForYear"
                  labelText="Dias de ferias disponiveis consoante a data de admissao"
                  value={daysAvalableForYear}
                  disabled
                />
              </Column>

              {showModal.modaltype === "UPDATE" ? (
                <Column sm={4} md={6} lg={4}>
                  <TextInput
                    labelText="Dias gozadas"
                    value={management.enjoyedDays}
                    disabled
                  />
                </Column>
              ) : (
                ""
              )}
              {showModal.modaltype === "CREATE" ? (
                <Column sm={4} md={6} lg={4}>
                  <TextInput
                    labelText="Dias de ferias disponiveis"
                    value={availableDays ?? getValue("availableDays")}
                    onChange={(e) => {
                      e = "" + availableDays;
                    }}
                    disabled
                  />
                </Column>
              ) : (
                ""
              )}

              {showModal.modaltype === "UPDATE" ? (
                <Column sm={4} md={6} lg={4}>
                  <TextInput
                    labelText="Dias de ferias disponiveis"
                    value={management.availableDays}
                    disabled
                  />
                </Column>
              ) : (
                ""
              )}
              <Column sm={4} md={6} lg={4} className="required">
                <TextInput
                  id="daysToEnjoy"
                  labelText="Dias a gozar"
                  {...register("daysToEnjoy")}
                />
                <p className="field-error">{errors.daysToEnjoy?.message}</p>
              </Column>
            </Row>

            <Row className="mt-2">
              <Column sm={12} md={6} lg={6}>
                <ButtonSet>
                  <Button onClick={onEmployeeChange}>
                    Calcular Dias de ferias
                  </Button>
                </ButtonSet>
              </Column>
            </Row>
          </Form>
        </Modal>
      </Form>

      <Modal
        modalHeading={" Adicionar empresa onde trabalha"}
        open={showModalCompany.modalval}
        onRequestClose={(e) => {
          setShowModalCompany(false);
        }}
        primaryButtonText="Salvar"
        secondaryButtonText="Cancelar"
        onRequestSubmit={() => onSaveCompany()}
      >
        <Form style={{ marginBottom: "70px" }}>
          <Row>
            <Column sm={4} md={6} lg={4}>
              <Dropdown
                id="company"
                titleText="Empresa"
                label={
                  formMode == "UPDATE"
                    ? company_of_employee.company
                    : "Selecione"
                }
                items={company}
                onChange={onCompanyChange}
                name="company"
                value={company_of_employee.company}
                itemToString={(item) => (item ? item.text : "")}
              />
            </Column>
            <Column sm={4} md={6} lg={4}>
              <Dropdown
                id="department"
                titleText="Departamento"
                label={
                  formMode == "UPDATE"
                    ? company_of_employee.department
                    : "Selecione"
                }
                items={department}
                onChange={onDepartmentChange}
                name="department"
                value={company_of_employee.department}
                itemToString={(item) => (item ? item.text : "")}
              />
            </Column>
            <Column sm={4} md={6} lg={4}>
              <Dropdown
                id="position"
                titleText="Cargo"
                label={
                  formMode == "UPDATE"
                    ? company_of_employee.position
                    : "Selecione"
                }
                items={position}
                onChange={onPositionChange}
                value={company_of_employee.position}
                name="position"
                itemToString={(item) => (item ? item.text : "")}
              />
            </Column>
          </Row>

          <Row>
            <Column sm={4} md={6} lg={4}>
              <NumberInput
                id="salary"
                label="Salario"
                name="salary"
                value={company_of_employee.salary}
                invalidText="O número não é válido"
                onChange={handleChange}
              />
            </Column>

            <Column sm={4} md={6} lg={4}>
              <TextInput
                id="company_of_employee.coinType"
                labelText="Tipo de Moeda"
                name="coinType"
                value={company_of_employee.coinType}
                onChange={handleChange}
              />
            </Column>

            <Column sm={4} md={6} lg={4}>
              <DatePicker
                locale="pt"
                dateFormat="m/d/Y"
                datePickerType="single"
              >
                <DatePickerInput
                  style={{ width: "100%" }}
                  labelText="Data de admissão"
                  id="company_of_employee.admissionDate"
                  name="admissionDate"
                  value={company_of_employee.admissionDate}
                  onSelect={setAdmissionDate}
                />
              </DatePicker>
            </Column>
          </Row>
          <Row>
            <Column sm={4} md={6} lg={4}>
              <TextInput
                id="company_of_employee.email"
                labelText="Email"
                name="email"
                value={company_of_employee.email}
                onChange={handleChange}
              />
            </Column>
          </Row>
        </Form>
      </Modal>

      <Modal
        modalHeading={"Actualizar empresa onde trabalha"}
        open={showModalCompanyEdit.modalval}
        onRequestClose={(e) => {
          setShowModalCompanyEdit(false);
        }}
        primaryButtonText="Actualizar"
        secondaryButtonText="Cancelar"
        onRequestSubmit={() => onEditCompany()}
      >
        <Form style={{ marginBottom: "70px" }}>
          <Row>
            <Column sm={4} md={6} lg={4}>
              <Dropdown
                id="showModalCompanyEdit.company"
                titleText="Empresa"
                label={
                  formMode == "UPDATE"
                    ? showModalCompanyEdit.company
                    : "Selecione"
                }
                items={company}
                onChange={onEditCompanyChange}
                name="company"
                value={showModalCompanyEdit.company}
                itemToString={(item) => (item ? item.text : "")}
              />
            </Column>
            <Column sm={4} md={6} lg={4}>
              <Dropdown
                id="showModalCompanyEdit.department"
                titleText="Departamento"
                label={
                  formMode == "UPDATE"
                    ? showModalCompanyEdit.department
                    : "Selecione"
                }
                items={department}
                onChange={onEditDepartmentChange}
                name="department"
                value={showModalCompanyEdit.department}
                itemToString={(item) => (item ? item.text : "")}
              />
            </Column>
            <Column sm={4} md={6} lg={4}>
              <Dropdown
                id="showModalCompanyEdit.position"
                titleText="Cargo"
                label={
                  formMode == "UPDATE"
                    ? showModalCompanyEdit.position
                    : "Selecione"
                }
                items={position}
                onChange={onEditPositionChange}
                value={showModalCompanyEdit.position}
                name="position"
                itemToString={(item) => (item ? item.text : "")}
              />
            </Column>
          </Row>
          <Row>
            <Column sm={4} md={6} lg={4}>
              <NumberInput
                id="salary"
                label="Salario"
                name="salary"
                value={showModalCompanyEdit.salary}
                invalidText="O número não é válido"
                onChange={handleEditChange}
              />
            </Column>

            <Column sm={4} md={6} lg={4}>
              <TextInput
                id="showModalCompanyEdit.coinType"
                labelText="Tipo de Moeda"
                name="coinType"
                value={showModalCompanyEdit.coinType}
                onChange={handleEditChange}
              />
            </Column>

            <Column sm={4} md={6} lg={4}>
              <DatePicker
                locale="pt"
                dateFormat="m/d/Y"
                datePickerType="single"
              >
                <DatePickerInput
                  style={{ width: "100%" }}
                  labelText="Data de admissão"
                  id="showModalCompanyEdit.admissionDate"
                  name="admissionDate"
                  value={showModalCompanyEdit.admissionDate}
                  onSelect={setEditAdmissionDate}
                />
              </DatePicker>
            </Column>
          </Row>

          <Row>
            <Column sm={4} md={6} lg={4}>
              <TextInput
                id="showModalCompanyEdit.email"
                labelText="Email"
                name="email"
                value={showModalCompanyEdit.email}
                onChange={handleEditChange}
              />
            </Column>
            <Column sm={4} md={6} lg={4}>
              <DatePicker
                locale="pt"
                dateFormat="m/d/Y"
                datePickerType="single"
              >
                <DatePickerInput
                  style={{ width: "100%" }}
                  labelText="Data de demissao"
                  id="showModalCompanyEdit.resignationDate"
                  name="resignationDate"
                  value={showModalCompanyEdit.resignationDate}
                  onSelect={setEditResignationDate}
                />
              </DatePicker>
            </Column>
          </Row>
        </Form>
      </Modal>

      <hr></hr>

      <Tabs
        className="Navmargin"
        selected={activeTab}
        onSelectionChange={handleTabChange}
      >
        <Tab label="Empresas">
          <Card title="Empresas onde trabalha" style={myStyle}>
            <div className="form-buttons">
              <p></p>
              <Button
                kind="ghost"
                type="button"
                onClick={(e) => {
                  setcompany_of_employee(companyDefault);

                  setShowModalCompany((prev) => ({
                    ...prev,

                    modaltype: "CREATE",
                    modalval: true,
                    hidebtn: true,
                  }));
                }}
                renderIcon={Add16}
              >
                Adicionar
              </Button>
            </div>

            <DataTable
              rows={companies}
              headers={CompanytableHeaders}
              isSortable
              useZebraStyles
            >
              {({ rows, headers, getHeaderProps, getTableProps }) => (
                <TableContainer>
                  <Table {...getTableProps()}>
                    <TableHead>
                      <TableRow>
                        {headers.map((header) => (
                          <TableHeader {...getHeaderProps({ header })}>
                            {header.header}
                          </TableHeader>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows.map((row) => (
                        <TableRow key={row.id}>
                          {row.cells.map((cell) => (
                            <TableCell key={cell.id}>
                              {cell.value?.content ?? cell.value}
                            </TableCell>
                          ))}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </DataTable>
          </Card>
        </Tab>
        <Tab label="Contactos">Content for Tab 2</Tab>
      </Tabs>
    </>
  );
};

export default EmployeeForm;
