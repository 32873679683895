import {
  Button,
  DataTable,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
  OverflowMenuItem,
  OverflowMenu,
  DatePicker,
  DatePickerInput,
  Column,
  Row,
  NumberInput,
  TextInput,
  Dropdown,
  Modal,
  Form,
} from "carbon-components-react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import http from "../../http-common";
import { Add16, Edit24, TrashCan24 } from "@carbon/icons-react";
import Card from "../../components/Card/Card";
import TablePagination from "../../components/Pagination/Pagination";
import { toast } from "react-toastify";

const tableHeaders = [
  {
    header: "Empresa",
    key: "name",
  },
  {
    header: "Descricao",
    key: "description",
  },
  {
    header: "Operações",
    key: "actions",
  },
];

 

const ClientCompany = ({ mode, clientId }) => {
  const [companies, setCompanies] = useState([]);
  const [count, setCount] = useState(0);
  const [size, setSize] = useState(20);
  const [type, setType] = useState([]);
  const [company, setCompany] = useState([]);

  const [isBtnDisabled, setisBtnDisabled] = useState(false);

  const [formMode] = useState(mode);

  //New
  const [showModal, setShowModal] = useState({
    modalval: false,
    hidebtn: false,
    modaltype: "",
  });
  const [showModalCompany, setShowModalCompany] = useState({
    modalval: false,
    hidebtn: false,
    modaltype: "",
  });
  const [showModalCompanyEdit, setShowModalCompanyEdit] = useState({
    modalval: false,

    id: 0,
    name: "",
    description: "",
    client: { id: clientId },
  });
  //end

  //Int attributs
  const companyDefault = {
    id: 0,
    name: "",
    description: "",
    client: { id: clientId },
  };

  const [company_of_employee, setcompany_of_employee] =
    useState(companyDefault);
  //End

  const {
    setValue,
    formState: { errors },
  } = useForm();

  const navigate = useNavigate();

  const featchData = async () => {
    try {
      const response = await http.get(`/companies/client/${clientId}`);
      getCompanytData(response);
    } catch (error) {}
  };

  const getCompanytData = (response) => {
    //try {
    const data = response?.data?.map((company) => ({
      id: company.id,
      name: company.name,
      description: company.description,

      actions: (
        <>
          <ul style={{ display: "flex" }}>
            <li style={{ "margin-right": "10px" }}>
              <Edit24
                onClick={(e) => {
                  setShowModalCompanyEdit((prev) => ({
                    ...prev,

                    modalval: true,

                    id: company.id,
                    name: company.name,
                    description: company.description,

                    client: { id: clientId },
                  }));
                }}
              />
            </li>
          </ul>
        </>
      ),
    }));
    setCompanies(data);
    setCount(response.data.totalElements);
     
  };

  const onPageChange = async ({ page, pageSize }) => {
    try {
      var pag = page - 1;
      const response = await http.get(`/companies/client/${clientId}`);
      getCompanytData(response);
    } catch (error) {}
  };

  useEffect(() => {
    featchData();
  }, []);

  

  //fetch Company

  useEffect(() => {
    (async () => {
      let response = await fetchCompany();
      if (response.success) {
        setCompany(response.data);
      }
    })();
  }, []);

  const fetchCompany = async () => {
    try {
      const response = await http.get("/company");
      let items = response.data.map((e) => {
        return { id: e.id, text: e.description, children: e.children };
      });
      return { success: true, data: items };
    } catch (error) {
      return { success: false };
    }
  };

  

  const onSaveCompany = async () => {
    var data = await http.post("/companies", company_of_employee);

    toast.success("Operação realizada com sucesso!");
    await featchData();
    setShowModalCompanyEdit((prev) => ({
      ...prev,

      modalval: false,
    }));
  };

  const onEditCompany = async () => {
    var data = await http.post("/companies", showModalCompanyEdit);

    toast.success("Operação realizada com sucesso!");
    await featchData();
    setShowModalCompanyEdit((prev) => ({
      ...prev,

      modalval: false,
    }));
  };

  function handleChange(e) {
    const { name, value } = e.target;

    setcompany_of_employee((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }

  function handleEditChange(e) {
    const { name, value } = e.target;

    setShowModalCompanyEdit((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }

  async function onSaveVocationPlan() {
    setisBtnDisabled(true);
    var data = null;
    //ENd
  }

  function onCompanyChange(e) {
    company_of_employee.name = e.selectedItem.text;
    setValue("name", e.selectedItem.text);
  }
  function onEditCompanyChange(e) {
    showModalCompanyEdit.name = e.selectedItem.text;
    setValue("name", e.selectedItem.text);
  }

  return (
    <>
      <Card title="Consultar Empresa">
        <div className="mb-2 form-buttons">
          <p></p>
          <Button
            kind="ghost"
            type="button"
            onClick={(e) => {
              setcompany_of_employee(companyDefault);

              setShowModalCompany((prev) => ({
                ...prev,

                modaltype: "CREATE",
                modalval: true,
                hidebtn: true,
              }) );

            }}
            renderIcon={Add16}
          >
            Registar Empresa
          </Button>
        </div>

        <DataTable
          rows={companies}
          headers={tableHeaders}
          isSortable
          useZebraStyles
        >
          {({ rows, headers, getHeaderProps, getTableProps }) => (
            <TableContainer>
              <Table {...getTableProps()}>
                <TableHead>
                  <TableRow>
                    {headers.map((header) => (
                      <TableHeader {...getHeaderProps({ header })}>
                        {header.header}
                      </TableHeader>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => (
                    <TableRow key={row.id}>
                      {row.cells.map((cell) => (
                        <TableCell key={cell.id}>
                          {cell.value?.content ?? cell.value}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </DataTable>

        <TablePagination
          currentPageSize={size}
          onPageChange={onPageChange}
          totalItems={count}
        />
      </Card>

      <Modal
        modalHeading={" Registar empresa "}
        open={showModalCompany.modalval}
        onRequestClose={(e) => {
          setShowModalCompany(false);
        }}
        primaryButtonText="Salvar"
        secondaryButtonText="Cancelar"
        onRequestSubmit={() => onSaveCompany()}
      >
        <Form style={{ marginBottom: "100px" }}>
          <Row>
            <Column sm={4} md={6} lg={4}>
              <Dropdown
                id="company_of_employee.name"
                titleText="Empresa"
                label={
                  formMode == "UPDATE" ? company_of_employee.name : "Selecione"
                }
                items={company}
                onChange={onCompanyChange}
                name="name"
                value={company_of_employee.name}
                itemToString={(item) => (item ? item.text : "")}
              />
            </Column>
            <Column sm={8} md={6} lg={8}>
              <TextInput
                id="company_of_employee.description"
                labelText="Descricao"
                name="description"
                value={company_of_employee.description}
                onChange={handleChange}
              />
            </Column>
          </Row>
        </Form>
      </Modal>

      <Modal
        modalHeading={"Actualizar empresa onde trabalha"}
        open={showModalCompanyEdit.modalval}
        onRequestClose={(e) => {
          setShowModalCompanyEdit(false);
        }}
        primaryButtonText="Actualizar"
        secondaryButtonText="Cancelar"
        onRequestSubmit={() => onEditCompany()}
      >
        <Form style={{ marginBottom: "100px" }}>
          <Row>
            <Column sm={4} md={6} lg={4}>
              <Dropdown
                id="showModalCompanyEdit.name"
                titleText="Empresa"
                label={
                  formMode == "UPDATE" ? showModalCompanyEdit.name : "Selecione"
                }
                items={company}
                onChange={onEditCompanyChange}
                value={showModalCompanyEdit.name}
                name="name"
                itemToString={(item) => (item ? item.text : "")}
              />
            </Column>
            <Column sm={4} md={6} lg={4}>
              <TextInput
                id="showModalCompanyEdit.description"
                labelText="Descricao"
                name="description"
                value={showModalCompanyEdit.description}
                onChange={handleEditChange}
              />
            </Column>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default ClientCompany;
