import React from 'react'
import Card from '../../components/Card/Card'
import ClientForm from './ClientForm'

const CreateClientPage = () => {
  return (
    <Card title="Registar Cliente">
      <ClientForm
        mode={'CREATE'}
        url={'/clients'}
        successMessage={'Cliente registado com sucesso'}
        onSuccessNavigateTo={'../clientes'}
      />
    </Card>
  )
}

export default CreateClientPage
