import React from 'react'
import { Button } from 'carbon-components-react'
import { SaveModel24 } from '@carbon/icons-react'

const SubmitButton = ({ type, label, icon, onClick, className }) => {
  return (
    <Button
      renderIcon={icon}
      iconDescription={label}
      onClick={onClick}
      className={className}
      type={type}
    >
      {label}
    </Button>
  )
}

SubmitButton.defaultProps = {
  type: 'submit',
  label: 'Salvar',
  icon: SaveModel24,
}

export default SubmitButton
