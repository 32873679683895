
import React, { useState, useEffect } from 'react'
import { Form } from 'carbon-components-react'
import Card from '../../components/Card/Card'
import {
  Row,
  Column,
  TextInput,
  Button,
  DataTable,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
  TableToolbar,
  TableToolbarContent,
  OverflowMenuItem,
  OverflowMenu,
} from 'carbon-components-react'
import { Link, useNavigate } from 'react-router-dom'
import { Add16, Search32, Edit24 } from '@carbon/icons-react'
import http from '../../http-common'
import TablePagination from '../../components/Pagination/Pagination'
import { useForm } from 'react-hook-form'


const tableHeaders = [
  {
    header: 'Cliente',
    key: 'client',
  },
  {
    header: 'Projecto',
    key: 'project',
  },
  {
    header: 'Previsão de factura',
    key: 'prev_payment_date',
  },
  {
    header: 'Data real da factura',
    key: 'payment_date',
  },
  {
    header: 'Foi faturado ?',
    key: 'is_paid',
  }, {
    header: 'Operações',
    key: 'actions',
  },
]

const LinkList = ({ url, url2 }) => (
  <ul style={{ display: 'flex' }}>
    <li>
      <Link to={url} title="Editar">
        <Edit24 />
      </Link>
    </li>
    &nbsp;&nbsp;&nbsp;
  </ul>
)




const ContractsPage = () => {

  const navigate = useNavigate()
  const [count, setCount] = useState(0)
  const [services, setService] = useState([])
  const [size, setSize] = useState(20)

  const { register, handleSubmit, formState: { errors }, } = useForm()

  const tableActions = (result) => {
    return (
      <OverflowMenu flipped>
        <OverflowMenuItem
          key="optionOne"
          itemText="Edit"
          onClick={() => navigate('../update-payment-term/' + result.id)}
        />
      </OverflowMenu>
    )
  }

  const onSearch = async (data) => {
    try {

      console.log("data",data) 

      if (data.name === '') {
        data.name = "-1"
      }

      if (data.cliente === '') {
        data.cliente = "-1"
      }

      const response = await http.get(`/services/searchpayment/${data.name}/${data.cliente}`)


      getProjectData(response)
    } catch (error) { }
  }


  useEffect(() => {
    (async () => {

      var data = {name: '', cliente: ''}
      onSearch(data);

    })();
  }, []);

  const onPageChange = async ({ page, pageSize }) => {
    try {
      var pag = page - 1
      const response = await http.get(
        '/projects/search/filter?page=' + pag + '&size=' + pageSize,
      )
      getProjectData(response)
    } catch (error) { }
  }


  const getProjectData = (response) => {

    try {

      const data = response.data.content.map((result) => ({

        id: result.id,
        client: result.client,
        project: result.project,
        prev_payment_date: result.prev_payment_date,
        payment_date: result.payment_date,
        is_paid: <label className='textDanger' > {result.is_paid} </label>,
        actions: <LinkList url={'../update-payment-term/' + result.id} />,
      }))


      setService(data)
      setCount(response.data.totalElements)

    } catch (error) {
      console.log("error", error)
    }
  }

  return (

    <Card title="Consultar Facturação">

      <DataTable
        rows={services}
        headers={tableHeaders}
        isSortable
        useZebraStyles
      >
        {
          ({ rows, headers, getHeaderProps, getTableProps }) => (
            <TableContainer>
              <TableToolbar aria-label="data table toolbar">
                <TableToolbarContent>
                  <Form
                    className="data-table-search-form"
                    onSubmit={handleSubmit(onSearch)}
                  >
                    <Row>
                      <Column sm={4} md={3} lg={3}>
                        <TextInput
                          id="name"
                          labelText=""
                          placeholder="Nome do projecto"
                          {...register('name')}
                        />
                      </Column>
                      <Column sm={4} md={3} lg={3}>
                        <TextInput
                          id="name"
                          labelText=""
                          placeholder="Cliente"
                          {...register('cliente')}
                        />
                      </Column>
                      {/* <Column sm={4} md={3} lg={3}>
                        <TextInput
                          id="name"
                          labelText=""
                          placeholder="Previsão de facturar"
                          {...register('date')}
                        />
                      </Column> */}
                    </Row>
                    <Button type="submit" renderIcon={Search32}>
                      Pesquisar
                    </Button>
                  </Form>
                </TableToolbarContent>
              </TableToolbar>
              <Table {...getTableProps()}>
                <TableHead>
                  <TableRow>
                    {headers.map((header) => (
                      <TableHeader {...getHeaderProps({ header })}>
                        {header.header}
                      </TableHeader>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => (
                    <TableRow key={row.id} className="text-danger">
                      {row.cells.map((cell) => (
                        <TableCell key={cell.id}  >
                          {cell.value?.content ?? cell.value}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

          )}
      </DataTable>
      <TablePagination
        currentPageSize={size}
        onPageChange={onPageChange}
        totalItems={count}
      />
    </Card>

  );
};

export default ContractsPage;
