import React from 'react'
import { useParams } from 'react-router-dom'
import Card from '../../components/Card/Card'
import ContractForm from './ContractForm'

const CreateContractPage = () => {
  const params = useParams()

  return (
    <div>
      <Card title="Gestao de Contrato">
        <ContractForm
          mode={'CREATE'}
          url={'/contacts/'}
          successMessage={'Cliente registado com sucesso'}
          onSuccessNavigateTo={'../update_client/' + params.id}
          clientid={params.id}
        />
      </Card>
    </div>
  )
}

export default CreateContractPage
