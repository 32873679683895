import React, { useEffect, useState } from "react";
import { Select, SelectItem } from "carbon-components-react";
import http from "../../http-common";
import { toast } from "react-toastify";
import { Controller } from "react-hook-form";

const DistrictCombo = ({ id, name, label, disabled, control, items }) => {
  const [districts, setDistricts] = useState([]);

  const getDistricts = async (e) => {
    try {
      const response = await http.get("/utils/districts");
      setDistricts(response.data);
    } catch (error) {
      toast.error(error?.response?.data.message);
    }
  };

  useEffect(() => {
    if (items.length === 0) {
      getDistricts();
    } else {
      setDistricts(items);
    }
  }, [items]);

  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <Select {...field} id={id} labelText={label} disabled={disabled}>
          <SelectItem text="" value="" />
          {districts.map((p) => (
            <SelectItem text={p.description} value={p.id} key={p.id} />
          ))}
        </Select>
      )}
    />
  );
};

DistrictCombo.defaultProps = {
  disabled: false,
};

export default DistrictCombo;
