import Card from "../../components/Card/Card";
import { useLocation } from "react-router-dom";
import { ModalWrapper, Button } from "carbon-components-react";
const IFrameDocument = ({ mediaId, documentName }) => {
  const user = localStorage.getItem("principal");
  const { state } = useLocation();

  const urlServer = "http://crm.ologa.com:8000/api/v1/media/file/";

  const urlServerTeste = "http://teste-crm.ologa.com:8005/api/v1/media/file/";

  const localUrl = "http://localhost:8082/api/v1/media/file/";

  const request = new Request(localUrl + mediaId, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${JSON.parse(user).token}`,
    },
    mode: "cors",
    cache: "default",
  });

  return (
    <>
      <Button
        iconDescription="Save"
        onClick={() => {
          fetch(request)
            .then((response) => response.blob())
            .then((blob) => {
              const file = window.URL.createObjectURL(blob);
              window.open(file);
            })
            .catch((err) => {
              console.log(err);
            });
        }}
      >
        {documentName}
      </Button>
    </>
  );
};

export default IFrameDocument;
