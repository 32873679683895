import React, { useState, useEffect } from "react";
import Card from "../../components/Card/Card";
import { Form } from "carbon-components-react";
import {
  Row,
  Column,
  TextInput,
  Button,
  DataTable,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
  TableToolbar,
  TableToolbarContent,
  OverflowMenuItem,
  OverflowMenu,
  DatePicker,
  DatePickerInput,
} from "carbon-components-react";
import { Link, useNavigate } from "react-router-dom";
import { Add16, Search32, Edit24 } from "@carbon/icons-react";
import http from "../../http-common";
import TablePagination from "../../components/Pagination/Pagination";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

const tableHeaders = [
  {
    header: "Empresa assocciada",
    key: "company",
  },
  {
    header: "Nome do projecto",
    key: "name",
  },
  {
    header: "Nome do cliente",
    key: "clientAbreviation",
  },

  {
    header: "Tipo de Projecto",
    key: "typeOfProject",
  },
  {
    header: "Data de previsao de início",
    key: "prevStartDate",
  },
  {
    header: "Data de início",
    key: "startDate",
  },
  {
    header: "Data de previsao de término",
    key: "prevendDate",
  },
  {
    header: "Data de término",
    key: "endDate",
  },
  {
    header: "Valor do Projecto",
    key: "value",
  },
  {
    header: "Valor em outra moeda",
    key: "correspondedValue",
  },
  {
    header: "Operações",
    key: "actions",
  },
];

const LinkList = ({ url, url2 }) => (
  <ul style={{ display: "flex" }}>
    <li>
      <Link to={url} title="Editar">
        <Edit24 />
      </Link>
    </li>
    &nbsp;&nbsp;&nbsp;
  </ul>
);

const ListProjectPage = () => {
  const navigate = useNavigate();

  const [project, setProject] = useState([]);
  const [count, setCount] = useState(0);
  const [size, setSize] = useState(20);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const featchData = async () => {
    try {
      const response = await http.get(
        "/projects/currency/search/filter?size=" + size
      );

      getProjectData(response);
    } catch (error) {}
  };

  const getProjectData = (response) => {
    try {
      const data = response.data.content.map((project) => ({
        id: project.id,
        name: project.name,
        typeOfProject: project.typeOfProject,
        goal: project.goal,
        prevStartDate: project.startDate,

        prevendDate: project.prevendDate,
        endDate: project.endDate,

        value:
          formatMz(project.value) +
          " " +
          (project.valueCoinType ? project.valueCoinType : ""),
        correspondedValue:
          formatMz(project.correspondedValue) +
          " " +
          (project.correspondedValueCoinType
            ? project.correspondedValueCoinType
            : ""),

        clientAbreviation: project.clientAbreviation,
        company: project.company,

        actions: <LinkList url={"../actualizar-projecto/" + project.id} />,
      }));
      setProject(data);
      setCount(response.data.totalElements);
    } catch (e) {
      toast.error("Erro ao consultar projectos:" + e);
      console.log("Error trying to getProjectData:", e);
    }
  };

  const onPageChange = async ({ page, pageSize }) => {
    try {
      var pag = page - 1;
      const response = await http.get(
        "/projects/currency/search/filter?page=" + pag + "&size=" + pageSize
      );
      getProjectData(response);
    } catch (error) {}
  };

  const onSearch = async (data) => {
    console.log("yeee");
    try {
      const response = await http.get(
        `/projects/currency/search/filter?company=${data.company}`
        // &name=${data.name}&prevendDate=${data.prevendDate}`
      );
      getProjectData(response);
      console.log("Filter", response.data);
    } catch (error) {}
  };

  useEffect(() => {
    featchData();
  }, []);

  function setEndDate(e) {
    project.prevendDate = e.target.value;
    setValue("prevendDate", e.target.value);
    console.log("Data:", project.prevendDate);
  }
  const tableActions = (project) => {
    return (
      <OverflowMenu flipped>
        <OverflowMenuItem
          key="optionOne"
          itemText="Edit"
          onClick={() => navigate("../actualizar-projecto/" + project.id)}
        />
      </OverflowMenu>
    );
  };

  // change format for Project Value
  function formatMz(num) {
    if (num != null) {
      var p = num.toFixed(2).split(".");
      return (
        p[0]
          .split("")
          .reverse()
          .reduce(function (acc, num, i, orig) {
            return num + (num != "-" && i && !(i % 3) ? "." : "") + acc;
          }, "") +
        "," +
        p[1]
      );
    } else {
      return "";
    }
  }

  const porjectSerach_default = {
    name: "",
    clientCompany: "",
    prevendDate: "",
  };

  const [porjectSerach, serPjectSerach] = useState(porjectSerach_default);

  function hendleSerach(e) {
    const { name, value } = e.target;

    serPjectSerach((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }

  const onSearchAn = async () => {
    try {
      console.log(porjectSerach);
      const response = await http.get("/projects/search ", porjectSerach);

      // const response = await http.get(`/projects/search`, porjectSerach);

      getProjectData(response);
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <Card title="Consultar Projectos">
      <DataTable
        rows={project}
        headers={tableHeaders}
        isSortable
        useZebraStyles
      >
        {({ rows, headers, getHeaderProps, getTableProps }) => (
          <TableContainer>
            <TableToolbar aria-label="data table toolbar">
              <TableToolbarContent>
                <Form
                  className="data-table-search-form"
                  onSubmit={handleSubmit(onSearch)}
                >
                  <Row>
                    <Column sm={4} md={4} lg={4}>
                      <TextInput
                        id="company"
                        labelText=""
                        placeholder="Nome da empresa"
                        {...register("company")}
                      />
                    </Column>
                    <Column sm={4} md={4} lg={4}>
                      <TextInput
                        id="name"
                        labelText=""
                        placeholder="Nome do projecto"
                        {...register("name")}
                      />
                    </Column>

                    <Column sm={4} md={4} lg={4}>
                      <DatePicker
                        locale="pt"
                        dateFormat="d/m/Y"
                        datePickerType="single"
                      >
                        <DatePickerInput
                          id="prevendDate"
                          name="prevendDate"
                          placeholder="Data da provisao de término"
                          onSelect={setEndDate}
                        />
                      </DatePicker>
                    </Column>
                  </Row>
                  <Button type="submit" renderIcon={Search32}>
                    Pesquisar
                  </Button>
                </Form>
              </TableToolbarContent>
            </TableToolbar>
            <Table {...getTableProps()}>
              <TableHead>
                <TableRow>
                  {headers.map((header) => (
                    <TableHeader {...getHeaderProps({ header })}>
                      {header.header}
                    </TableHeader>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow key={row.id}>
                    {row.cells.map((cell) => (
                      <TableCell key={cell.id}>
                        {cell.value?.content ?? cell.value}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </DataTable>
      <TablePagination
        currentPageSize={size}
        onPageChange={onPageChange}
        totalItems={count}
      />
    </Card>
  );
};
export default ListProjectPage;
