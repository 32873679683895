import {
  Button,
  DataTable,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
  OverflowMenuItem,
  OverflowMenu,
  DatePicker,
  DatePickerInput,
  Column,
  Row,
  NumberInput,
  TextInput,
  Dropdown,
  Modal,
  Form,
} from "carbon-components-react";
import { useEffect, useState, useContext } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, Link } from "react-router-dom";
import http from "../../http-common";
import { Add16, Edit24, Search32 } from "@carbon/icons-react";
import Card from "../../components/Card/Card";
import TablePagination from "../../components/Pagination/Pagination";
import { toast } from "react-toastify";

const tableHeaders = [
  {
    header: "Nome",
    key: "employeeName",
  },
  {
    header: "Cargo",
    key: "description",
  },

  {
    header: "Empresa",
    key: "startDate",
  }, 
  {
    header: "Operações",
    key: "actions",
  },
];

const LinkList = ({ url, url2 }) => (
  <ul style={{ display: "flex" }}>
    <li>
      <Link to={url} title="Editar">
        <Edit24 />
      </Link>
    </li>
    &nbsp;&nbsp;&nbsp;
  </ul>
);




const ProjectEmployee = ({ projectId }) => {
  const [service, setservice] = useState({
    description: "",
  });

  const [showModalToAddEmployee, setShowModalCompanyEdit] = useState({
    modalval: false,
    hidebtn: false,
    modaltype: "",
  });

  const [employees, setEmployees] = useState([]);
  const [services, setServices] = useState([]);
  const [count, setCount] = useState(0);
  const [size, setSize] = useState(20);
  const [isLoading, setIsLoading] = useState(true);
  const [type, setType] = useState([]);

  const {

    setValue,

    formState: { errors },
  } = useForm();

  const navigate = useNavigate();



  //Service type
  const getAllServices = async () => {
    const response = await http.get("/servicetypes");
    setType(response.data);
    setIsLoading(false);
  };


  useEffect(() => {
    (async () => {
      let response = await fetchEmployee();
      if (response.success) {
        setEmployees(response.data);
      }
    })();
  }, []);

  const fetchEmployee = async () => {
    try {
      const response = await http.get("/employees");
      let items = response.data.map((e) => {
        return {
          id: e.id,
          text: e.firstName + "   " + e.surName,
          children: e.children,
        };
      });
      return { success: true, data: items };
    } catch (error) {
      return { success: false };
    }
  };




  const featchData = async () => {
    try {
      const response = await http.get(`/employee_company/${projectId}`);

      getserviceDate(response);
    } catch (error) { }
  };

  const getserviceDate = (response) => {
    try {


      const data = response?.data?.map((x) => ({

        id: x.id,
        employeeName: x.employeeName,

        actions: (
          <>
            <ul style={{ display: "flex" }}>
              <li style={{ "margin-right": "10px" }}>
                <Edit24
                  onClick={(e) => {

 
                    setEmployee_company((prev) => ({
                      ...prev,
                      id: x.id,
                      employeeName: x.employeeName,
                      projectId: x.projectId
                    }));


                    setShowModalCompanyEdit((prev) => ({
                      ...prev,

                      modalval: true,



                    }));
                  }}
                />
              </li>
            </ul>
          </>
        ),

      }));
      setServices(data);
      setCount(response.data.totalElements);
    } catch (error) {
    }
  };

  const onPageChange = async ({ page, pageSize }) => {
    try {
      var pag = page - 1;
      const response = await http.get(`/services/projects/${projectId}`);
      getserviceDate(response);
    } catch (error) { }
  };

  useEffect(() => {
    getAllServices();
    featchData();
  }, []);

  const tableActions = (client) => {
    return (
      <OverflowMenu flipped>
        <OverflowMenuItem
          key="optionOne"
          itemText="Edit"
          onClick={() => navigate("../update-service/" + client.id)}
        />
      </OverflowMenu>
    );
  };


  function onEmployeeChange(e) {
    employee_company.employeeId = e.selectedItem.id;
    setValue("employeeId", e.selectedItem.id);

    employee_company.employeeName = e.selectedItem.text;
    setValue("employeeName", e.selectedItem.text);
  }


  const EmployeeCompanyToAdd = {
    id: 0,
    projectId: 0,
    employeeId: 0,
    employeeName: ""
  };

  const [employee_company, setEmployee_company] = useState(EmployeeCompanyToAdd);

  const onAddEmployee = async () => {

    employee_company.projectId = projectId;
    var data = await http.post("/employee_company", employee_company);



    toast.success("Operação realizada com sucesso!");
    await featchData();
    setShowModalCompanyEdit((prev) => ({
      ...prev,

      modalval: false,
    }));
  };

  // change format for Project Value
  function formatMz(num) {
    var p = num.toFixed(2).split(".");
    return (
      p[0]
        .split("")
        .reverse()
        .reduce(function (acc, num, i, orig) {
          return num + (num != "-" && i && !(i % 3) ? "." : "") + acc;
        }, "") +
      "," +
      p[1]
    );
  }

  return (

    <>
      <Modal
        modalHeading={" Adicionar Funcionário "}
        open={showModalToAddEmployee.modalval}
        onRequestClose={(e) => {
          setShowModalCompanyEdit(false);
        }}
        primaryButtonText="Adicionar"
        secondaryButtonText="Cancelar"
        onRequestSubmit={() => onAddEmployee()}
      >
        <Form style={{ marginBottom: "100px" }}>


          <Dropdown
            id="employee.id"
            name="employee.id"
            label="Selecione"
            titleText="Responsável pelo projecto"
            items={employees}
            onChange={onEmployeeChange}
            itemToString={(item) => (item ? item.text : "")}
          />

          <br></br>

          <br></br>

          <br></br>
        </Form>

      </Modal>




      <Card title="Responsáveis pelo projecto">
        <div className="mb-2 form-buttons">
          <p></p>
          <Button
            kind="ghost"
            type="button"
            onClick={(e) => {
              setEmployee_company(EmployeeCompanyToAdd);

              setShowModalCompanyEdit((prev) => ({
                ...prev,

                modalval: true,
                hidebtn: true,
              }));

            }}
            renderIcon={Add16}
          >
            Adicionar Responsável
          </Button>
        </div>

        <DataTable
          rows={services}
          headers={tableHeaders}
          isSortable
          useZebraStyles
        >
          {({ rows, headers, getHeaderProps, getTableProps }) => (
            <TableContainer>
              <Table {...getTableProps()}>
                <TableHead>
                  <TableRow>
                    {headers.map((header) => (
                      <TableHeader {...getHeaderProps({ header })}>
                        {header.header}
                      </TableHeader>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => (
                    <TableRow key={row.id}>
                      {row.cells.map((cell) => (
                        <TableCell key={cell.id}>
                          {cell.value?.content ?? cell.value}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </DataTable>

        <TablePagination
          currentPageSize={size}
          onPageChange={onPageChange}
          totalItems={count}
        />
      </Card>
    </>


  );
};

export default ProjectEmployee;
