import React from 'react'
import { useParams } from 'react-router-dom'
import Card from '../../components/Card/Card'
import ContactForm from './ContactForm'

const UpdateContactPage = () => {
  const params = useParams()

  return (
    <>
      <Card title="Actualizar Contacto">
        <ContactForm
          mode={'UPDATE'}
          url={'/contacts/' + params.id}
          successMessage={'Contacto actualizado com sucesso'}
          onSuccessNavigateTo={'../contactos'}
          contactId={params.id}
        />
      </Card>
    </>
  )
}

export default UpdateContactPage
