import React from "react";
import http from "../../http-common";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Card from "../../components/Card/Card";
import ProjectEmployeePage from "./ProjectEmployeePage";
import ProjectForm from "./ProjectForm";
import ProjectServicePage from "./ProjectServicePage";
import ProjectEmployee from "./ProjectEmployee";
/* import ProjectDocuments from '../UploadsDocuments/ProjectDocuments' */
/* import CreateProjectDocument from '../UploadsDocuments/CreateProjectDocument' */
import { Button } from "carbon-components-react";
import AppModal from "../Application/AppModal";
import IFrameDocument from "../Home/Documents";
import { Tabs, Tab } from "carbon-components-react";

const UpdateProjectPage = () => {
  const params = useParams();
  const [open, setOpen] = useState(false);
  const [openDocs, setOpenDocs] = useState(false);
  const [client, setClientId] = useState();
  const [media, setMedia] = useState({});

  const [mediaList, setMediaList] = useState([]);

  const closeModal = () => {
    setOpen(false);
  };

  /*  useEffect(() => {
    readClientId();
  }, client); */

  const closeModalOpnDcs = () => {
    setOpenDocs(false);
  };

  async function readClientId() {
    const result = await http.get(`/projects/${params.id}`);

    setClientId(result.data?.client?.id);

    console.log(" clientId ", client);
  }

  //View Docs
  const [activeTab, setActiveTab] = useState(0);
  const handleTabChange = (index) => {
    setActiveTab(index);
  };


  const viewDocuments = async () => {
    const response = await http
      .get("/media/contract/" + params.id)
      .then((response) => {
        setMediaList(response.data);
        console.log("File", response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    viewDocuments();
  }, []);

  return (
    <>
      <Card title="Actualizar Projecto">
        <ProjectForm
          mode={"UPDATE"}
          url={"/projects/" + params.id}
          successMessage={"Projecto actualizado com sucesso"}
          onSuccessNavigateTo={"../projectos"}
          projectId={params.id}
          clientid={client}
        />
      </Card>

      <Card>
        <Tabs
          className="Navmargin"
          selected={activeTab}
          onSelectionChange={handleTabChange}
        >
          <Tab label="Actividades">
            <ProjectServicePage projectId={params.id} />
          </Tab>
          <Tab label="Responsáveis">
            <ProjectEmployee projectId={params.id} />
          </Tab>
          <Tab label="Documentos">

            <Button onClick={() => setOpen(true)}>Carregar documentos</Button>
            <AppModal
              mode={"UPDATE"}
              url={"/upload/contract/" + params.id}
              setClose={closeModal}
              setOpen={open}
              title="Upload de Documentos do projecto"
            />
            {mediaList.map((document) => (
              <div className="cds--file__container">
                <br />
                <IFrameDocument
                  mediaId={document.id}
                  documentName={" " + document.name.toString().split("-")[0]}
                />
              </div>
            ))}
          </Tab>
        </Tabs>
      </Card>





    </>
  );
};

export default UpdateProjectPage;
