import {
  Button,
  Form,
  TextInput,
  Row,
  Column,
  ButtonSet,
  Dropdown,
  Loading,
  DatePicker,
  DatePickerInput,
} from "carbon-components-react";
import React from "react";
import { useEffect, useState, useContext } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import http from "../../http-common";
import { SaveModel24 } from "@carbon/icons-react";
import ProvinceCombo from "../../components/Combos/ProvinceCombo";
import { licenseCRMSchema } from "../../services/SchemaService";
import { yupResolver } from "@hookform/resolvers/yup";

const LicenseManagementForm = ({
  disabled,
  mode,
  url,
  provinceId,
  licensaId,
}) => {
  const [formMode] = useState(mode);
  const [showSuccess, setShowSuccess] = useState(false);
  const [licensa, setLicensa] = useState({
    typeOfLicence: "",
    description: "",
    version: "",
    dateOfPurchase: "",
    expirationDate: "",
    numberOfLincence: "",
    address: "",
    licenseCost: "",
    paymentForm: "",
    restrictionOfUse: "",
    conditionOfUse: "",
    providerId: "",
    // provider: { id: "", name: "" },
    client: { id: "", abreviation: "" },
  });
  const [type, setType] = useState([]);
  const [provider, setProvider] = useState([]);
  const [payment, setPayment] = useState([]);
  const [client, setClient] = useState([]);

  const [showLoading, setShowLoading] = useState(false);
  const [isBtnDisabled, setisBtnDisabled] = useState(false);

  const getSchema = () => {
    switch (mode) {
      case "UPDATE":
        return licenseCRMSchema;
      case "CREATE":
        return licenseCRMSchema;
      default:
        return licenseCRMSchema;
    }
  };

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    watch,
    control,
    formState: { errors },
  } = useForm({ resolver: yupResolver(getSchema()), mode: "onSubmit" });

  const delay = (ms) => new Promise((res) => setTimeout(res, ms));

  async function onSubmit(data) {
    try {
      setisBtnDisabled(true);
      var _data = null;

      if (formMode === "UPDATE") {
        _data = await http.post(url, data);
        toast.success("Operação realizada com sucesso!");
        await delay(2000); // breaking
        navigate("../licensas");
      } else {
        _data = await http.post(url, data);

        if (_data?.data.msg !== undefined) {
          toast.error("Operação não realizada!: \n " + _data?.data.msg);
          setisBtnDisabled(false);
          navigate("../licensas");
        } else {
          toast.success("Operação realizada com sucesso!");
          await delay(2000); // breaking
          navigate("../licensas");
        }
      }
    } catch (error) {
      setisBtnDisabled(false);
      toast.error(error.response?.data.message);
    }
  }

  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    if (formMode === "UPDATE") {
      const fetchData = async () => {
        try {
          const result = await http.get(`/licensemanagement/client/${licensaId}`);
          const fields = [
            "id",
            "typeOfLicence",
            "description",
            "version",
            "dateOfPurchase",
            "expirationDate",
            "numberOfLincence",
            "address",
            "licenseCost",
            "paymentForm",
            "restrictionOfUse",
            "conditionOfUse",
            "client",
            "providerId",
          
          ];
          fields.forEach((field) => setValue(field, licensa[field]));
          setLicensa(result.data);
        } catch (error) {
          toast.error(error.response?.data.message);
        }
      };

      fetchData();
    }
    setShowSuccess(false);
  }, [formMode]);

  useEffect(() => {
    reset(licensa);
  }, [licensa]);

  //fetch Provider
  useEffect(() => {
    (async () => {
      let response = await fetchProvider();
      if (response.success) {
        setProvider(response.data);
        console.log("ID provider", response.data);
      }
    })();
  }, []);

  const fetchProvider = async () => {
    try {
      const response = await http.get("/provider");
      let items = response.data.map((e) => {
        return { id: e.id, text: e.name, children: e.children };
      });
      return { success: true, data: items };
    } catch (error) {
      return { success: false };
    }
  };
  //fetch Client
  useEffect(() => {
    (async () => {
      let response = await fetchClient();
      if (response.success) {
        setClient(response.data);
        console.log("ID Client", response.data);
      }
    })();
  }, []);

  const fetchClient = async () => {
    try {
      const response = await http.get("/clients");
      let items = response.data.map((e) => {
        return { id: e.id, text: e.abreviation, children: e.children };
      });
      return { success: true, data: items };
    } catch (error) {
      return { success: false };
    }
  };

  //fetch Type
  useEffect(() => {
    (async () => {
      let response = await fetchTypeLicense();
      if (response.success) {
        setType(response.data);
      }
    })();
  }, []);

  const fetchTypeLicense = async () => {
    try {
      const response = await http.get("/typeoflicense");
      let items = response.data.map((e) => {
        return { id: e.id, text: e.description, children: e.children };
      });
      return { success: true, data: items };
    } catch (error) {
      return { success: false };
    }
  };

  //fetch Payment
  useEffect(() => {
    (async () => {
      let response = await fetchPaymentForm();
      if (response.success) {
        setPayment(response.data);
      }
    })();
  }, []);

  const fetchPaymentForm = async () => {
    try {
      const response = await http.get("/paymentform");
      let items = response.data.map((e) => {
        return { id: e.id, text: e.description, children: e.children };
      });
      return { success: true, data: items };
    } catch (error) {
      return { success: false };
    }
  };

  function onProviderChange(e) {
    licensa.providerId = e.selectedItem.id;
    setValue("providerId", e.selectedItem.id);
    console.log(licensa.provider_id);
  }

  function onClientChange(e) {
    licensa.client.id = e.selectedItem.id;
    setValue("client.id", e.selectedItem.id);
    console.log(licensa.client.id);
  }
  function setTypeOfLicense(e) {
    licensa.typeOfLicence = e.selectedItem.text;
    setValue("typeOfLicence", e.selectedItem.text);
  }

  function setPayemntForm(e) {
    licensa.paymentForm = e.selectedItem.text;
    setValue("paymentForm", e.selectedItem.text);
  }
  const setdateOfPurchase = (e) => {
    setValue("dateOfPurchase", e.target.value);
    licensa.dateOfPurchase = e.target.value;
  };

  const setexpirationDate = (e) => {
    setValue("expirationDate", e.target.value);
    licensa.expirationDate = e.target.value;
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Row>
        <Column sm={4} md={6} lg={4} className="required">
          <Dropdown
            id="typeOfLicence"
            titleText="Tipo de Licença"
            label={formMode == "UPDATE" ? licensa.typeOfLicence : "Selecione"}
            items={type}
            onChange={setTypeOfLicense}
            name="typeOfLicence"
            itemToString={(item) => (item ? item.text : "")}
          />
          <p className="field-error">{errors.typeOfLicence?.message}</p>
        </Column>
        <Column sm={4} md={6} lg={4} className="required">
          <TextInput
            id="description"
            labelText="Descrição ou nome"
            maxLength={64}
            {...register("description")}
          />
          <p className="field-error">{errors.description?.message}</p>
        </Column>
        <Column sm={4} md={6} lg={4}>
          <TextInput
            id="address"
            labelText="Localização"
            {...register("address")}
            maxLength={50}
          />
        </Column>
      </Row>
      <Row>
        <Column sm={4} md={6} lg={4} className="required">
          <TextInput
            id="numberOfLincence"
            labelText="Referência"
            {...register("numberOfLincence")}
            maxLength={30}
          />
          <p className="field-error">{errors.numberOfLincence?.message}</p>
        </Column>

        <Column sm={4} md={6} lg={4}>
          {formMode === "UPDATE" ? (
            <DatePicker locale="pt" dateFormat="d/m/Y" datePickerType="single">
              <DatePickerInput
                style={{ width: "330px" }}
                labelText="Data de compra da Licença"
                id="dateOfPurchase"
                name="dateOfPurchase"
                value={licensa.dateOfPurchase}
                {...register("dateOfPurchase")}
                onSelect={setdateOfPurchase}
              />
            </DatePicker>
          ) : (
            <DatePicker locale="pt" dateFormat="d/m/Y" datePickerType="single">
              <DatePickerInput
                style={{ width: "330px" }}
                labelText="Data de compra da Licença"
                id="dateOfPurchase"
                name="dateOfPurchase"
                {...register("dateOfPurchase")}
                onSelect={setdateOfPurchase}
              />
            </DatePicker>
          )}
        </Column>
        <Column sm={4} md={6} lg={4}>
          {formMode === "UPDATE" ? (
            <DatePicker locale="pt" dateFormat="d/m/Y" datePickerType="single">
              <DatePickerInput
                style={{ width: "330px" }}
                labelText="Data de validade da Licença"
                id="expirationDate"
                name="expirationDate"
                value={licensa.expirationDate}
                {...register("expirationDate")}
                onSelect={setexpirationDate}
              />
            </DatePicker>
          ) : (
            <DatePicker locale="pt" dateFormat="d/m/Y" datePickerType="single">
              <DatePickerInput
                style={{ width: "330px" }}
                labelText="Data de validade da Licença"
                id="expirationDate"
                name="expirationDate"
                {...register("expirationDate")}
                onSelect={setexpirationDate}
              />
            </DatePicker>
          )}
        </Column>
      </Row>
      <Row>
        <Column sm={4} md={6} lg={4}>
          <TextInput
            id="version"
            labelText="Versão"
            {...register("version")}
            maxLength={30}
          />
        </Column>
        <Column sm={4} md={6} lg={4}>
          <TextInput
            id="licenseCost"
            labelText="Custo"
            {...register("licenseCost")}
            maxLength={30}
          />
        </Column>
        <Column sm={4} md={6} lg={4}>
          <Dropdown
            id="paymentForm"
            label={formMode == "UPDATE" ? licensa.paymentForm : "Selecione"}
            titleText="Formas de Pagamento"
            items={payment}
            onChange={setPayemntForm}
            name="paymentForm"
            itemToString={(item) => (item ? item.text : "")}
          />
        </Column>
      </Row>
      <Row>

        <Column sm={4} md={6} lg={4} className="required">
          <Dropdown
            id="provider.id"
            label={formMode == "UPDATE" ? licensa.providerId : "Selecione"}
            titleText="Fornecedor"
            items={provider}
            onChange={onProviderChange}
            name="provider.id"
            itemToString={(item) => (item ? item.text : "")}
          />
          <p className="field-error">{errors.provider?.message}</p>
        </Column>
        <Column sm={4} md={6} lg={4} className="required">
          <Dropdown
            id="client.id"
            label={formMode == "UPDATE" ? licensa.client.abreviation : "Selecione"}
            titleText="Cliente"
            items={client}
            onChange={onClientChange}
            name="client.id"
            itemToString={(item) => (item ? item.text : "")}
          />
          <p className="field-error">{errors.client?.message}</p>
        </Column>

        <Column sm={4} md={6} lg={4}>
          <TextInput
            id="conditionOfUse"
            labelText="Condições de uso da licença"
            {...register("conditionOfUse")}
            maxLength={30}
          />
        </Column>
      </Row>
      <Row>
        <Column sm={4} md={6} lg={4}>
          <TextInput
            id="restrictionOfUse"
            labelText="Restrições de uso da licença"
            {...register("restrictionOfUse")}
            maxLength={30}
          />
        </Column>
      </Row>
      <Row className="mt-2">
        <Column sm={12} md={6} lg={6}>
          <ButtonSet>
            <Button
              disabled={isBtnDisabled}
              type="submit"
              renderIcon={SaveModel24}
              iconDescription="Save"
            >
              Salvar
              {showLoading == true ? (
                <Loading
                  description="Active loading indicator"
                  withOverlay={false}
                  small
                />
              ) : (
                ""
              )}
            </Button>
            <Button onClick={() => navigate("../licensas")}>Voltar</Button>
          </ButtonSet>
        </Column>
      </Row>
    </Form>
  );
};

export default LicenseManagementForm;
