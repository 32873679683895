import { useParams } from "react-router-dom";
import Card from "../../components/Card/Card";
import ClientForm from "./ClientForm";
import { Tabs, Tab } from "carbon-components-react";
import { useEffect, useState, React } from "react";
import ProjectClientPage from "./ProjectClientPage";
import ClientContactPage from "../ClientContact/ClientContactPage";

import { Function } from "@carbon/icons-react";
import { ToastContainer, toast } from "react-toastify";
import ClientActivityPage from "../../pages/Client/ClientActivityPage";
import ClientCompanyPage from "./ClientCompany";

const UpdateClientPage = () => {
  const params = useParams();

  const [activeTab, setActiveTab] = useState(0);
  const handleTabChange = (index) => {
    setActiveTab(index);
  };

  return (
    <>
      <Card title="Actualizar Cliente">
        <ClientForm
          mode={"UPDATE"}
          url={"/clients/" + params.id}
          successMessage={"Client Updated Successfully"}
          onSuccessNavigateTo={"../clientes"}
          clientId={params.id}
        />
      </Card>

      <Card>
        <Tabs
          className="Navmargin"
          selected={activeTab}
          onSelectionChange={handleTabChange}
        >
          <Tab label="Empresa">
            <ClientCompanyPage clientId={params.id} />
          </Tab>
          <Tab label="Contacto">
            <ClientContactPage clientId={params.id} />
          </Tab>
          <Tab label="Projectos">
            <ProjectClientPage clientId={params.id} />
          </Tab>
          <Tab label="Actividades">
            <ClientActivityPage clientId={params.id} />
          </Tab>
        </Tabs>
      </Card>
    </>
  );
};

export default UpdateClientPage;
