import Card from "../../components/Card/Card"

const PlanePage = ()=>{
    return (<Card title="Planos de Seguros">
        <div> 
            <p>Here come table</p>
        </div>
    </Card>);
};

export default PlanePage;